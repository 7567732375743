export const TASK_WORKFLOW_RESULT = {
  AUTHENTICATED: 'authenticated',
  COMPLETED: 'completed',
  FAILED: 'failed',
  FULFILLMENT_AVAILABLE: 'fulfillment-available',
  MFA_RETRY: 'mfa-retry',
  ONLY_AUTH_COMPLETED: 'only-auth-completed',
  PENDING: 'pending',
  TASK_RESULT_INFO: 'task-result-info',
}
