export const USER_MESSAGE_TYPES = {
  /**
   * This is a special case that will subscribe/unsubscribe from all events for task workflow orchestrators.
   */
  ALL_EVENTS: '*',
  AUTHENTICATION_FAILED: 'authentication-failed',
  AUTHENTICATION_SUCCESSFUL: 'authentication-successful',
  DATA_VERIFICATION: 'data-verification',
  EXTERNAL_LOGIN_RECOVERY: 'external-login-recovery',
  FRACTIONAL_DEPOSIT_ERROR: 'fractional-deposit-error',
  GATHER_REQUEST: 'gather-request',
  INITIALIZE_ADP_EDDE_SDK: 'initialize-adp-edde-sdk',
  MANUAL_MODE: 'manual-mode',
  MOCK_MANUAL_MODE: 'mock-manual-mode',
  MUTEX_LOCK_REQUEST: 'mutex-lock-request',
  RECEIVED_USER_INPUT: 'received-user-input',
  SMART_AUTH_DATA_REQUEST: 'smart-auth-data-request',
  TASK_COMPLETED: 'task-completed',
  TASK_CREATED: 'task-created',
  TASK_FAILED: 'task-failed',
  TASK_WORKFLOW_FINISHED: 'task-workflow-finished',
  USER_ACCOUNTS_UPDATED: 'user-accounts-updated',
  USER_CARDS_UPDATED: 'user-cards-updated',
  USER_NOTIFICATION: 'user-notification',
}
