<template>
  <button
    ref="button"
    class="btn content-only touchable focus-visible-width"
    :style="buttonStyles"
    :class="{
      disabled: isDisabled,
      dark: useDarkMode,
      'left-align': iconLeft,
      'space-between': hasLeftAndRightContent,
    }"
    :aria-disabled="isDisabled"
    :data-test-id="dataTestId"
    v-store-focused-element:click="handleClick"
  >
    <transition v-if="showLeftArea" name="fadeIconLeft" mode="out-in">
      <BaseButtonLeft
        :text="text"
        :icon-left="iconLeft"
        :font-size="fontSize"
      />
    </transition>
    <transition v-if="showCenterArea" name="fade" mode="out-in">
      <BaseButtonCenter
        :text="text"
        :helper-text="helperText"
        :font-size="fontSize"
      />
    </transition>
    <transition v-if="showRightArea" name="fadeIconRight" mode="out-in">
      <BaseButtonRight
        :text="text"
        :icon-right="iconRight"
        :font-size="fontSize"
      />
    </transition>
  </button>
</template>

<script>
import BaseButtonLeft from '@/components/Form/BaseButtonLeft.vue'
import BaseButtonRight from '@/components/Form/BaseButtonRight.vue'
import BaseButtonCenter from '@/components/Form/BaseButtonCenter.vue'
import { mapGetters } from 'vuex'
import {
  customerBrandedButtons,
  taskBrandedButtons,
  atomicBrandedButtons,
  customBrandedButtons,
} from '@/util/branding'

export default {
  name: 'BaseButton',
  props: {
    customerBranded: Boolean,
    taskBranded: Boolean,
    colors: Object,
    text: [String, Object],
    helperText: String,
    btnStyle: String,
    isDisabled: Boolean,
    dataTestId: String,
    iconLeft: String,
    iconRight: Object,
    fontSize: String,
  },
  components: {
    BaseButtonLeft,
    BaseButtonRight,
    BaseButtonCenter,
  },
  emits: ['handleClick', 'disableClick'],
  computed: {
    ...mapGetters('company', ['brandColor']),
    ...mapGetters('theme', [
      'customerBrandColor',
      'useDarkMode',
      'overrideColorDefaults',
      'theme',
    ]),
    buttonStyles() {
      const stylesFromStore = this.theme?.buttons

      if (this.colors) {
        return {
          ...stylesFromStore,
          ...customBrandedButtons({
            style: this.btnStyle,
            colors: this.colors,
          }),
        }
      } else if (this.customerBranded || this.overrideColorDefaults) {
        return {
          ...stylesFromStore,
          ...customerBrandedButtons({
            style: this.btnStyle,
            color: this.customerBrandColor,
          }),
        }
      } else if (this.taskBranded) {
        return {
          ...stylesFromStore,
          ...taskBrandedButtons({
            style: this.btnStyle,
            color: this.brandColor,
          }),
        }
      } else {
        return {
          ...stylesFromStore,
          ...atomicBrandedButtons({ style: this.btnStyle }),
        }
      }
    },
    showLeftArea() {
      return this.iconLeft || this.text?.left
    },
    showRightArea() {
      return this.iconRight || this.text?.right
    },
    showCenterArea() {
      return (
        (this.text || this.helperText) &&
        (!this.text?.left || !this.text?.right)
      )
    },
    hasLeftAndRightContent() {
      return this.showLeftArea && this.showRightArea
    },
    hasLeftIcon() {
      return this.iconLeft
    },
  },
  methods: {
    handleClick() {
      if (this.isDisabled) {
        return this.$emit('disableClick')
      }
      this.$emit('handleClick')
      this.$refs.button?.blur()
    },
  },
}
</script>

<style lang="scss" scoped>
.btn {
  align-items: center;
  border: transparent solid 2px;
  color: var(--gray-900);
  cursor: pointer;
  display: flex;
  height: auto;
  justify-content: center;
  line-height: 1;
  transform: translateZ(0);
  min-height: var(--btn-min-height);
  padding: 0.8rem;
  text-align: center;
  text-decoration: none;
  width: 100%;
  transition: var(--button-hover);
  position: relative;
  overflow: hidden;
  @media (max-height: 639px) {
    min-height: 44px;
    font-size: 1.6rem;
  }
  &:hover,
  &:active {
    transform: scale(0.98);
    opacity: 0.8;
  }

  &.inline {
    width: auto;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
    transition: all 200ms ease-in-out;
  }
  &.left-align {
    justify-content: start;
    .text {
      text-align: left;
    }
  }
  &.space-between {
    justify-content: space-between;
  }
}

@keyframes waves {
  to {
    transform: translateX(100%);
  }
}

/*********************************************
WEIRD QUIRK...
FONT-WEIGHT ON AN ACTUAL BUTTON WILL NOT
RECOGNIZE FONT-WEIGHT:400 or FONT-WEIGHT:500
IF AN A TAG WITH THE CLASS OF .BTN, THEN IT
WORKS JUST FINE
*********************************************/
button.btn {
  font-weight: 500;
}
</style>
