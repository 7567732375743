export const interstitial = {
  addCardTitle: ({ customerName, companyName }) =>
    `Securely link your ${customerName} card to ${companyName}`,
  addCardDescription:
    'Scan, enter, or add a card via your mobile device wallet.',
  paymentDescription: 'After you sign in, your payment will be linked.',
  paymentMethodFeeDisclaimer: ({ companyName, paymentMethodType, fee }) => {
    const paymentMethodDisplayName = {
      credit: 'credit card',
      debit: 'debit card',
      ach: 'ACH',
    }[paymentMethodType]

    return `${companyName} may charge a ${fee} fee for ${paymentMethodDisplayName} payments.`
  },
  creditCardFeeDisclaimer: ({ companyName, creditCardFee }) =>
    `${companyName} charges a ${creditCardFee} fee for credit card payments.`,
  uplink: {
    title: ({ name }) => `Sign in to ${name} to connect your account`,
    description: `Once signed in, we'll start connecting your account.`,
    continue: ({ name }) => `Sign in on ${name}`,
  },
}
