import { screenReaderAccessibleNumber } from '@/util/general'
import { getPaymentMethodAccountType, isAch } from '@/util/pay-link'

export const shared = {
  collapse: 'Colapsar',
  collapseFullSelectionList: 'Colapsar lista completa de selección',
  currentPaymentMethod: 'Método de pago actual',
  expand: 'Expandir',
  fullSelectionList: 'Lista completa de selección',
  inProgressPaymentMethod: 'Método de pago en progreso',
  linked: 'Conectado',
  linkingCompleted: 'Conexión completada',
  linkedPaymentMethod: 'Método de pago vinculado',
  linkingFailed: 'La conexión falló',
  managePayment: 'Gestionar pago',
  paymentMethod: 'Método de pago',
  paymentMethodAriaDescription: ({ paymentMethod }) =>
    isAch(paymentMethod)
      ? `${paymentMethod.title} ${getPaymentMethodAccountType(
          paymentMethod,
        )} cuenta terminada en ${screenReaderAccessibleNumber(
          paymentMethod.accountNumber,
        )}`
      : `${paymentMethod.title} ${
          paymentMethod.brand
        } tarjeta terminada en ${screenReaderAccessibleNumber(
          paymentMethod.lastFour,
        )}`,
  remove: 'Eliminar',
  removeSelection: ({ companyName }) => `Eliminar ${companyName}`,
  selected: 'Seleccionado',
  selectionList: 'Lista de selección',
  selections: 'selecciones',
  selectPaymentMethod: 'Seleccione método de pago',
  status: 'Estado',
  tryAgain: 'Inténtalo de nuevo',
  updatePaymentMethod: 'Actualizar método de pago',
  verifyAccount: 'Verificar cuenta',
  viewFullSelectionList: 'Ver lista completa de selección',
}
