import { processTasks } from '@/util/task-history'

const state = {
  taskList: [],
  linkedAccountList: [],
  selectedConnectionToModify: undefined,
}

const actions = {
  updateTaskList({ commit }, payload) {
    commit('setTaskList', processTasks(payload))
  },
  updateLinkedAccountList({ commit }, linkedAccounts) {
    commit('setLinkedAccounts', linkedAccounts)
  },
  updateSelectedConnectionToModify({ commit }, companyId) {
    commit('setSelectedConnectionToModify', companyId)
  },
  resetSelectedConnectionToModify({ commit }) {
    commit('setSelectedConnectionToModify', undefined)
  },
}

const mutations = {
  setTaskList: (state, tasks) => {
    state.taskList = tasks
  },
  setLinkedAccounts: (state, linkedAccounts) => {
    state.linkedAccountList = linkedAccounts
  },
  setSelectedConnectionToModify: (state, companyId) => {
    state.selectedConnectionToModify = companyId
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
