import { MODAL_ACTIONS, PAGES, EXIT_REASONS } from '@/util/constants'
import Analytics from '@/plugins/analytics'
import { getGlobalCSSValue } from '@/util/general'

export function global40000({ store }) {
  const analyticsInstance = Analytics.get()
  analyticsInstance.track({
    event: `Viewed ${PAGES.BAD_API_REQUEST}`,
  })

  return {
    logo: '/img/notification/icon-lock.svg',
    bgColor: getGlobalCSSValue('--warn-100'),
    title: store?.getters['i18n/phrases'].errorMessage.failedRequest,
    description: store.state.main.globalErrorStatus.description,
    actionItems: store.state.main.inSdk
      ? [
          {
            title: store?.getters['i18n/phrases'].shared.close,
            method: MODAL_ACTIONS.EXIT_TRANSACT,
            exitReason: EXIT_REASONS.BAD_REQUEST,
          },
        ]
      : [],
  }
}

export function global40103({ store }) {
  const analyticsInstance = Analytics.get()
  analyticsInstance.track({
    event: `Viewed ${PAGES.EXPIRED_TOKEN}`,
  })

  return {
    logo: '/img/notification/icon-lock.svg',
    bgColor: getGlobalCSSValue('--warn-100'),
    title: store?.getters['i18n/phrases'].errorMessage.expiredSession,
    description:
      store?.getters['i18n/phrases'].errorMessage.expiredSessionDescription,
    actionItems: store.state.main.inSdk
      ? [
          {
            title: store?.getters['i18n/phrases'].shared.close,
            method: MODAL_ACTIONS.EXIT_TRANSACT,
            exitReason: EXIT_REASONS.EXPIRED_TOKEN,
          },
        ]
      : [],
  }
}

export function global40104({ store }) {
  const analyticsInstance = Analytics.get()
  analyticsInstance.track({
    event: `Viewed ${PAGES.ACCESS_UNAUTHORIZED}`,
  })

  return {
    logo: '/img/notification/icon-lock.svg',
    bgColor: getGlobalCSSValue('--warn-100'),
    title: store?.getters['i18n/phrases'].errorMessage.invalidToken,
    description: store?.getters['i18n/phrases'].errorMessage.invalidTokenDesc,
    actionItems: store.state.main.inSdk
      ? [
          {
            title: store?.getters['i18n/phrases'].shared.close,
            method: MODAL_ACTIONS.EXIT_TRANSACT,
            exitReason: EXIT_REASONS.UNAUTHORIZED,
          },
        ]
      : [],
  }
}

export function globalTaskUnauthorized({ code, store }) {
  const analyticsInstance = Analytics.get()
  analyticsInstance.track({
    event: `Viewed ${PAGES.TASK_UNAUTHORIZED}`,
    payload: {
      code,
    },
  })

  return {
    logo: '/img/notification/icon-lock.svg',
    bgColor: getGlobalCSSValue('--warn-100'),
    title: store?.getters['i18n/phrases'].errorMessage.taskUnauthorized,
    description: store.state.main.globalErrorStatus.description,
    actionItems: store.state.main.inSdk
      ? [
          {
            title: store?.getters['i18n/phrases'].shared.close,
            method: MODAL_ACTIONS.EXIT_TRANSACT,
            exitReason: EXIT_REASONS.UNAUTHORIZED,
          },
        ]
      : [],
  }
}

export function global50X({ store }) {
  const analyticsInstance = Analytics.get()
  analyticsInstance.track({
    event: `Viewed ${PAGES.NETWORK_ERROR}`,
  })

  return {
    logo: '/img/notification/icon-lock.svg',
    bgColor: getGlobalCSSValue('--warn-100'),
    title: store?.getters['i18n/phrases'].errorMessage.failedConnection,
    description:
      store?.getters['i18n/phrases'].errorMessage.failedConnectionDesc,
    actionItems: store.state.main.inSdk
      ? [
          {
            title: store?.getters['i18n/phrases']?.shared.tryAgain,
            method: MODAL_ACTIONS.EXIT_TRANSACT,
            exitReason: EXIT_REASONS.FAILED_CONNECTION,
          },
        ]
      : [],
  }
}

export function globalTimeout({ store }) {
  const analyticsInstance = Analytics.get()
  analyticsInstance.track({
    event: `Viewed ${PAGES.FAILED_TIMEOUT}`,
  })

  return {
    logo: '/img/notification/icon-wifi-off.svg',
    bgColor: getGlobalCSSValue('--warn-100'),
    title: store?.getters['i18n/phrases'].errorMessage.failedConnection,
    description:
      store?.getters['i18n/phrases'].errorMessage.failedConnectionDesc,
    actionItems: store.state.main.inSdk
      ? [
          {
            title: store?.getters['i18n/phrases']?.shared.tryAgain,
            method: MODAL_ACTIONS.EXIT_TRANSACT,
            exitReason: EXIT_REASONS.FAILED_CONNECTION,
          },
        ]
      : [],
  }
}

export function globalSDKParameters({ store }) {
  const analyticsInstance = Analytics.get()
  analyticsInstance.track({
    event: `Viewed ${PAGES.INVALID_CONFIGURATION}`,
  })

  return {
    logo: '/img/notification/icon-lock.svg',
    bgColor: getGlobalCSSValue('--warn-100'),
    title: store?.getters['i18n/phrases']?.errorMessage.failedConfig,
    description: store.state.main.globalErrorStatus.description,
    actionItems: store.state.main.inSdk
      ? [
          {
            title: store?.getters['i18n/phrases']?.shared.close,
            method: MODAL_ACTIONS.EXIT_TRANSACT,
            exitReason: EXIT_REASONS.INVALID_CONFIGURATION,
          },
        ]
      : [],
  }
}

export function globalWithholdNotSupported({ store }) {
  const analyticsInstance = Analytics.get()
  analyticsInstance.track({
    event: `Viewed ${PAGES.WITHHOLD_NOT_SUPPORTED}`,
  })

  return {
    logo: '/img/notification/icon-lock.svg',
    bgColor: getGlobalCSSValue('--warn-100'),
    title:
      store?.getters['i18n/phrases'].errorMessage.failedWithholdNotSupported,
    description:
      store?.getters['i18n/phrases'].errorMessage
        .failedWithholdNotSupportedDesc,
    actionItems: store.state.main.inSdk
      ? [
          {
            title: store?.getters['i18n/phrases']?.shared.tryAgain,
            method: MODAL_ACTIONS.EXIT_TRANSACT,
            exitReason: EXIT_REASONS.WITHHOLD_NOT_SUPPORTED,
          },
        ]
      : [],
  }
}
