export const TASK_FAIL_REASON = {
  BAD_CREDENTIALS: 'bad-credentials',
  ACCOUNT_LOCKOUT: 'account-lockout',
  ACCOUNT_SETUP_INCOMPLETE: 'account-setup-incomplete',
  ACCOUNT_UNUSABLE: 'account-unusable',
  CONNECTION_ERROR: 'connection-error',
  DATA_VALIDATION_FAILED: 'data-validation-failed',
  DEVICE_DISCONNECTED: 'device-disconnected',
  DISTRIBUTION_NOT_SUPPORTED: 'distribution-not-supported',
  ENROLLED_IN_PAYCARD: 'enrolled-in-paycard',
  EXPIRED: 'expired',
  SESSION_TIMEOUT: 'session-timeout',
  PRODUCT_NOT_SUPPORTED: 'product-not-supported',
  NO_DATA_FOUND: 'no-data-found',
  UNKNOWN_FAILURE: 'unknown-failure',
  WORK_STATUS_TERMINATED: 'work-status-terminated',
  ROUTING_NUMBER_NOT_SUPPORTED: 'routing-number-not-supported',
  SYSTEM_UNAVAILABLE: 'system-unavailable',
  TRANSACTION_PENDING: 'transaction-pending',
  USER_DATA_REJECTED: 'user-data-rejected',

  // Paylink
  SUBSCRIPTION_INACTIVE: 'subscription-inactive',
  SUBSCRIPTION_MANAGED_BY_PARTNER_PROVIDER:
    'subscription-managed-by-partner-provider',
  PAYMENT_METHOD_REJECTED: 'payment-method-rejected',
  PAYMENT_METHOD_NOT_SUPPORTED: 'payment-method-not-supported',
  PAYMENT_METHOD_LOCKED: 'payment-method-locked',
  PAYMENT_SWITCH_UNSUCCESSFUL: 'payment-switch-unsuccessful',
  PAYMENT_METHOD_LIMIT_REACHED: 'payment-method-limit-reached',
  PAYMENT_METHOD_DECLINED: 'payment-method-declined',
}
