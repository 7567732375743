import { openModalAnimation, closeModalAnimation } from '@/util/animation'

const state = {
  isOpen: false,
  modalState: {
    view: undefined,
    data: {},
    overlay: false,
  },
}

const mutations = {
  setIsOpen: (state, value) => {
    state.isOpen = value
  },
  setModalState: (state, { view, data = {}, overlay = false }) => {
    state.modalState.view = view
    state.modalState.data = data
    state.modalState.overlay = overlay
  },
}

const actions = {
  async openModal({ commit, state, rootGetters }, payload) {
    commit('setModalState', payload)
    commit('setIsOpen', true)
    await openModalAnimation({
      overlay: state.modalState.overlay,
      dark: rootGetters['theme/useDarkMode'],
    })
  },
  async closeModal({ commit, state, rootGetters }) {
    await closeModalAnimation({
      overlay: state.modalState.overlay,
      dark: rootGetters['theme/useDarkMode'],
    })
    commit('setIsOpen', false)
    commit('setModalState', { view: undefined, data: {}, overlay: false })
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
