import { SEARCH_TAGS } from '@/util/constants'

/**
 * Keep these source objects in alphabetical order and handle arbitrary order
 * in the exported function.
 */

const aaa = {
  _id: '655d0d64630d0ce4cb4d15d7',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/da3ab00d-87fd-49eb-95d4-e32e37563b7b_aaa.png',
    },
    color: '#FFFFFF',
  },
  name: 'AAA',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.INSURANCE],
}

const affirm = {
  _id: '66a7ebd57cac0a85943aac54',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/901cd91b-cb15-4770-a18c-76e45589c6d3_460x0w.png',
    },
    color: '#484AF4',
  },
  name: 'Affirm',
  tags: [SEARCH_TAGS.POPULAR],
}

const afterpay = {
  _id: '66a29bfa9153403b760e1da7',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/0a8a0bb3-de3a-416b-b55a-aa6b818e0888_after.png',
    },
    color: '#B2FCE5',
  },
  name: 'Afterpay',
  tags: [SEARCH_TAGS.POPULAR],
}

const allstate = {
  _id: '65297fd16695ab0008a26c79',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/2118f75a-07fc-4a3c-a5a1-0101208adb4c_allstate.png',
    },
    color: '#0033A0',
  },
  name: 'Allstate',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.INSURANCE],
}

const amazon = {
  _id: '65272c415d8a530008e972df',
  branding: {
    logo: {
      url: '/img/grid-search/pay-link/amazon.svg',
    },
    color: '#F39700',
  },
  name: 'Amazon',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.SUBSCRIPTION],
}

const americanFamily = {
  _id: '65298cf128c44100096f89c0',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/d5b262e2-a154-4582-aa94-1aa7ca3628b4_American-Family-Insurance-logo.png',
    },
    color: '#FFFFFF',
  },
  name: 'American Family',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.INSURANCE],
}

const amex = {
  _id: '669e7a7afcba1d3d10c82711',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/76c7bf00-6ba2-4298-9de9-ed748104cf9e_amex.png',
    },
    color: '#006FCF',
  },
  name: 'Amex',
  fullName: 'American Express',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.CARD],
}

const apple = {
  _id: '6536d5456f184e00095f9df8',
  branding: {
    logo: {
      url: '/img/grid-search/pay-link/apple.svg',
    },
    color: '#F5F5F7',
  },
  name: 'Apple',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.SUBSCRIPTION],
}

const att = {
  _id: '65298dfc2c00c70008a87746',
  branding: {
    logo: {
      url: '/img/grid-search/pay-link/att.svg',
    },
    color: '#00A1DC',
  },
  name: 'AT&T',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.CELL, SEARCH_TAGS.CABLE],
}

const audible = {
  _id: '6671df750b6c67b0a405175f',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/9e93b08a-1b54-46d1-8205-dfa3c7d6269c_audible.png',
    },
    color: '#FB9600',
  },
  name: 'Audible',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.SUBSCRIPTION],
}

const boost = {
  _id: '665a0d7e3c6388329a589afc',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/333262ad-1507-4a3a-b6c9-418456ee2f09_boost.png',
    },
    color: '#FFFFFF',
  },
  name: 'Boost',
  fullName: 'Boost Mobile',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.CELL],
}

const cashApp = {
  _id: '6674566ad0e5c7b59fc0de23',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/6c8eb7ae-ee2a-459c-aa08-3160a034374e_cash-app.png',
    },
    color: '#00D633',
  },
  name: 'Cash App',
  tags: [SEARCH_TAGS.POPULAR],
}

const centuryLink = {
  _id: '66744fd5ce050627119b42f7',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/b709e275-716f-4f07-869b-ed960a77165c_century-link.png',
    },
    color: '#0047BB',
  },
  name: 'CenturyLink',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.CABLE],
}

const chase = {
  _id: '66a2b0c603b1a73a82373a54',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/e1693657-53f0-4c6b-9e06-64ffcba76e88_chase.png',
    },
    color: '#0F5BA8',
  },
  name: 'Chase',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.CARD],
}

const chewy = {
  _id: '65298ea2d592c40008acd053',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/8aca4e72-596c-4f53-ad49-698bb4e534b3_chewy.png',
    },
    color: '#1c49c2',
  },
  name: 'Chewy',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.SUBSCRIPTION],
}

const costco = {
  _id: '668eec607c6ab1082467a80a',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/d978f2ee-848d-489a-995f-3c55ff2b18a7_costco.png',
    },
    color: '#FFFFFF',
  },
  name: 'Costco',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.SUBSCRIPTION],
}

const cox = {
  _id: '65b7fe6246707779d0b348ee',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/1af12d1f-e95e-4dcf-a760-e6529b670733_cox.png',
    },
    color: '#FFFFFF',
  },
  name: 'Cox',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.CABLE],
}

const cricket = {
  _id: '665a3200ed5cdbede76893eb',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/b0be9453-8522-4583-a22e-2b0d6e1f126c_cricket.png',
    },
    color: '#60A630',
  },
  name: 'Cricket',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.CELL],
}

const discover = {
  _id: '66bb9796100941757bbbc8dc',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/c117ab47-cfec-4323-8b11-d34dde109883_discover.png',
    },
    color: '#FFFFFF',
  },
  name: 'Discover',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.CARD],
}

const disney = {
  _id: '6529915dde3f69000834fbe1',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/3e6748fa-6516-4956-b146-2b14877325fd_Disney_Plus_logo.svg-(1).png',
    },
    color: '#141450',
  },
  name: 'Disney+',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.SUBSCRIPTION],
}

const doordash = {
  _id: '6529a36ecb8e7200085c687f',
  branding: {
    logo: {
      url: '/img/grid-search/pay-link/doordash.svg',
    },
    color: '#FFFFFF',
  },
  name: 'Doordash',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.SUBSCRIPTION],
}

const espn = {
  _id: '655d0ecb55bfbf70e0769cd2',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/a188a7dc-06ba-4136-b3a5-6490adb32db7_espn-plus.png',
    },
    color: '#000000',
  },
  name: 'ESPN+',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.SUBSCRIPTION],
}

const geico = {
  _id: '6529a5dca815cc0008866a23',
  branding: {
    logo: {
      url: '/img/grid-search/pay-link/geico.svg',
    },
    color: '#2058AB',
  },
  name: 'Geico',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.INSURANCE],
}

const google = {
  _id: '653c354756bbb40008dd8e01',
  branding: {
    logo: {
      url: '/img/grid-search/pay-link/google.svg',
    },
    color: '#FFFFFF',
  },
  name: 'Google',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.SUBSCRIPTION],
}

const grubhub = {
  _id: '655d090455bfbf70e0769147',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/19d7f198-f6b7-4c37-ba0a-7c056de99083_grubhub.png',
    },
    color: '#EF8733',
  },
  name: 'Grubhub',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.SUBSCRIPTION],
}

const hulu = {
  _id: '6529a6eccb8e7200085c6ef6',
  branding: {
    logo: {
      url: '/img/grid-search/pay-link/hulu.svg',
    },
    color: '#000',
  },
  name: 'Hulu',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.SUBSCRIPTION],
}

const klarna = {
  _id: '66aa6ca85226ea2387eb981e',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/9c21536a-7323-4b2d-b033-72fc6fb6dfba_kla.png',
    },
    color: '#FFA7CC',
  },
  name: 'Klarna',
  tags: [SEARCH_TAGS.POPULAR],
}

const libertyMutual = {
  _id: '655d0e02ffc31b8be69760cb',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/95281c4f-d977-4926-a56c-8322abc4c13b_liberty-mutual.png',
    },
    color: '#F8D247',
  },
  name: 'Liberty Mutual',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.INSURANCE],
}

const lyft = {
  _id: '668d567b4ca50e51f027806d',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/fd09588e-c472-44b5-850a-ecf8dbab3eea_lyft.png',
    },
    color: '#EA0B8C',
  },
  name: 'Lyft',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.SUBSCRIPTION],
}

const max = {
  _id: '6529ab9c85376a0008a76730',
  branding: {
    logo: {
      url: '/img/grid-search/pay-link/max.svg',
    },
    color: '#002BE7',
  },
  name: 'Max',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.SUBSCRIPTION],
}

const metro = {
  _id: '66fffdeda315d308227ce4d6',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/b5a15153-8b81-4bf0-acaa-14ab7a8b183a_metro_logo.png',
    },
    color: '#FFFFFF',
  },
  name: 'Metro',
  fullName: 'Metro by T-Mobile',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.CELL],
}

const mint = {
  _id: '6568f70dd053315898a6443b',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/9cb26d09-612b-48ce-9fc1-8fd26b2b5582_mintMobileLogo.png',
    },
    color: '#78BA97',
  },
  name: 'Mint',
  fullName: 'Mint Mobile',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.CELL],
}

const netflix = {
  _id: '64ecca15ec669e000851d5d2',
  branding: {
    logo: {
      url: '/img/grid-search/pay-link/netflix.svg',
    },
    color: '#000000',
  },
  name: 'Netflix',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.SUBSCRIPTION],
}

const newYorkTimes = {
  _id: '66a00452f14beb7f4a15247b',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/ca9c753d-2702-4b53-92aa-c7f30c307f69_new-york-times.png',
    },
    color: '#000000',
  },
  name: 'NY Times',
  fullName: 'New York Times',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.SUBSCRIPTION],
}

const optimum = {
  _id: '67129b6592a4863eae997c7a',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/5efb9176-462c-46f0-be4c-0ee87c5cf610_optimum.png',
    },
    color: '#002864',
  },
  name: 'Optimum',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.CABLE],
}

const paramount = {
  _id: '6529b25f134644000877dbe9',
  branding: {
    logo: {
      url: '/img/grid-search/pay-link/paramount.svg',
    },
    color: '#0164FF',
  },
  name: 'Paramount+',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.SUBSCRIPTION],
}

const paypal = {
  _id: '6712a2da6ce091a63b2125e4',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/ccf6a3a7-261a-445e-a658-537cc57f7003_paypal.png',
    },
    color: '#003087',
  },
  name: 'PayPal',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.CARD],
}

const peacock = {
  _id: '65b7fdf5f8c265b827b94e06',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/4af34d9a-5c3f-46ce-928a-cfa2a2d983e3_peacock.png',
    },
    color: '#000000',
  },
  name: 'Peacock',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.SUBSCRIPTION],
}

const playstation = {
  _id: '653c308bbbd8bd0009e54759',
  branding: {
    logo: {
      url: '/img/grid-search/pay-link/playstation.svg',
    },
    color: '#FFFFFF',
  },
  name: 'PlayStation',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.SUBSCRIPTION],
}

const progressive = {
  _id: '6529b3dda815cc0008867bc8',
  branding: {
    logo: {
      url: '/img/grid-search/pay-link/progressive.svg',
    },
    color: '#3a94c4',
  },
  name: 'Progressive',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.INSURANCE],
}

const roku = {
  _id: '665a4b1215402edfe2409e42',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/f2896a8f-33d2-41f7-88e5-76faed65dcba_roku.png',
    },
    color: '#662D91',
  },
  name: 'Roku',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.SUBSCRIPTION],
}

const shop = {
  _id: '657b2d3981768582fc94ab27',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/7512eed8-901a-4226-888e-ca0c739e2913_shop.png',
    },
    color: '#5433EB',
  },
  name: 'Shop',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.SUBSCRIPTION],
}

const siriusXM = {
  _id: '66aa7bfd8a0f797ff291c93d',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/899cc2b7-37db-4173-83f0-d78af5af5c9b_492x0w.png',
    },
    color: '#0000EB',
  },
  name: 'SiriusXM',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.SUBSCRIPTION],
}

const spectrum = {
  _id: '663bee7aebe9c939a7d946d6',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/e1ecb328-8f53-460f-bd75-2c3dbccde32d_spectrum.png',
    },
    color: '#001B33',
  },
  name: 'Spectrum',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.CABLE],
}

const starbucks = {
  _id: '6529b9ccf1930500082b0b1f',
  branding: {
    logo: {
      url: '/img/grid-search/pay-link/starbucks.svg',
    },
    color: '#FFFFFF',
  },
  name: 'Starbucks',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.SUBSCRIPTION],
}

const stateFarm = {
  _id: '6529bab0a815cc000886852f',
  branding: {
    logo: {
      url: '/img/grid-search/pay-link/statefarm.svg',
    },
    color: '#FFFFFF',
  },
  name: 'State Farm',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.INSURANCE],
  hideOnAndroid: true,
}

const straightTalk = {
  _id: '669845ca5ee9cdb5ec197b8a',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/94492f67-db98-47d6-838a-dd8fb37fedb5_straight-talk.png',
    },
    color: '#CCFD31',
  },
  name: 'Straight Talk',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.CELL],
}

const target = {
  _id: '6529bc88134644000877e848',
  branding: {
    logo: {
      url: '/img/grid-search/pay-link/target.svg',
    },
    color: '#FFFFFF',
  },
  name: 'Target',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.SUBSCRIPTION],
}

const tmobile = {
  _id: '6529bb9185376a0008a77933',
  branding: {
    logo: {
      url: '/img/grid-search/pay-link/tmobile.svg',
    },
    color: '#ED008C',
  },
  name: 'T-Mobile',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.CELL, SEARCH_TAGS.CABLE],
}

const uber = {
  _id: '6529bd993e34a5000922ebae',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/2e18d066-d18e-4a58-b2fb-a85979a9ddde_uber.png',
    },
    color: '#000000',
  },
  name: 'Uber',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.SUBSCRIPTION],
}

const usaa = {
  _id: '655d0a366075be94d646b4b4',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/397f111d-b88e-404d-a79f-b8bf30a9ea7e_usaa.png',
    },
    color: '#12395B',
  },
  name: 'USAA',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.INSURANCE],
}

const venmo = {
  _id: '6671dfbb3d3a8e262a7bbd5b',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/533ad124-efb4-453d-89c0-cdf20ea00c2f_venmoLogo.png',
    },
    color: '#008AFF',
  },
  name: 'Venmo',
  tags: [SEARCH_TAGS.POPULAR],
}

const verizon = {
  _id: '6529be98f1930500082b11fb',
  branding: {
    logo: {
      url: '/img/grid-search/pay-link/verizon.svg',
    },
    color: '#000',
  },
  name: 'Verizon',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.CELL, SEARCH_TAGS.CABLE],
}

const walmart = {
  _id: '652802875d8a530008e9e410',
  branding: {
    logo: {
      url: '/img/grid-search/pay-link/walmart.svg',
    },
    color: '#306FD5',
  },
  name: 'Walmart',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.SUBSCRIPTION],
}

const xbox = {
  _id: '6567763f9d5ad6f0afee080b',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/2e009f1f-6b2d-41b3-9b40-7c8c1b3ce39e_xbox.png',
    },
    color: '#0F7C10',
  },
  name: 'Xbox',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.SUBSCRIPTION],
}

const xfinity = {
  _id: '6529c1463e34a5000922efc4',
  branding: {
    logo: {
      url: '/img/grid-search/pay-link/xfinity.svg',
    },
    color: '#623AF9',
  },
  name: 'Xfinity',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.CABLE],
}

const xpressBillPay = {
  _id: '6529c2173e34a5000922f040',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/ab9c5a97-aa21-40cc-bfdd-94d6d62977df_image-removebg-preview-(1).png',
    },
    color: '#FFFFFF',
  },
  name: 'Xpress Bill Pay',
  tags: [SEARCH_TAGS.POPULAR],
}

const youtube = {
  _id: '6529c2aaa815cc0008868df0',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/ceaa0c1f-631a-4b8e-bc1d-8bec3aef0a17_free-youtube-logo-icon-2431-thumb.png',
    },
    color: '#FFFFFF',
  },
  name: 'YouTube',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.SUBSCRIPTION],
}

const zip = {
  _id: '66bb963170d655be79817e4c',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/c353d730-4dac-422b-b00e-60d7f60a906c_zip.png',
    },
    color: '#1A0724',
  },
  name: 'Zip',
  tags: [SEARCH_TAGS.POPULAR],
}

const visible = {
  _id: '65690996fc1e152a3903f4f5',
  branding: {
    logo: {
      url: 'https://cdn-public.atomicfi.com/9cb121ed-5354-4d8d-a5f4-914b7ef51307_visible-logo.png',
    },
    color: '#FFFFFF',
  },
  name: 'Visible',
  tags: [SEARCH_TAGS.POPULAR, SEARCH_TAGS.CELL],
}

export const payLinkSuggestions = () => [
  netflix,
  amazon,
  venmo,
  att,
  cashApp,
  verizon,
  tmobile,
  disney,
  metro,
  max,
  newYorkTimes,
  shop,
  peacock,
  aaa,
  paypal,
  allstate,
  cox,
  optimum,
  americanFamily,
  audible,
  stateFarm,
  progressive,
  uber,
  lyft,
  paramount,
  chewy,
  walmart,
  costco,
  xpressBillPay,
  hulu,
  youtube,
  apple,
  mint,
  boost,
  cricket,
  straightTalk,
  spectrum,
  xfinity,
  centuryLink,
  doordash,
  geico,
  libertyMutual,
  playstation,
  xbox,
  espn,
  grubhub,
  roku,
  starbucks,
  amex,
  chase,
  discover,
  target,
  siriusXM,
  google,
  usaa,
  affirm,
  afterpay,
  klarna,
  zip,
  visible,
]

/**
 * Temporary export for referring user to another company when task fails for
 * subscription-managed-by-partner-provider. Long-term solution is to for the
 * backound to provide company branding information with the fail reason.
 */
export const SUBSCRIPTION_BUNDLE_PROVIDERS = {
  AMAZON: amazon,
  APPLE: apple,
  DISNEY: disney,
  GOOGLE: google,
  HULU: hulu,
}
