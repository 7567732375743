import { broadcastEvent } from '@/util/broadcast'
import Ajv from 'ajv'
import AjvErrors from 'ajv-errors'
import {
  COMPANY_TAGS,
  PRODUCTS,
  DEEPLINK_STEPS,
  DISTRIBUTION_TYPES,
  DISTRIBUTION_ACTION_TYPES,
  LANGUAGES,
  ERROR_CODES,
  MODAL_VIEW,
  PLATFORMS,
  TASK_WORKFLOW_TYPES,
  SDK_EVENT_TYPES,
} from '@/util/constants'

export function emitSdkEvent(event, payload) {
  broadcastEvent({ event, payload })
}

export function openURL({ store, url, mimetype }) {
  if (store.state.main.inSdk) {
    emitSdkEvent(SDK_EVENT_TYPES.OPEN_URL, { url, mimetype })
  } else {
    window.open(url, '_blank')
  }
}

const schema = {
  type: 'object',
  properties: {
    ...productSchema(),
    ...publicTokenSchema(),
    ...tokenSchema(),
    ...themeSchema(),
    ...deeplinkSchema(),
    ...distributionSchema(),
    ...withholdSchema(),
    ...languageSchema(),
    ...linkedAccountSchema(),
    ...taskToConvertSchema(),
    ...handoffSchema(),
    ...searchSchema(),
    ...tasksSchema(),
    ...platformSchema(),
    ...customerSchema(),
  },
}

function productSchema() {
  return {
    product: {
      type: 'string',
      enum: Object.values(PRODUCTS),
      errorMessage: `The product should be one of the following ${Object.values(
        PRODUCTS,
      )}`,
    },
  }
}

function publicTokenSchema() {
  return {
    publicToken: {
      type: 'string',
      errorMessage: 'The publicToken should be a string',
    },
  }
}

function tokenSchema() {
  return {
    token: {
      type: 'string',
      errorMessage: 'The token should be a string',
    },
  }
}

function themeSchema() {
  return {
    theme: {
      type: 'object',
      properties: {
        brandColor: {
          type: 'string',
          errorMessage: 'The brandColor should be a string',
        },
        overlayColor: {
          type: 'string',
          errorMessage: 'The overlayColor should be a string',
        },
      },
    },
  }
}

function deeplinkSchema() {
  return {
    deeplink: {
      type: 'object',
      properties: {
        step: {
          type: 'string',
          enum: Object.values(DEEPLINK_STEPS),
          errorMessage: `The deeplink.step should be one of the following ${Object.values(
            DEEPLINK_STEPS,
          )}`,
        },
        companyName: {
          type: 'string',
          errorMessage: 'The companyName should be a string',
        },
        companyId: {
          type: 'string',
          errorMessage: 'The companyId should be a string',
        },
        connectorId: {
          type: 'string',
          errorMessage: 'The connectorId should be a string',
        },
      },
      oneOf: [
        {
          properties: {
            step: {
              type: 'string',
              pattern: DEEPLINK_STEPS.SEARCH_COMPANY,
            },
          },
          required: ['step'],
        },
        {
          properties: {
            step: {
              type: 'string',
              pattern: DEEPLINK_STEPS.ADD_CARD,
            },
          },
          required: ['step'],
        },
        {
          properties: {
            step: {
              type: 'string',
              pattern: DEEPLINK_STEPS.GENERATE_DEPOSIT_PDF,
            },
          },
          required: ['step'],
        },
        {
          properties: {
            step: {
              type: 'string',
              pattern: DEEPLINK_STEPS.MANUAL_FALLBACK,
            },
          },
          required: ['step'],
        },
        {
          properties: {
            step: {
              type: 'string',
              pattern: DEEPLINK_STEPS.SEARCH_PAYROLL,
            },
          },
          required: ['step', 'companyName'],
          errorMessage: {
            required: {
              companyName: 'companyName is required',
            },
          },
        },
        {
          properties: {
            step: {
              type: 'string',
              pattern: DEEPLINK_STEPS.LOGIN_COMPANY,
            },
          },
          required: ['step', 'companyId'],
          errorMessage: {
            required: {
              companyName: 'companyId is required',
            },
          },
        },
        {
          properties: {
            step: {
              type: 'string',
              pattern: DEEPLINK_STEPS.LOGIN_PAYROLL,
            },
          },
          required: ['step', 'connectorId', 'companyName'],
          errorMessage: {
            required: {
              companyName: 'connectorId and companyName are required',
            },
          },
        },
      ],
    },
  }
}

function distributionSchema() {
  return {
    distribution: {
      type: 'object',
      properties: {
        type: {
          type: 'string',
          enum: Object.values(DISTRIBUTION_TYPES),
          errorMessage: `The distribution.type should be one of the following ${Object.values(
            DISTRIBUTION_TYPES,
          )}`,
        },
        amount: {
          type: ['number', 'integer'],
          errorMessage: 'The distribution.amount should be a number',
        },
        action: {
          type: 'string',
          enum: Object.values(DISTRIBUTION_ACTION_TYPES),
          errorMessage: `The distribution.action should be one of the following ${Object.values(
            DISTRIBUTION_ACTION_TYPES,
          )}`,
        },
      },
      oneOf: [
        {
          properties: {
            type: {
              const: DISTRIBUTION_TYPES.TOTAL,
            },
          },
        },
        {
          properties: {
            type: {
              const: DISTRIBUTION_TYPES.FIXED,
            },
          },
          required: ['amount'],
          errorMessage: {
            required: {
              amount: 'amount is required',
            },
          },
        },
        {
          properties: {
            type: {
              const: DISTRIBUTION_TYPES.PERCENT,
            },
          },
          required: ['amount'],
          errorMessage: {
            required: {
              amount: 'amount is required',
            },
          },
        },
      ],
    },
  }
}

function withholdSchema() {
  return {
    withholding: {
      type: 'object',
      properties: {
        formVersion: {
          type: 'string',
          enum: ['united-states-w-4'],
        },
        filingStatus: {
          type: 'string',
          enum: [
            'head-of-household',
            'married-filing-jointly-or-qualifying-widow-er',
            'single-or-married-filing-separately',
          ],
        },
        multipleJobsOrSpouseWorks: {
          type: 'boolean',
        },
        nonResidentAlien: {
          type: 'boolean',
        },
        exempt: {
          type: 'boolean',
        },
        dependentTaxCreditEligible: {
          type: 'boolean',
        },
        dependentsUnder17: {
          type: 'number',
          minimum: 0,
        },
        dependentsUnder17Amount: {
          type: 'number',
          minimum: 0,
        },
        otherDependents: {
          type: 'number',
          minimum: 0,
        },
        otherDependentsAmount: {
          type: 'number',
          minimum: 0,
        },
        dependentAmount: {
          type: 'number',
          minimum: 0,
        },
        otherIncomeNotFromJobsAmount: {
          type: 'number',
          minimum: 0,
        },
        deductionsAmount: {
          type: 'number',
          minimum: 0,
        },
        extraWithholdingPerPayPeriodAmount: {
          type: 'number',
          minimum: 0,
        },
      },
      required: [
        'formVersion',
        'filingStatus',
        'multipleJobsOrSpouseWorks',
        'nonResidentAlien',
        'exempt',
        'dependentTaxCreditEligible',
        'dependentsUnder17',
        'dependentsUnder17Amount',
        'otherDependents',
        'otherDependentsAmount',
        'dependentAmount',
        'otherIncomeNotFromJobsAmount',
        'deductionsAmount',
        'extraWithholdingPerPayPeriodAmount',
      ],
    },
  }
}

function languageSchema() {
  return {
    language: {
      type: 'string',
      enum: Object.values(LANGUAGES),
      errorMessage: `The language should be one of the following: ${Object.values(
        LANGUAGES,
      )}`,
    },
  }
}

function linkedAccountSchema() {
  return {
    linkedAccount: {
      type: 'string',
      errorMessage: 'The linkedAccount should be a string',
    },
  }
}

function taskToConvertSchema() {
  return {
    taskIdToConvert: {
      type: 'string',
      errorMessage: 'The taskIdToConvert should be a string',
    },
  }
}

function handoffSchema() {
  return {
    handoff: {
      type: 'array',
      minItems: 1,
      errorMessage: 'The handOff must be an array',
    },
  }
}

function searchSchema() {
  return {
    search: {
      type: 'object',
      maxProperties: 1,
      errorMessage:
        'The search parameter may only contain tags or excluded tags. Not both.',
      properties: {
        tags: {
          type: 'array',
          items: { type: 'string', enum: Object.values(COMPANY_TAGS) },
          errorMessage: `The tags should be one of the following: ${Object.values(
            COMPANY_TAGS,
          )}`,
        },
        excludedTags: {
          type: 'array',
          items: { type: 'string', enum: Object.values(COMPANY_TAGS) },
          errorMessage: `The excludedTags should be one of the following: ${Object.values(
            COMPANY_TAGS,
          )}`,
        },
      },
    },
  }
}

function tasksSchema() {
  return {
    tasks: {
      type: 'array',
      minItems: 1,
      items: {
        type: 'object',
        properties: {
          product: {
            type: 'string',
            enum: [
              ...Object.values(PRODUCTS),
              ...Object.values(TASK_WORKFLOW_TYPES),
            ],
            errorMessage: `The product should be one of the following ${[
              ...Object.values(PRODUCTS),
              ...Object.values(TASK_WORKFLOW_TYPES),
            ]}`,
          },
          operation: {
            type: 'string',
            enum: [
              ...Object.values(PRODUCTS),
              ...Object.values(TASK_WORKFLOW_TYPES),
            ],
            errorMessage: `The operation should be one of the following ${[
              ...Object.values(PRODUCTS),
              ...Object.values(TASK_WORKFLOW_TYPES),
            ]}`,
          },
          ...distributionSchema(),
        },
        oneOf: [
          {
            required: ['operation'],
          },
          {
            required: ['product'],
          },
        ],
      },
      errorMessage: 'The tasks property must be an array of objects',
    },
  }
}

function platformSchema() {
  return {
    platform: {
      type: 'object',
      properties: {
        name: {
          type: 'string',
          enum: Object.values(PLATFORMS),
          errorMessage: `The platform should be one of the following: ${Object.values(
            PLATFORMS,
          )}`,
        },
        version: {
          type: 'string',
          errorMessage: 'The version must be a string',
        },
        systemVersion: {
          type: 'string',
          errorMessage: 'The systemVersion must be a string',
        },
        sdkVersion: {
          type: 'string',
          errorMessage: 'The sdkVersion must be a string',
        },
      },
      required: ['name'],
    },
  }
}

function customerSchema() {
  return {
    customer: {
      type: 'object',
      properties: {
        name: {
          type: 'string',
          errorMessage: 'The customer name must be a string',
        },
      },
    },
  }
}

export function validateSdkParameters({ config, store }) {
  const ajv = new Ajv({ allErrors: true, allowUnionTypes: true })
  AjvErrors(ajv)
  const validate = ajv.compile(schema)
  const validConfig = validate(config)

  if (!validConfig) {
    store.dispatch('main/updateGlobalErrorStatus', {
      code: ERROR_CODES.GLOBAL_SDK_PARAMETERS,
      description: validate?.errors[0].message,
    })

    store.dispatch('modal/openModal', {
      view: MODAL_VIEW.ERROR,
    })

    // eslint-disable-next-line no-undef
    if (!process.env.VITEST) {
      console.error(
        `Invalid ${ajv.errorsText(validate.errors, { dataVar: 'sdk' })}`,
      )
    }

    return false
  }
  return true
}
