<template>
  <ModalContent>
    <ModalBody>
      <ModalContentWrap>
        <DynamicHeader
          :title="phrases.authenticationHelp.walmartTitle"
          textAlignment="text-center"
        />
        <ul>
          <HelpStep symbol="1" :title="phrases.authenticationHelp.walmartStep1">
            <TextButton
              @click="triggerStoreFinder"
              class="link"
              text="walmart.com/store-finder"
              data-test-id="store-finder"
            />.
          </HelpStep>
          <HelpStep
            symbol="2"
            :title="phrases.authenticationHelp.walmartStep2"
          />
          <HelpStep
            symbol="3"
            :title="phrases.authenticationHelp.walmartStep3"
          />
        </ul>
        <div class="walmart-location-wrap" :class="{ dark: useDarkMode }">
          <h3 class="title">Example Store</h3>
          <address class="address">
            Walmart
            <span class="store-number" :class="{ dark: useDarkMode }"
              >#1234</span
            >
            <span class="store-id">
              <IconArrowLeft
                :stroke="brandColor"
                :size="16"
                aria-hidden="true"
              />
              Store ID
            </span>
            <br />
            567 ABC Stree, Easyville, WA 55055
          </address>
        </div>
        <TextButton
          class="mt-6"
          data-test-id="close"
          btnStyle="hollow"
          :text="phrases.authenticationHelp.walmartGotIt"
          @handleClick="triggerCloseModal"
        />
      </ModalContentWrap>
    </ModalBody>
  </ModalContent>
</template>

<script>
import { PAGES } from '@/util/constants'
import TextButton from '@/components/Form/TextButton.vue'
import ModalContent from '@/components/Modal/ModalContent.vue'
import ModalContentWrap from '@/components/Modal/ModalContentWrap.vue'
import ModalBody from '@/components/Modal/ModalBody.vue'
import DynamicHeader from '@/components/Header/DynamicHeader.vue'
import { closeModalAnimation } from '@/util/animation'
import { mapState, mapGetters, mapActions } from 'vuex'
import { openURL } from '@/util/sdk'
import HelpStep from '@/components/Shared/HelpStep.vue'
import IconArrowLeft from '@/components/Icons/IconArrowLeft.vue'

export default {
  name: 'WalmartAuthenticationHelp',
  components: {
    ModalContent,
    ModalBody,
    ModalContentWrap,
    DynamicHeader,
    HelpStep,
    TextButton,
    IconArrowLeft,
  },
  computed: {
    ...mapGetters('company', ['brandColor', 'companyName']),
    ...mapGetters('theme', ['useDarkMode', 'customerBrandColor']),
    ...mapState('taskWorkflow', ['taskWorkflowBranding']),
  },
  methods: {
    ...mapActions('modal', ['clearModalState']),
    async triggerCloseModal() {
      this.$analytics.track({
        event: `Clicked Close From ${PAGES.WALMART_AUTHENTICATION_HELP}`,
      })
      await closeModalAnimation({ store: this.$store })
      this.clearModalState()
    },
    triggerStoreFinder() {
      this.$analytics.track({
        event: `Clicked Store Finder From Walmart Help Page`,
      })
      openURL({
        store: this.$store,
        url: 'https://walmart.com/store-finder',
      })
    },
  },
  async created() {
    this.$analytics.track({
      event: `Viewed ${PAGES.AUTHENTICATION_HELP}`,
    })
  },
}
</script>

<style lang="scss" scoped>
.link {
  display: contents;
  height: 1.6rem;
  line-height: 2.4rem;
  margin: 0;
  text-align: left;
  color: v-bind(brandColor) !important;
  text-decoration: underline !important;
}

.walmart-location-wrap {
  border: solid 1px var(--gray-200);
  border-radius: 8px;
  padding: calc(var(--paddingBase) * 2);
  margin: calc(var(--marginBase) * 2) 0 calc(var(--marginBase) * 2) 0;

  h3 {
    font-size: 1.4rem;
  }
  &.dark {
    border: solid 1px var(--gray-600);
  }
  .title {
    color: var(--gray-500);
  }
  .address {
    font-style: normal;
    color: var(--gray-500);
    margin-top: var(--marginBase);
  }
  .store-number {
    color: var(--gray-900);
    &.dark {
      color: var(--gray-300);
    }
  }

  .store-id {
    margin-left: var(--marginBase);
    svg {
      position: relative;
      top: 2px;
      margin-right: calc(var(--marginBase) / 2);
    }
    color: v-bind(brandColor);
  }
}
</style>
