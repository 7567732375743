import parseUserDeviceAutomation from '@/util/client-side-automation/parse-user-device-automation'
import { SCOPES, AUTHENTICATION_METHODS } from '@/util/constants'
import { features } from '@/util/native'

const defaultActiveSession = {
  nativeEventListener: undefined,
  currentURL: undefined,
  runTriggers: false,
}

const state = {
  userAutomationDeviceConfig: {},
  activeSession: { ...defaultActiveSession },
  pages: {},
  latestPageHandle: undefined,
}

const mutations = {
  setUserAutomationDeviceConfig: (state, userAutomationDeviceConfig) => {
    state.userAutomationDeviceConfig = userAutomationDeviceConfig
  },
  setActiveSession: (state, session) => {
    state.activeSession = session
  },
  setPage(state, page) {
    state.latestPageHandle = page.handle
    state.pages[page.handle] = page
  },
}

const getters = {
  userAutomationDeviceConfig: (state) => {
    return state.userAutomationDeviceConfig
  },
  customerHasUserLinkContinuousAccessEnabled(_, getters, rootState) {
    return (
      rootState.main.scope === SCOPES.USER_LINK &&
      rootState.user?.userData?.customer?.features?.userLinkContinuousAccess
        ?.enabled
    )
  },
  // new flag to allow for connectors like paycom that only perform well on uplink
  // to use trueauth for both verify deposit even if the customer has CA enabled
  connectorPrefersUplink(_, getters, rootState) {
    return (
      rootState.company.connector.preferredAuthenticationMethod ===
      AUTHENTICATION_METHODS.UPLINK
    )
  },
  selectedConnectorSupportsUplink: (state) => {
    return !!state.userAutomationDeviceConfig
  },
  usingNativeSDK: () => {
    return window.SUPPORTS_NATIVE_AUTHENTICATION
  },
  uplinkIsAvailable: (_, getters) => {
    return (
      getters.referToMobileUplinkExperience || getters.usingUserAutomatedDevice
    )
  },
  uplinkIsEnabled: (_, getters, rootState) => {
    return (
      rootState.experiment.ldFlags.useUplink &&
      getters.selectedConnectorSupportsUplink &&
      (!getters.customerHasUserLinkContinuousAccessEnabled ||
        getters.connectorPrefersUplink)
    )
  },
  referToMobileUplinkExperience: (_, getters, rootState) => {
    return !!(
      !getters.usingNativeSDK &&
      getters.uplinkIsEnabled &&
      rootState.experiment.ldFlags.trueAuthAppClip
    )
  },
  usingUserAutomatedDevice: (_, getters) => {
    return !!(getters.usingNativeSDK && getters.uplinkIsEnabled)
  },
  parsedUserAutomationDeviceConfig: (state, _, rootState) => {
    return state.userAutomationDeviceConfig?.payload
      ? parseUserDeviceAutomation({
          userAutomationDeviceConfig: state.userAutomationDeviceConfig,
          userState: rootState.user,
        })
      : undefined
  },
  useQuantum: async (_, getters) => {
    const { QUANTUM } = await features()
    return QUANTUM && getters.usingUserAutomatedDevice
  },
  latestPage: (state) => state.pages[state.latestPageHandle],
}

const actions = {
  updateUserAutomationDeviceConfig({ commit }, userAutomationDeviceConfig) {
    commit('setUserAutomationDeviceConfig', userAutomationDeviceConfig)
  },
  updateActiveSession({ state, commit }, { updates }) {
    commit('setActiveSession', { ...state.activeSession, ...updates })
  },
  addPage({ commit }, page) {
    commit('setPage', page)
  },
  getPage({ state }, handle) {
    return state.pages[handle]
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
