import { makeRequest } from '@/util/requests'
import { camelCase, sample } from 'lodash-es'
import { AUTOMATION_DEVICES } from '@/util/constants'

const state = {
  abTestActive: false,
  activeExperiments: {},
  abTestVariants: {},
  ldFlags: {},
}

const actions = {
  async fetchLdFeatureFlags({ commit, rootGetters, dispatch }, payload) {
    const automationDevice = rootGetters[
      'userDeviceAutomation/usingUserAutomatedDevice'
    ]
      ? AUTOMATION_DEVICES.USER
      : AUTOMATION_DEVICES.CLOUD

    const platformName = rootGetters['main/platformName']
    const platformSdkVersion = rootGetters['main/platformSdkVersion']
    const products = rootGetters['taskWorkflow/products']
    const company = rootGetters['company/companyName']
    const companyId = rootGetters['company/companyId']
    const connector = rootGetters['company/connectorName']
    const connectorId = rootGetters['company/connectorId']
    const customer = rootGetters['user/customerName']
    const experimentsAllowed = rootGetters['user/experimentsAllowed']
    const surveysAllowed = rootGetters['user/surveysAllowed']
    const customerId = rootGetters['user/customerId']
    const identifier = rootGetters['user/userIdentifier']
    // Services always sets `authenticator` regardless of the use of one, so we will match that functionality
    // https://github.com/atomicfi/services/blob/e28a9e0b84d751bd0f46b5743f95f4782b1136c2/packages/task/lib/create.js#L50
    const authenticator = rootGetters['company/activeConnectorName']

    const response = await makeRequest({
      method: 'POST',
      endpoint: '/feature-flags',
      data: {
        identifier,
        company,
        companyId,
        experimentsAllowed,
        surveysAllowed,
        customer,
        customerId,
        connector,
        connectorId,
        automationDevice,
        products,
        authenticator,
        platformName,
        platformSdkVersion,
        deviceType: IS_MOBILE ? (IS_ANDROID ? 'android' : 'ios') : 'web', // Derived from users device, not the sdk
        ...payload,
      },
    })

    const flags = Object.fromEntries(
      Object.entries(response.data.flags).map(([key, val]) => [
        camelCase(key),
        val,
      ]),
    )

    commit('setLdFeatureFlags', flags)
    if (flags.transactPublicApiOverride) {
      dispatch(
        'environment/updateApiEndpoint',
        flags.transactPublicApiOverride,
        { root: true },
      )
    }
  },
  initializeAbTestVariants({ commit, state }) {
    for (const [experiment] of Object.entries(state.activeExperiments)) {
      commit('setVariant', experiment)
    }
  },
  updateLdFeatureFlags({ commit }, payload) {
    commit('setLdFeatureFlags', payload)
  },
}

const mutations = {
  setVariant: (state, experiment) => {
    state.abTestVariants[experiment] = sample(
      state.activeExperiments[experiment],
    )
  },
  setLdFeatureFlags: (state, flags) => {
    state.ldFlags = flags
  },
}

const getters = {
  abTestVariants: (state, getters, rootState, rootGetters) => {
    // If the user is for a Cypress Test, ignore the experiments
    if (rootGetters['user/userIsCypressTest']) return {}
    return state.abTestVariants
  },
  ldFlags: (state) => {
    return state.ldFlags
  },
  alwaysShowWelcomeScreen: (state, getters, rootState, rootGetters) => {
    return (
      state.ldFlags.transactAlwaysShowWelcomeScreen &&
      rootGetters['taskWorkflow/productsIncludeDeposit']
    )
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
