<template>
  <div class="modal-body"><slot></slot></div>
</template>

<script>
export default {
  name: 'ModalBody',
}
</script>

<style lang="scss" scoped>
.modal-body {
  height: 100%;
  display: flex;
  flex-flow: column;
}
</style>
