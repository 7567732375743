export const taskProgress = {
  noNeedToHangAround:
    'No es necesario que se quede—nosotros nos encargamos de esto.',
  progressTitleDeposit:
    '¡Casi terminamos! Estamos actualizando su depósito directo...',
  progressTitleVerify: '¡Casi terminamos! Estamos vinculando su nómina...',
  progressTitleWithhold:
    '¡Casi terminamos! Estamos actualizando sus retenciones...',
  ariaSuccessPrompt: ', finalizado con éxito.',
  ariaWaitPrompt: 'aún está en progreso, por favor espere.',
  messageAverage: 'Normalmente, esto toma alrededor de',
  seconds: 'segundos',
  minutes: 'minutos',
  estimatedTimeRemaining: 'Tiempo estimado restante',
  tax: {
    title: 'Recopilando sus documentos fiscales...',
  },
}
