export const distribution = {
  entireAmount: 'Entire paycheck',
  percentage: 'Percentage',
  specificAmount: 'Specific amount',
  unsupported: 'Unsupported',
  underMinimumFractionalDistributionFixed: 'Please enter a higher amount.',
  overMaximumFractionalDistributionFixed: 'Please enter a lower amount.',
  underMinimumFractionalDistributionPercent:
    'Please enter a higher percentage.',
  overMaximumFractionalDistributionPercent: 'Please enter a lower percentage.',
  pageTitleChangeable: ({ name }) =>
    `How much of your paycheck should go to ${name}?`,
  pageTitleNotChangeable: ({ name }) =>
    `Confirm your direct deposit to ${name}`,
  entire: 'Entire',
  fullAmount: 'Entire paycheck',
  percentageAmount: 'Percentage amount',
  specificAmount2: 'Specific amount',
  ofEveryPaycheck: 'of every paycheck',
}
