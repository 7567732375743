import Analytics from '@/plugins/analytics'
import Ably from '@/plugins/ably'
import { MODAL_VIEW } from '@/util/constants'

export function listenForNetworkEvents({ store }) {
  const analyticsInstance = Analytics.get()
  let offlineTimeout
  let ignoreNextDisconnectedEvent = false

  // If the user enters the background, we don't want to show the offline modal on the next disconnected event
  // due to Ably disconnecting and immediately reconnecting
  document.body.addEventListener('sdk-native-event', ({ detail: { name } }) => {
    switch (name) {
      case 'sdk-did-enter-background':
        ignoreNextDisconnectedEvent = true
        return
    }
  })

  Ably.get().connection.on('disconnected', () => {
    // Clear any existing timeout
    if (offlineTimeout) {
      clearTimeout(offlineTimeout)
    }

    // If we've disconnected more than once, we'll consider it an actual disconnect
    // Or if the user is reconnecting after closing the app, don't show the offline modal
    if (ignoreNextDisconnectedEvent) {
      return
    }

    // Sometimes when the user returns to the app, Ably fires a disconnected event
    // immediately with a subsequent connected event. We don't want to count this
    // as an actual disconnect, so we'll wait 500ms to see if Ably fires a
    // connected event. If not, we'll consider it an actual disconnect and show the offline modal.
    offlineTimeout = setTimeout(() => {
      _wentOffline({ store, analyticsInstance })
      _waitForOnline({ store, analyticsInstance })
    }, 500)
  })

  Ably.get().connection.on('connected', () => {
    // If the user reconnected, then the next disconnected event should show the offline modal
    ignoreNextDisconnectedEvent = false

    // Clear the timeout if we connect within 500ms
    if (offlineTimeout) {
      clearTimeout(offlineTimeout)
    }
  })
}

function _wentOffline({ store, analyticsInstance }) {
  if (store.state.modal.modalState.view === MODAL_VIEW.OFFLINE) {
    return
  }

  store.dispatch('network/updateIsOnline', false)

  analyticsInstance.track({
    event: 'Went offline',
    internal: true,
  })

  store.dispatch('modal/openModal', { view: MODAL_VIEW.OFFLINE })
}

function _waitForOnline({ store, analyticsInstance }) {
  const onlineListener = async () => {
    await _wentOnline({ store, analyticsInstance })
    Ably.get().connection.off('connected', onlineListener)
  }

  Ably.get().connection.on('connected', onlineListener)
}

async function _wentOnline({ store, analyticsInstance }) {
  store.dispatch('network/updateIsOnline', true)

  await store.dispatch('modal/closeModal')

  analyticsInstance.track({
    event: 'Went online',
    internal: true,
  })
}
