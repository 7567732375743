export const shared = {
  continue: 'Continue',
  ariaSearchResults: ', results found.',
  searchNoMatch: 'Not finding yours?',
  search: 'Search',
  searchFranchiseNotFound: 'No franchises were found',
  searchPayrollNotFound: 'Payroll provider not found',
  searchPayrollZeroResults: ({ searchText }) =>
    `We couldn't find any results for "${searchText}". Try searching for your payroll provider instead.`,
  continueOnCustomer: ({ customerName }) => `Continue on ${customerName}`,
  confirm: 'Confirm',
  returnToCustomer: ({ customerName }) => `Return to ${customerName}`,
  manualFallbackCta: `Set up manually`,
  findPayrollProvider: 'Find your payroll provider',
  cancel: 'Cancel',
  changeDistributionTitlePercent: `What percent of each paycheck?`,
  changeDistributionTitleFixed: `How much of each paycheck?`,
  ariaUpdateDepositMessage: 'Updated deposit amount to; ',
  close: 'Close',
  depositAccountAriaLabel: ({ ctx }) => {
    const labelEnding = ctx.isActionable
      ? ' Select this button to make changes or continue to confirmation button.'
      : ' If this information is correct, continue to confirmation button.'

    return `${ctx.name}, with account ending in: ${ctx.number}, will receive: ${ctx.amount} distribution. ${labelEnding}`
  },
  searchFor: 'search for,',
  searchTip: 'results will appear below as you type',
  enterValidEmail: 'Enter a valid email address',
  ariaSelectedOption: ', option selected.',
  next: 'Next',
  searchResults: 'search results',
  or: 'or',
  searchPayrollProviders: 'Search payroll providers',
  poweredByAtomic: 'Powered by Atomic',
  yes: 'Yes',
  no: 'No',
  back: 'Back',
  done: 'Done',
  backToSearch: 'Back to search',
  backToLogin: 'Back to login',
  backToPrefilledForm: 'Back to prefilled form',
  backToConfirmDistribution: 'Back to deposit form',
  tryAgain: 'Try again',
  formattedAs: 'Formatted as',
  loading: 'Loading',
  manageDirectDeposit: 'Manage Direct Deposit',
  disclaimer: ({ customerName }) =>
    `${customerName} uses Atomic to power our direct deposit setup experience. Your use of Atomic is subject to their`,
}
