export const socialSecuritySsi = {
  title: 'Set up your Social Security deposit',
  step1: {
    title:
      'Update your Social Security Income direct deposit by calling the Social Security Administration at:',
    phone: '1-800-772-1213',
    phoneTTY: '(TTY 1-800-325-0778)',
    hours: 'Monday through Friday, 8:00 a.m. - 7:00 p.m. local time.',
  },
  step2: {
    title: 'Provide your bank account info:',
  },
  showAccountNumbers: 'Show account number',
}
