<template>
  <component
    class="modal-content-container"
    :is="element"
    id="modalDescription"
  >
    <slot></slot>
  </component>
</template>

<script>
export default {
  name: 'ModalDescription',
  props: {
    element: {
      type: String,
      validator: (propValue) => {
        return propValue === 'p' || propValue === 'div'
      },
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-content-container {
  height: 100%;
  margin-top: calc(var(--marginBase) * 3);
}
</style>
