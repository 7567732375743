<template>
  <SkeletonTemplate>
    <!-- Title -->
    <SkeletonBlock
      class="mt-6"
      shape="box"
      shapeWidth="80%"
      shapeHeight="32px"
    />

    <!-- Description -->
    <SkeletonBlock
      class="mt-2"
      shape="box"
      shapeWidth="70%"
      shapeHeight="24px"
    />

    <div class="mt-6 d-flex justify-content-between">
      <SkeletonBlock
        class="mr-2"
        shape="box"
        shapeWidth="100px"
        shapeHeight="24px"
      />
      <SkeletonBlock shape="box" shapeWidth="80px" shapeHeight="24px" />
    </div>

    <SkeletonBlock
      class="mt-2"
      shape="box"
      shapeWidth="100%"
      shapeHeight="56px"
    />

    <SkeletonBlock
      class="mt-2"
      shape="box"
      shapeWidth="100%"
      shapeHeight="56px"
    />

    <SkeletonBlock
      class="mt-2"
      shape="box"
      shapeWidth="100%"
      shapeHeight="56px"
    />
  </SkeletonTemplate>
</template>

<script>
import SkeletonTemplate from '@/components/SkeletonLoader/SkeletonTemplate.vue'
import SkeletonBlock from '@/components/SkeletonLoader/SkeletonBlock.vue'

export default {
  name: 'PayLinkHomeSkeleton',
  components: {
    SkeletonTemplate,
    SkeletonBlock,
  },
}
</script>

<style scoped>
/* Add any specific styles for the skeleton loader here */
</style>
