<template>
  <component :is="element" id="modalLabel" ref="modalLabel" tabindex="-1">
    <slot></slot>
  </component>
</template>

<script>
import { MODAL_ANIMATION_DURATION } from '@/util/animation'

export default {
  name: 'ModalLabel',
  props: {
    level: {
      type: Number,
      validator: (propValue) => {
        return propValue > 0 && propValue < 7
      },
      required: true,
    },
  },
  computed: {
    element() {
      return `h${this.level}`
    },
  },
  mounted() {
    setTimeout(() => {
      this.$refs.modalLabel?.focus()
    }, MODAL_ANIMATION_DURATION + 50)
  },
}
</script>
