import { useActor } from '@xstate/vue'
import { computed } from 'vue'
import { interpret } from 'xstate'

import { store } from '@/store'

import { authenticationMachine } from './machine'

export { AUTHENTICATION_EVENTS } from './events'
export { AUTHENTICATION_STATES } from './states'

export const authenticationService = interpret(authenticationMachine, {
  devTools: import.meta.env.DEV,
}).onTransition((state) => {
  // this is necessary, as the back button store in vuex cannot reference the
  // computed property
  store.dispatch('main/updateAuthStateWorkaround', state)
})

export const useAuthenticationService = () =>
  useActor(computed(() => authenticationService))
