export const shared = {
  continue: 'Continuar',
  ariaSearchResults: ', resultados encontrados.',
  searchNoMatch: '¿No encuentras el suyo?',
  search: 'Buscar por nombre',
  searchFranchiseNotFound: 'Franquicia no encontrada',
  searchPayrollNotFound: 'Proveedor de nómina no encontrado',
  searchPayrollZeroResults: ({ searchText }) =>
    `No pudimos encontrar resultados para "${searchText}".`,
  continueOnCustomer: ({ customerName }) => `Continuar en ${customerName}`,
  confirm: 'Confirmar',
  returnToCustomer: ({ customerName }) => `Regresar a ${customerName}`,
  manualFallbackCta: 'Configurar manualmente',
  findPayrollProvider: 'Encuentra su proveedor de nómina',
  exit: 'Salir',
  cancel: 'Cancelar',
  changeDistributionTitlePercent: '¿Qué porcentaje de cada sueldo?',
  changeDistributionTitleFixed: '¿Cuánto de cada sueldo?',
  ariaUpdateDepositMessage: 'Cantidad de depósito actualizada a; ',
  close: 'Salir',
  depositAccountAriaLabel: ({ ctx }) => {
    const labelEnding = ctx.isActionable
      ? ' Seleccione este botón para realizar cambios o continuar con el botón de confirmación.'
      : ' Si esta información es correcta, continúe con el botón de confirmación.'

    return `${ctx.name}, con cuenta terminada en: ${ctx.number}, recibirá: ${ctx.amount} distribución. ${labelEnding}`
  },
  searchFor: 'buscar,',
  searchTip: 'los resultados aparecerán abajo a medida que escriba',
  enterValidEmail: 'Ingresa un e-mail valido',
  ariaSelectedOption: ', opción seleccionada.',
  next: 'Siguiente',
  searchResults: 'Resultados de la búsqueda',
  or: 'o',
  searchPayrollProviders: 'Buscar su proveedor de nómina',
  poweredByAtomic: 'Proporcionado por Atomic',
  yes: 'Sí',
  no: 'No',
  done: 'Listo',
  back: 'Volver',
  backToSearch: 'Volver a buscar',
  backToLogin: 'Volver a iniciar sesión',
  backToPrefilledForm: 'Volver a formulario precompletado',
  backToConfirmDistribution: 'Volver a formulario de deposito',
  tryAgain: 'Inténtalo de nuevo',
  formattedAs: 'Formateado como',
  loading: 'Cargando',
  manageDirectDeposit: 'ADMINISTRAR DEPÓSITO DIRECTO',
  disclaimer: ({ customerName }) =>
    `${customerName} usa Atomic para configurar su depósito directo. Su uso de Atomic está sujeto a sus `,
}
