const state = {
  activeElementHistory: [],
  modalTriggerElementId: null,
  isNavigatingBackward: false,
}

const actions = {
  addActiveElement({ commit }, payload) {
    commit('addActiveElement', payload)
  },
  addModalTriggerElementId({ commit }, payload) {
    commit('addModalTriggerElementId', payload)
  },
  clearModalTriggerElementId({ commit }) {
    commit('clearModalTriggerElementId')
  },
  updateElementHistory({ commit }, payload) {
    commit('updateElementHistory', payload)
  },
  updateIsNavigatingBackward({ commit }, payload) {
    commit('updateIsNavigatingBackward', payload)
  },
}

const mutations = {
  addActiveElement: (state, value) => {
    if (
      state.activeElementHistory[state.activeElementHistory.length - 1] !==
      value
    ) {
      state.activeElementHistory.push(value)
    }
  },
  addModalTriggerElementId: (state, value) => {
    state.modalTriggerElementId = value
  },
  clearModalTriggerElementId: (state) => {
    state.modalTriggerElementId = null
  },
  updateElementHistory(state, value) {
    state.activeElementHistory.length = value
  },
  updateIsNavigatingBackward(state, value) {
    state.isNavigatingBackward = value
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
