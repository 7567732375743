import {
  ADP_CONNECTOR_IDS,
  AMAZON_COMPANY_IDS,
  SOCIAL_SECURITY_CONNECTOR_IDS,
  SOCIAL_SECURITY_ADMINISTRATION_CONNECTOR_IDS,
  WORKDAY_CONNECTOR_IDS,
} from '@/util/constants'

export function isADPConnector(connector) {
  return Object.values(ADP_CONNECTOR_IDS).includes(connector?._id)
}

export function isAmazonCatchall(company) {
  return company?._id === '5e4c4d18b7d75c37aac54a47'
}

export function isAmazonCompany(companyId) {
  return Object.values(AMAZON_COMPANY_IDS).includes(companyId)
}

export function isSSConnector(connector) {
  return SOCIAL_SECURITY_CONNECTOR_IDS.includes(connector?._id)
}

export function isSSAConnector(connector) {
  return SOCIAL_SECURITY_ADMINISTRATION_CONNECTOR_IDS.includes(connector?._id)
}

export function isSystemAfterHours(connector) {
  if (isSSConnector(connector)) {
    return _isAfterSocialSecurityHours()
  }
}

export function isRoutingNumberBlocked({ connector, customer, account }) {
  // If the connector does not have any blocked routing numbers, return
  if (!connector.capabilities?.blockedRoutingNumbers?.length) return

  if (
    _selectedAccountIsBlocked({
      blockedRoutingNumbers: connector.capabilities?.blockedRoutingNumbers,
      selectedAccount: account,
    }) ||
    _customerRoutingIsBlocked({
      customerRoutingNumbers: customer?.routingNumbers,
      blockedRoutingNumbers: connector.capabilities?.blockedRoutingNumbers,
    })
  )
    return true
}

export function isWorkdayConnector(connector) {
  return Object.values(WORKDAY_CONNECTOR_IDS).includes(connector?._id)
}

function _isAfterSocialSecurityHours() {
  const now = _convertTimezone(new Date(), 'America/Toronto') // Eastern time
  const hours = now.getHours()
  const minutes = now.getMinutes()
  const day = now.getDay()
  // Monday - Friday, 5am - 1am
  if (day >= 1 && day <= 5) {
    if (hours >= 5) {
      // 5am-1am
      return false
    } else {
      return true
    }
  } else if (day === 6) {
    // Saturday, 5am - 11pm
    if (hours >= 5 && hours <= 23) {
      return false
    } else {
      return true
    }
  } else {
    // Sunday, 8am - 11:30pm
    if (hours >= 8 && hours <= 23 && minutes <= 30) {
      return false
    } else {
      return true
    }
  }
}

function _convertTimezone(date, tzString) {
  return new Date(
    (typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', {
      timeZone: tzString,
    }),
  )
}

function _selectedAccountIsBlocked({ blockedRoutingNumbers, selectedAccount }) {
  return blockedRoutingNumbers.includes(selectedAccount?.routingNumber)
}

function _customerRoutingIsBlocked({
  customerRoutingNumbers,
  blockedRoutingNumbers,
}) {
  return (
    customerRoutingNumbers?.length === 1 &&
    blockedRoutingNumbers.includes(customerRoutingNumbers[0])
  )
}
