export const adpInterstitial = {
  iDoNotRememberMyCredentials: "No, I don't remember",
  iRememberMyCredentials: 'Yes, I know my credentials',
  title: 'Do you remember your ADP User ID & password?',
  subtitle:
    "It's okay if you don't remember. You can login by verifying some information.",
  wrongConnector: "I don't use ADP",
  coAuthTitle: "Let's find your ADP account",
  coAuthSubtitle:
    'Atomic will use your personal details to lookup your ADP account so you can update your direct deposit.',
}
