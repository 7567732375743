import { store } from '@/store'
import { emitSdkEvent } from '@/util/sdk'
import { AUTHENTICATION_METHODS, SDK_EVENT_TYPES } from '@/util/constants'
import { cloneDeep } from 'lodash-es'
import Mixpanel from '@/plugins/mixpanel'
import { isLocalDev } from '@/util/environment'

let analyticsInstance

export default {
  install: (app) => {
    analyticsInstance = {
      track: ({ transformPayload = true, ...options }) => {
        if (!transformPayload) {
          _trackEvent({ options })

          return
        }

        options.payload = options.payload || {}
        options.payload.customer = store.getters['user/customerName']
        options.payload.customerId = store.getters['user/customerId']
        options.payload.windowWidth = document.documentElement.clientWidth
        options.payload.windowHeight = document.documentElement.clientHeight
        if (store.getters['user/userIdentifier']) {
          options.payload.userIdentifier = store.getters['user/userIdentifier']
        }
        if (store.getters['user/parentCustomerName']) {
          options.payload.parentCustomer =
            store.getters['user/parentCustomerName']
        }
        if (store.getters['user/parentCustomerId']) {
          options.payload.parentCustomerId =
            store.getters['user/parentCustomerId']
        }
        if (store.getters['user/customerTags']) {
          options.payload.customerTags = store.getters['user/customerTags']
        }
        if (store.state.experiment.abTestActive) {
          options.payload.abTestVariants = cloneDeep(
            store.getters['experiment/abTestVariants'],
          )
        }
        options.payload.language = store.state.i18n.language
        options.payload.taskWorkflowType =
          store.getters['taskWorkflow/taskWorkflowType']
        options.payload.scope = store.state.main.scope
        options.payload.product = store.getters['taskWorkflow/products'][0]

        if (store.getters['company/companyName']) {
          // Allow company to be passed in
          if (!options.payload.company) {
            options.payload.company = store.getters['company/companyName']
          }
          options.payload.automationDevice =
            store.getters['taskWorkflow/automationDevice']
          options.payload.authenticationMethod =
            store.getters['taskWorkflow/authenticationMethod']

          // Due to the dumb rebrand of Uplink, I mean nothing wrong with the name True Auth, just dumb for development
          // We are keeping the code branded to Uplink as the authentication method, but changing the public value
          // to `true-auth`
          if (
            options.payload.authenticationMethod ===
            AUTHENTICATION_METHODS.UPLINK
          ) {
            options.payload.authenticationMethod = 'true-auth'
          }
        }

        if (store.state.task.taskId) {
          options.payload.taskId = store.state.task.taskId
        }

        if (store.getters['company/companyId']) {
          options.payload.companyId = store.getters['company/companyId']
        }

        if (store.getters['company/connectorName']) {
          options.payload.payroll = store.getters['company/connectorName']
        }
        if (store.getters['company/connectorId']) {
          options.payload.payrollId = store.getters['company/connectorId']
        }
        if (store.getters['authenticator/usingAuthenticator']) {
          const { _id, name } =
            store.getters['authenticator/selectedAuthenticator']?.connector ??
            {}

          options.payload.authenticator = name
          options.payload.authenticatorId = _id
        }
        if (store.state.main.metadata) {
          options.payload.metadata = store.state.main.metadata
        }
        if (store.getters['company/usesSmartAuth']) {
          options.payload.usesSmartAuth = true
        }
        if (store.state.experiment.ldFlags) {
          options.payload.featureFlags = cloneDeep(
            store.state.experiment.ldFlags,
          )
        }

        options.payload.platform = cloneDeep(store.getters['main/platform'])

        if (store.state.main.sessionContext) {
          options.payload.sessionContext = store.state.main.sessionContext
        }

        if (store.state.search.searchRule.id) {
          options.payload.searchRuleId = store.state.search.searchRule.id
        }

        _trackEvent({ options })
      },
    }
    app.config.globalProperties.$analytics = analyticsInstance
  },
  get() {
    return analyticsInstance
  },
}

function _trackEvent({ options }) {
  // Using cloned data to allow functionality to still work and not mutate the original data
  const clonedData = cloneDeep(options.payload)
  const mixpanelPayload = {
    name: options.event,
    value: _sanitizeDataForMixpanel({ data: clonedData }),
  }

  if (
    store.state.analytics.trackAnalyticsEvents ||
    options.trackAnalyticsEventsOverride
  ) {
    Mixpanel.get().track(mixpanelPayload.name, mixpanelPayload.value)
  }

  const sdkPayload = {
    name: options.event,
    value: _sanitizeDataForSDK({ data: clonedData }),
  }

  if (!options.internal) {
    emitSdkEvent(SDK_EVENT_TYPES.INTERACTION, sdkPayload)
  } else if (isLocalDev()) {
    console.log('INTERNAL:', sdkPayload)
  }
}

const _removeAccountInfo = (data) => {
  delete data.config?.deeplink?.data?.account
  delete data.config?.deeplink?.data?.routing

  return data
}

function _sanitizeDataForSDK({ data }) {
  delete data.metadata
  delete data.featureFlags
  delete data.authenticator
  delete data.authenticatorId
  delete data.survey
  _removeAccountInfo(data)

  return data
}

function _sanitizeDataForMixpanel({ data }) {
  return _removeAccountInfo(data)
}
