<template>
  <div class="content" :class="{ dark: useDarkMode }">
    <div class="icon-container">
      <!-- SVG needs to be inlined, so we can serve during offline mode -->
      <span class="icon-dot">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_20214_76637)">
            <path
              d="M1 1L23 23"
              stroke="#FFB020"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16.7197 11.0601C17.5388 11.4598 18.3048 11.9604 18.9997 12.5501"
              stroke="#FFB020"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5 12.5502C6.48208 11.311 8.26587 10.4864 10.17 10.1602"
              stroke="#FFB020"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.71 5.05023C12.8524 4.8776 15.0076 5.13839 17.047 5.81705C19.0864 6.4957 20.9682 7.5783 22.58 9.00023"
              stroke="#FFB020"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1.41992 9.00012C2.80709 7.77397 4.39762 6.79934 6.11992 6.12012"
              stroke="#FFB020"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.53027 16.1102C9.54548 15.389 10.7599 15.0015 12.0053 15.0015C13.2506 15.0015 14.4651 15.389 15.4803 16.1102"
              stroke="#FFB020"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12 20H12.01"
              stroke="#FFB020"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_20214_76637">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </span>
      <span class="icon-ring"></span>
    </div>
    <ModalLabel :level="3" class="paragraph">{{
      phrases.errorMessage.connectionLost
    }}</ModalLabel>
    <ModalDescription element="p" class="paragraph reconnecting">{{
      phrases.errorMessage.connectionLostDesc
    }}</ModalDescription>
  </div>
</template>

<script>
import ModalLabel from '@/components/Modal/ModalLabel.vue'
import ModalDescription from '@/components/Modal/ModalDescription.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'NoInternetConnection',
  components: { ModalDescription, ModalLabel },
  computed: {
    ...mapGetters('theme', ['useDarkMode']),
  },
}
</script>

<style lang="scss" scoped>
.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;

  h3 {
    color: var(--gray-900);
  }

  &.dark {
    h3 {
      color: var(--gray-100);
    }
  }

  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px;

    .icon-dot {
      width: 56px;
      height: 56px;
      background-color: var(--warn-100);
      background-clip: padding-box;
      z-index: 100;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
    }

    .icon-ring {
      position: absolute;
      background: 0 0;
      width: 88px;
      height: 88px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      size: ledger;
      border: 6px solid var(--warn-100);
      opacity: 0;
      z-index: 10;
      pointer-events: none;
      border-radius: 50%;
      background-clip: padding-box;
      animation: pulse 3s ease-out infinite;
    }
  }

  .reconnecting {
    margin-right: -16px;
    width: 172px;

    &:after {
      overflow: hidden;
      display: inline-block;
      vertical-align: bottom;
      -webkit-animation: ellipsis steps(4, end) 1600ms infinite;
      animation: ellipsis steps(4, end) 1600ms infinite;
      content: '\2026'; /* ascii code for the ellipsis character */
      width: 0px;
    }
  }
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  25% {
    transform: scale(0);
    opacity: 0.3;
  }

  50% {
    transform: scale(0.2);
    opacity: 0.5;
  }

  75% {
    transform: scale(0.6);
    opacity: 0.7;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}
</style>
