import anime from 'animejs'

const SLIDE_UP_POPOVER_WRAP = {
  targets: '.popover-wrap',
  translateY: ['100%', 0],
  duration: 50,
  easing: 'easeOutQuart',
}

const SLIDE_DOWN_POPOVER_WRAP = {
  targets: '.popover-wrap',
  translateY: [0, '100%'],
  duration: 400,
  easing: 'easeInSine',
}

const BLUR_POPOVER_WRAP = {
  targets: '.popover-wrap',
  backdropFilter: ['blur(0px)', 'blur(15px)'],
  webkitBackdropFilter: ['blur(0px)', 'blur(15px)'],
  duration: 350,
  easing: 'easeOutQuart',
}

const FOCUS_POPOVER_WRAP = {
  targets: '.popover-wrap',
  backdropFilter: ['blur(15px)', 'blur(0px)'],
  webkitBackdropFilter: ['blur(15px)', 'blur(0px)'],
  duration: 250,
  easing: 'easeOutQuart',
}

const FADE_IN_POPOVER_OVERLAY = {
  targets: '.popover-overlay',
  opacity: [0, 1],
  duration: 200,
  easing: 'easeOutQuart',
}

const FADE_OUT_POPOVER_OVERLAY = {
  targets: '.popover-overlay',
  opacity: [1, 0],
  duration: 200,
  easing: 'easeOutQuart',
}

const SLIDE_UP_POPOVER_CONTENT = {
  targets: '.popover-content',
  translateX: ['-50%', '-50%'],
  translateY: ['100%', '-50%'],
  easing: 'spring(1, 100, 15, 5)',
}

const SLIDE_DOWN_POPOVER_CONTENT = {
  targets: '.popover-content',
  translateX: ['-50%', '-50%'],
  translateY: ['-50%', '100%'],
  duration: 400,
  easing: 'easeOutQuart',
}

export function openPopoverAnimation() {
  anime
    .timeline({ easing: 'linear' })
    .add(SLIDE_UP_POPOVER_WRAP)
    .add(BLUR_POPOVER_WRAP)
    .add(FADE_IN_POPOVER_OVERLAY, '-=350')
    .add(SLIDE_UP_POPOVER_CONTENT)
}

export function closePopoverAnimation() {
  anime
    .timeline({ easing: 'linear' })
    .add(SLIDE_DOWN_POPOVER_CONTENT)
    .add(FADE_OUT_POPOVER_OVERLAY)
    .add(FOCUS_POPOVER_WRAP, '-=250')
    .add(SLIDE_DOWN_POPOVER_WRAP)
}
