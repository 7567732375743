<template>
  <div class="overlay-wrap" :class="{ dark: useDarkMode }">
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'AppOverlay',
  computed: {
    ...mapGetters('theme', ['overlayColor', 'useDarkMode']),
  },
}
</script>

<style lang="scss" scoped>
.overlay-wrap {
  background-color: var(--overlayColor);
  opacity: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;

  @media (max-width: 850px) {
    background-color: var(--white);

    &.dark {
      background-color: var(--darkModeBg);
    }
  }
}
</style>
