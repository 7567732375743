<template>
  <svg
    width="39"
    height="39"
    viewBox="0 0 39 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="security-icon-svg"
    aria-hidden="true"
  >
    <path
      d="M20 27.3334C20 27.3334 26.6667 24 26.6667 19V13.1667L20 10.6667L13.3334 13.1667V19C13.3334 24 20 27.3334 20 27.3334Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconAudit',
}
</script>
