import * as fingerprint from './fingerprint.js'
import * as uplink from './uplink.js'
import * as page from './page.js'

/** @param { import('@atomicfi/rpc').RPC } rpc */
export function register(rpc) {
  fingerprint.register(rpc)
  uplink.register(rpc)
  page.register(rpc)
}

export default {
  register,
}
