import { PAGE_TRANSITIONS } from '@/util/constants'

const state = {
  transitionName: PAGE_TRANSITIONS.SLIDE_LEFT,
  skeletonLoaderVisible: true,
}

const getters = {}

const actions = {
  updateTransitionName({ commit }, payload) {
    commit('setTransitionName', payload)
  },
  updateSkeletonLoaderVisibility({ commit }, value) {
    commit('setSkeletonLoaderVisibility', value)
  },
}

const mutations = {
  setTransitionName: (state, value) => {
    state.transitionName = value
  },
  setSkeletonLoaderVisibility: (state, value) => {
    state.skeletonLoaderVisible = value
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
