export const ERROR_CODES = {
  GLOBAL_40000: 40000,
  GLOBAL_40103: 40103,
  GLOBAL_40104: 40104,
  GLOBAL_40107: 40107,
  GLOBAL_40108: 40108,
  GLOBAL_40109: 40109,
  GLOBAL_50X: '50X',
  GLOBAL_TIMEOUT: 'timeout',
  GLOBAL_SDK_PARAMETERS: 'sdk-parameters',
  GLOBAL_MAINTENANCE_MODE: 50101,
  GLOBAL_WITHHOLD_NOT_SUPPORTED: 'withhold-not-supported',
}
