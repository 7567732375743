export const directDepositForm = {
  directDepositAuthorizationForm: 'Direct deposit authorization',
  legalText: ({ customerName }) =>
    `This form states I want direct deposit to be set up for my ${customerName} account. It includes relevant account and routing numbers along with a copy of my voided check. If the information provided here is not sufficient, please let me know.`,
  employeeNameLabel: 'Account holder',
  bankNameLabel: 'Bank name',
  accountTypeLabel: 'Account type',
  payTo: 'Pay to the order of',
  dollars: 'Dollars',
  routingNumber: 'Routing number',
  accountNumber: 'Account number',
  voidedCheckTitle: 'Copy of voided check',
  void: 'VOID',
  accountDetailsTitle: 'Account details',
  checkDescription:
    'The image of this voided check may be provided to your employer or other payer for no other purpose except to set up direct deposit.',
  authorizationTitle: 'Authorization',
  iAuthorize: ({ customerName }) =>
    `I authorize my employer to deposit my paychecks directly into my ${customerName} account.`,
  iWish: ({ amount }) => `${amount} `,
  signature: 'Signature',
  date: 'Date',
  footerCopyright: ({ customerName }) => `${customerName} Member FDIC.`,
  footerCopyrightFDIC: ({ customerName }) => `${customerName} Member FDIC.`,
  footerCopyrightNCUA: ({ customerName }) => `${customerName} Member NCUA.`,
}
