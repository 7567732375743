import {
  PAGES,
  PAY_LINK_USER_STATUS,
  USER_COMPANY_SELECTION_STATUS,
  SELECTION_REMOVAL_STRATEGY,
  MODAL_VIEW,
  USER_COMPANY_SELECTION_SOURCE,
} from '@/util/constants'
import {
  determinePaymentMethodForConnector,
  getPaymentMethodType,
  getPaymentMethodAccountType,
  addManagingCompany,
  applyOptimisticStatus,
  createSelectionAnalyticsPayload,
} from '@/util/pay-link'
import { negate, isEmpty } from 'lodash-es'

const {
  SELECTED,
  COMPLETED,
  IN_PROGRESS,
  REQUIRES_USER_INPUT,
  MANAGING_COMPANY_FOUND,
  FAILED,
} = USER_COMPANY_SELECTION_STATUS

const state = () => ({
  isLoading: false,
  isRefreshingPaymentMethods: false,
  selections: [],
  selectionsToAdd: [],
  selectionsToRemove: [],
  optimisticStatuses: {},
  paymentMethod: undefined,
  /** The payment method most recently selected by the user */
  userSelectedPaymentMethod: undefined,
})

const getters = {
  visibleSelections(state) {
    return [...state.selections, ...state.selectionsToAdd]
      .map(addManagingCompany)
      .map(applyOptimisticStatus(state.optimisticStatuses))
  },
  activeSelection(state, getters, rootState, rootGetters) {
    return getters.visibleSelections.find(
      ({ company }) => company._id === rootGetters['company/companyId'],
    )
  },
  companyIsSelected(state, getters) {
    return (company) => getters.visibleSelections.some(_companyMatches(company))
  },
  companySelectionStatus(state, getters) {
    return (company) =>
      getters.visibleSelections.find(_companyMatches(company))?.status
  },
  /** Used for identifying the next action when the user presses "Continue" */
  actionableSelections(state, getters) {
    const ACTIONABLE_STATUSES = [
      REQUIRES_USER_INPUT,
      MANAGING_COMPANY_FOUND,
      SELECTED,
      FAILED,
    ]
    return getters.visibleSelections
      .filter(_hasStatus(...ACTIONABLE_STATUSES))
      .sort(_byStatus(...ACTIONABLE_STATUSES))
  },
  /** Shown in the "Pending" section of the PayLink home screen */
  pendingSelections(state, getters) {
    const PENDING_STATUSES = [
      REQUIRES_USER_INPUT,
      MANAGING_COMPANY_FOUND,
      IN_PROGRESS,
    ]
    return getters.visibleSelections
      .filter(_hasStatus(...PENDING_STATUSES))
      .sort(_byStatus(...PENDING_STATUSES))
  },
  /** Shown in the "Selected" section of the PayLink home screen */
  onDeckSelections(state, getters) {
    const ON_DECK_STATUSES = [SELECTED, FAILED]
    return getters.visibleSelections
      .filter(_hasStatus(...ON_DECK_STATUSES))
      .sort(_byStatus(...ON_DECK_STATUSES))
  },
  selectedSelections(state, getters) {
    return getters.visibleSelections.filter(_hasStatus(SELECTED))
  },
  completedSelections(state, getters) {
    return getters.visibleSelections.filter(_hasStatus(COMPLETED))
  },
  inProgressSelections(state, getters) {
    return getters.visibleSelections.filter(_hasStatus(IN_PROGRESS))
  },
  nextSelection(state, getters) {
    return getters.actionableSelections[0]
  },
  userStatus(state, getters) {
    const statusChecks = {
      [PAY_LINK_USER_STATUS.NOT_STARTED]: () =>
        !getters.visibleSelections.length,
      [PAY_LINK_USER_STATUS.PRESELECTED]: () =>
        getters.visibleSelections.every(
          (selection) =>
            selection.status === SELECTED &&
            selection.source === USER_COMPANY_SELECTION_SOURCE.CUSTOMER,
        ),
      [PAY_LINK_USER_STATUS.SELECTING]: () =>
        getters.visibleSelections.every(_hasStatus(SELECTED)),
      [PAY_LINK_USER_STATUS.FINISHED]: () =>
        getters.visibleSelections.every(_hasStatus(COMPLETED)),
      [PAY_LINK_USER_STATUS.IN_PROGRESS]: () => true,
    }

    const [userStatus] = Object.entries(statusChecks).find(([, check]) =>
      check(),
    )

    return userStatus
  },
}

const mutations = {
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading
  },
  setIsRefreshingPaymentMethods(state, isRefreshing) {
    state.isRefreshingPaymentMethods = isRefreshing
  },
  setSelections(state, selections) {
    state.selections = selections
  },
  setSelectionsToAdd(state, selections) {
    state.selectionsToAdd = selections
  },
  setSelectionsToRemove(state, selections) {
    state.selectionsToRemove = selections
  },
  setPaymentMethod(state, paymentMethod) {
    state.paymentMethod = paymentMethod
      ? {
          ...paymentMethod,
          accountType: getPaymentMethodAccountType(paymentMethod),
          type: getPaymentMethodType(paymentMethod),
        }
      : paymentMethod
  },
  setUserSelectedPaymentMethod(state, paymentMethod) {
    state.userSelectedPaymentMethod = paymentMethod
  },
  addOptimisticStatus(state, { selection, status }) {
    state.optimisticStatuses[selection._id] = {
      originalStatus: selection.status,
      optimisticStatus: status,
    }
  },
  removeOptimisticStatus(state, selectionId) {
    delete state.optimisticStatuses[selectionId]
  },
}

const createPayLinkActions = (payLinkService, Analytics) => ({
  addSelection({ state, commit }, company) {
    commit('setSelectionsToAdd', [
      ...state.selectionsToAdd,
      {
        company,
        status: SELECTED,
      },
    ])
  },
  updateSelections({ commit, state, getters }, selections) {
    // Clear stale optimistic statuses
    if (!isEmpty(state.optimisticStatuses)) {
      selections.forEach((selection) => {
        const isOptimisticStatusStale =
          state.optimisticStatuses[selection._id] &&
          state.optimisticStatuses[selection._id].originalStatus !==
            selection.status
        if (isOptimisticStatusStale) {
          commit('removeOptimisticStatus', selection._id)
        }
      })
    }

    commit('setSelections', selections)

    // If a selection from selectionsToAdd is now in selections, it has been
    // added and can be removed from selectionsToAdd
    commit(
      'setSelectionsToAdd',
      state.selectionsToAdd.filter(negate(_isInSelections(selections))),
    )

    // If a selection from selectionsToRemove is no longer in selections, it
    // has been deleted and can be removed from selectionsToRemove
    commit(
      'setSelectionsToRemove',
      state.selectionsToRemove.filter(_isInSelections(selections)),
    )

    window.atomicStorage.setItem('payLinkUserStatus', getters.userStatus)
  },
  updateIsLoading({ commit }, isLoading) {
    commit('setIsLoading', isLoading)
  },
  updateIsRefreshingPaymentMethods({ commit }, isRefreshing) {
    commit('setIsRefreshingPaymentMethods', isRefreshing)
  },
  handleClickSelectionFromSearch({ getters, dispatch }, { company }) {
    const existingSelection = getters.visibleSelections.find(
      _companyMatches(company),
    )
    if (existingSelection) {
      if (existingSelection.status === SELECTED) {
        Analytics.get().track({
          event: `Deselected Company From ${PAGES.SEARCH_COMPANY}`,
          payload: { company: company.name },
        })
        dispatch('removeSelection', {
          selection: existingSelection,
          strategy: SELECTION_REMOVAL_STRATEGY.BATCH,
        })
      } else {
        dispatch('openSelectionDetail', existingSelection)
      }
    } else {
      Analytics.get().track({
        event: `Selected Company From ${PAGES.SEARCH_COMPANY}`,
        payload: { company: company.name },
      })
      dispatch('addSelection', company)
    }
  },
  openSelectionDetail({ dispatch }, selection) {
    dispatch(
      'modal/openModal',
      {
        view: MODAL_VIEW.PAYLINK_DETAIL,
        overlay: true,
        data: { selection },
      },
      { root: true },
    )
  },
  async saveSelections({ state }) {
    if (!state.selectionsToAdd.length && !state.selectionsToRemove.length)
      return

    await payLinkService.patchSelections({
      companyIdsToAdd: state.selectionsToAdd.map(
        (selection) => selection.company._id,
      ),
      selectionIdsToRemove: state.selectionsToRemove.map(
        (selection) => selection._id,
      ),
    })
  },
  removeSelection(
    { state, commit },
    { selection, strategy = SELECTION_REMOVAL_STRATEGY.SINGLE },
  ) {
    const isSavedSelection = Boolean(selection._id)

    if (!isSavedSelection) {
      commit(
        'setSelectionsToAdd',
        state.selectionsToAdd.filter(
          negate(_companyMatches(selection.company)),
        ),
      )
      return
    }

    commit(
      'setSelections',
      state.selections.filter(negate(_companyMatches(selection.company))),
    )
    if (strategy === SELECTION_REMOVAL_STRATEGY.BATCH) {
      commit('setSelectionsToRemove', [...state.selectionsToRemove, selection])
    } else {
      payLinkService.patchSelections({
        selectionIdsToRemove: [selection._id],
      })
    }
  },
  setOptimisticStatus({ state, commit }, { selection, status }) {
    commit('addOptimisticStatus', { selection, status })

    setTimeout(() => {
      if (state.optimisticStatuses[selection._id]) {
        const { originalStatus, optimisticStatus } =
          state.optimisticStatuses[selection._id]

        console.error('Optimistic Status Not Confirmed Within Deadline', {
          selectionId: selection._id,
          originalStatus,
          optimisticStatus,
        })
        Analytics.get().track({
          event: 'Optimistic Status Not Confirmed Within Deadline',
          payload: createSelectionAnalyticsPayload(selection, {
            originalStatus,
            optimisticStatus,
          }),
        })
      }
    }, 10000)
  },
  async swapSelection({ state, dispatch }, { selection, replacementCompany }) {
    const existingSelection =
      state.selectionsToAdd.find(_companyMatches(replacementCompany)) ||
      state.selections.find(_companyMatches(replacementCompany))

    if (!existingSelection) {
      dispatch('addSelection', replacementCompany)
    }

    dispatch('removeSelection', {
      selection,
      strategy: SELECTION_REMOVAL_STRATEGY.BATCH,
    })

    dispatch('saveSelections')
  },
  updatePaymentMethodForConnector({ state, rootState, rootGetters, commit }) {
    const paymentMethod = determinePaymentMethodForConnector({
      cards: rootGetters['user/cardPaymentMethods'],
      accounts: rootState.user.userData.accounts,
      userSelectedPaymentMethod: state.userSelectedPaymentMethod,
      connector: rootState.company.connector,
    })

    commit('setPaymentMethod', paymentMethod)
  },
  updatePaymentMethod({ commit }, { paymentMethod, userSelected = false }) {
    commit('setPaymentMethod', paymentMethod)
    if (userSelected) {
      commit('setUserSelectedPaymentMethod', paymentMethod)
    }
  },
  patchPaymentMethod({ commit, state }, { paymentMethod }) {
    commit('setPaymentMethod', paymentMethod)
    if (state.userSelectedPaymentMethod?._id === paymentMethod._id) {
      commit('setUserSelectedPaymentMethod', paymentMethod)
    }
  },
})

function _isInSelections(selections) {
  return (selection) => selections.some(_companyMatches(selection.company))
}

function _companyMatches(company) {
  return (selection) => selection.company._id === company?._id
}

function _hasStatus(...statuses) {
  return (selection) => statuses.includes(selection.status)
}

/** Sorts selections in order of the provided statuses */
function _byStatus(...statuses) {
  return (a, b) => {
    const indexA = statuses.indexOf(a.status)
    const indexB = statuses.indexOf(b.status)
    return indexA - indexB
  }
}

export const createPayLinkModule = (payLinkService, Analytics) => ({
  namespaced: true,
  state,
  getters,
  mutations,
  actions: createPayLinkActions(payLinkService, Analytics),
})
