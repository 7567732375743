const state = {
  completion: 0,
}

const actions = {
  updateProgressBar({ commit }, completion) {
    commit('setProgressBar', completion)
  },
}

const mutations = {
  setProgressBar: (state, completion) => {
    state.completion = completion
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
