<template>
  <SkeletonTemplate>
    <div class="mt-3 d-flex align-items-center logos">
      <SkeletonBlock shape="circle" shapeWidth="56px" shapeHeight="56px" />
      <SkeletonBlock
        class="logo-overlap"
        shape="circle"
        shapeWidth="56px"
        shapeHeight="56px"
      />
    </div>
    <SkeletonBlock
      class="mt-2"
      shape="box"
      shapeWidth="300px"
      shapeHeight="32px"
    />
    <SkeletonBlock
      class="mt-1"
      shape="box"
      shapeWidth="200px"
      shapeHeight="32px"
    />
    <SkeletonBlock
      class="mt-5"
      shape="box"
      shapeWidth="172px"
      shapeHeight="24px"
    />
    <SkeletonBlock
      class="mt-2"
      shape="box"
      shapeWidth="272px"
      shapeHeight="48px"
    />
    <SkeletonBlock
      class="mt-5"
      shape="box"
      shapeWidth="172px"
      shapeHeight="24px"
    />
    <SkeletonBlock
      class="mt-2"
      shape="box"
      shapeWidth="272px"
      shapeHeight="48px"
    />
    <SkeletonBlock
      class="mt-auto"
      shape="box"
      shapeWidth="100%"
      shapeHeight="56px"
    />
  </SkeletonTemplate>
</template>

<script>
import SkeletonTemplate from '@/components/SkeletonLoader/SkeletonTemplate.vue'
import SkeletonBlock from '@/components/SkeletonLoader/SkeletonBlock.vue'
export default {
  name: 'WelcomeSkeleton',
  components: {
    SkeletonTemplate,
    SkeletonBlock,
  },
}
</script>

<style lang="scss" scoped>
.logo-overlap {
  margin-left: -8px;
  box-shadow: -2px 0px 0px 2px var(--white);

  &.dark {
    box-shadow: -2px 0px 0px 2px var(--gray-1000);
  }
}
</style>
