export const exitModal = {
  generic: {
    tax: {
      title: '¿Salir de la vinculación de documentos fiscales?',
      description:
        'Su progreso se perderá y cualquier detalle ingresado será eliminado por su seguridad.',
    },
    deposit: {
      title: '¿Salir de la configuración de depósito directo?',
      description:
        'Su progreso se perderá y cualquier detalle ingresado será eliminado por su seguridad.',
    },
    verify: {
      title: '¿No está listo para vincular su cuenta?',
      description:
        'Su progreso se perderá y cualquier detalle ingresado será eliminado por su seguridad.',
    },
    switch: {
      title: '¿Ha terminado de cambiar los pagos?',
      description: 'Puede volver en cualquier momento para cambiar más pagos.',
    },
    present: {
      title: '¿Ha terminado de conectar comerciantes?',
      description:
        'Puede volver en cualquier momento para conectar más comerciantes.',
    },
  },
  search: {
    title: '¿No puede encontrar a su empleador o proveedor de nómina?',
    description:
      'Consulte los consejos para encontrar a su proveedor de pago o configure el depósito directo manualmente.',
    helperAction: 'Cómo encontrar a su empleador/proveedor de nómina',
  },
  login: {
    title: '¿Tiene problemas para iniciar sesión?',
    description:
      'Aprenda dónde encontrar sus credenciales o configure el depósito directo manualmente.',
    helperAction: 'Cómo encontrar sus credenciales',
  },
  distribution: {
    title: '¿No está listo para actualizar su depósito directo?',
    canEditTitle: '¿No está seguro de cuánto depositar directamente?',
    description: {
      canChange:
        'La mayoría de las personas eligen todo su cheque de pago, pero también puede establecer una cantidad menor.',
      noChange:
        'Vuelva en cualquier momento para terminar de actualizar su depósito directo.',
    },
  },
  actionItems: {
    changeDepositAmount: 'Cambiar monto del depósito',
    setUpManually: 'Configurar manualmente',
  },
}
