<template>
  <ModalContent>
    <ModalBody>
      <ModalContentWrap>
        <DynamicHeader
          :title="phrases.findCredentialsHelpModal.title"
          textAlignment="text-center"
        />
        <ul>
          <HelpStep
            symbol="A"
            :title="getStep1().title"
            :description="getStep1().description"
          />
          <HelpStep
            v-if="!taskWorkflowTypeIsTaxWith1040s"
            symbol="B"
            :title="phrases.findCredentialsHelpModal.step2.title"
            :description="phrases.findCredentialsHelpModal.step2.description"
          />
          <HelpStep
            v-if="!taskWorkflowTypeIsTaxWith1040s"
            symbol="C"
            :title="phrases.findCredentialsHelpModal.step3.title"
            :description="phrases.findCredentialsHelpModal.step3.description"
          />
        </ul>

        <TextButton
          class="mt-2"
          data-test-id="close"
          btnStyle="hollow"
          :text="phrases.shared.close"
          @handleClick="handleClickClose"
        />
      </ModalContentWrap>
    </ModalBody>
  </ModalContent>
</template>

<script>
import ModalContent from '@/components/Modal/ModalContent.vue'
import ModalBody from '@/components/Modal/ModalBody.vue'
import ModalContentWrap from '@/components/Modal/ModalContentWrap.vue'
import DynamicHeader from '@/components/Header/DynamicHeader.vue'
import HelpStep from '@/components/Shared/HelpStep.vue'
import TextButton from '@/components/Form/TextButton.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'FindCredentialsHelpModal',
  components: {
    ModalContentWrap,
    ModalBody,
    ModalContent,
    DynamicHeader,
    HelpStep,
    TextButton,
  },
  data: () => ({}),
  computed: {
    ...mapGetters('taskWorkflow', ['taskWorkflowTypeIsTaxWith1040s']),
  },
  methods: {
    ...mapActions('modal', ['closeModal']),
    async handleClickClose() {
      this.$analytics.track({
        event: 'Closed How To Find Credentials Modal',
      })
      await this.closeModal()
    },
    getStep1() {
      return this.taskWorkflowTypeIsTaxWith1040s
        ? {
            title: this.phrases.findCredentialsHelpModal.step1Tax.title,
            description:
              this.phrases.findCredentialsHelpModal.step1Tax.description,
          }
        : {
            title: this.phrases.findCredentialsHelpModal.step1.title,
            description:
              this.phrases.findCredentialsHelpModal.step1.description,
          }
    },
  },
}
</script>

<style lang="scss" scoped>
.step {
  display: flex;
  .step-content {
    margin-left: calc(var(--marginBase) * 2);
    .title {
      color: var(--gray-800);
      margin-top: 4px;

      .dark & {
        color: var(--gray-300);
      }
    }
  }
}
</style>
