const state = {
  spiedRequestHeaders: {},
  spiedResponseHeaders: {},
  interceptedData: {},
  sensitiveData: new Map(),
  cardData: new Map(),
}

const mutations = {
  setSpiedRequestHeaders: (state, spiedRequestHeaders) => {
    state.spiedRequestHeaders = {
      ...state.spiedRequestHeaders,
      ...spiedRequestHeaders,
    }
  },
  setSpiedResponseHeaders: (state, spiedResponseHeaders) => {
    state.spiedResponseHeaders = {
      ...state.spiedResponseHeaders,
      ...spiedResponseHeaders,
    }
  },
  setInterceptedData: (state, interceptedData) => {
    state.interceptedData = {
      ...state.interceptedData,
      ...interceptedData,
    }
  },
  setSensitiveData: (state, { key, value }) => {
    state.sensitiveData.set(key, value)
  },
  setCardData: (state, { pageHandle, cardData }) => {
    state.cardData.set(pageHandle, cardData)
  },
  deleteCardData: (state, pageHandle) => {
    state.cardData.delete(pageHandle)
  },
}

const actions = {
  mergeSpiedRequestHeaders({ commit }, requestHeaders = {}) {
    commit('setSpiedRequestHeaders', requestHeaders)
  },
  mergeSpiedResponseHeaders({ commit }, responseHeaders = {}) {
    commit('setSpiedResponseHeaders', responseHeaders)
  },
  mergeInterceptedData({ commit }, interceptedData = {}) {
    commit('setInterceptedData', interceptedData)
  },
  addSensitiveData({ commit }, { key, value }) {
    commit('setSensitiveData', { key, value })
  },
  addCardData({ commit }, { pageHandle, cardData }) {
    commit('setCardData', { pageHandle, cardData })
  },
  removeCardData({ commit }, pageHandle) {
    commit('deleteCardData', pageHandle)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
