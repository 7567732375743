<template>
  <ModalContent>
    <ModalBody>
      <ModalContentWrap>
        <DistributionAmount
          :distribution-type="modalState.data.distributionType"
          @cancel="closeModal"
          @choseAmount="choseDistribution"
          @changedDistribution="changedDistribution"
        />
      </ModalContentWrap>
    </ModalBody>
  </ModalContent>
</template>

<script>
import DistributionAmount from '@/components/Distribution/DistributionAmount.vue'
import ModalBody from '@/components/Modal/ModalBody.vue'
import ModalContent from '@/components/Modal/ModalContent.vue'
import ModalContentWrap from '@/components/Modal/ModalContentWrap.vue'
import { mapActions, mapState } from 'vuex'
import { TASK_WORKFLOW_VIEW } from '@/util/constants'

export default {
  name: 'DistributionOptionsModal',
  components: {
    ModalContent,
    ModalContentWrap,
    ModalBody,
    DistributionAmount,
  },
  computed: {
    ...mapState('distribution', [
      'distributionState',
      'distributionType',
      'distributionAmount',
    ]),
    ...mapState('modal', ['modalState']),
  },
  methods: {
    ...mapActions('modal', ['closeModal']),
    ...mapActions('distribution', [
      'handleFractionalDepositUpdates',
      'commitNewDistributionSettings',
      'resetNewDistributionSettings',
    ]),
    ...mapActions('taskWorkflow', ['updateTaskWorkflowState']),
    async choseDistribution() {
      await this.commitNewDistributionSettings()
      await this.closeModal()
    },
    async changedDistribution() {
      await this.commitNewDistributionSettings()
      this.handleFractionalDepositUpdates()
      await this.updateTaskWorkflowState({
        view: TASK_WORKFLOW_VIEW.IN_PROGRESS,
      })
      await this.closeModal()
    },
  },
}
</script>
