<template>
  <SkeletonTemplate>
    <div class="d-flex flex-1 fd-column justify-content-center">
      <div class="d-flex justify-content-center">
        <SkeletonBlock
          class="mr-1"
          shape="box"
          shapeWidth="50px"
          shapeHeight="50px"
        />
        <SkeletonBlock
          class="ml-1"
          shape="box"
          shapeWidth="50px"
          shapeHeight="50px"
        />
      </div>
      <SkeletonBlock
        class="mt-2"
        shape="box"
        shapeWidth="100%"
        shapeHeight="40px"
      />
      <SkeletonBlock
        class="mt-2"
        shape="box"
        shapeWidth="100%"
        shapeHeight="40px"
      />
    </div>
    <div class="mt-auto">
      <SkeletonBlock shape="box" shapeWidth="100%" shapeHeight="56px" />
      <SkeletonBlock
        class="mt-2"
        shape="box"
        shapeWidth="100%"
        shapeHeight="56px"
      />
    </div>
  </SkeletonTemplate>
</template>

<script>
import SkeletonTemplate from '@/components/SkeletonLoader/SkeletonTemplate.vue'
import SkeletonBlock from '@/components/SkeletonLoader/SkeletonBlock.vue'

export default {
  name: 'PreselectedCompanySingleSkeleton',
  components: { SkeletonBlock, SkeletonTemplate },
}
</script>

<style scoped></style>
