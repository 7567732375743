import {
  REQUEST_TYPES,
  ROUTES,
  SDK_EVENT_TYPES,
  DATA_REQUESTS,
  DISTRIBUTION_TYPES,
  AUTHENTICATION_METHODS,
} from '@/util/constants'
import {
  generateTaskWorkflowBrandingLogos,
  initTaskWorkflow,
  showOnlyBackgroundLogo,
} from '@/util/task-workflow'
import { emitSdkEvent } from '@/util/sdk'
import { isCard, hasValidToken } from '@/util/pay-link'
import Analytics from '@/plugins/analytics'

export async function prepareAuthenticationPage({ store }) {
  _handleAuthenticators({ store })
  _handleTaskWorkflowBranding({ store })
  _handleDistributionConfig({ store })
  _handleConfigurationParameters({ store })
  _handleSmartAuthAI({ store })
  await _getFeatureFlags({ store })
}

/**
 * Decides where to go when trying to authenticate with a connector
 */
export async function navigateToAuthenticationPage({ store, router }) {
  await prepareAuthenticationPage({ store })

  await router.push({ name: ROUTES.TASK })
}

async function _getFeatureFlags({ store }) {
  await store.dispatch('experiment/fetchLdFeatureFlags', {
    companyId: store.getters['company/companyId'],
  })
}

function _handleAuthenticators({ store }) {
  const { authenticators } = store.state.authenticator

  store.dispatch('authenticator/updateAuthenticatorId', undefined)
  store.dispatch('authenticator/updateAuthenticatorOptional', true)

  // If there are connectors and one is flagged as NOT optional, then
  // replace the current connector with the authenticator's connector
  setAuthenticators({ store, authenticators })
}

export function setAuthenticators({ store, authenticators }) {
  if (authenticators && authenticators.length) {
    authenticators.forEach((authenticator) => {
      if (authenticator.optional === false) {
        store.dispatch('company/updateActiveConnector', authenticator.connector)
        store.dispatch('authenticator/updateAuthenticatorId', authenticator._id)
        store.dispatch('authenticator/updateAuthenticatorOptional', false)
      }
    })
  }
}

/**
 * Determines what logos to show for the Task flow
 */
function _handleTaskWorkflowBranding({ store }) {
  if (store.getters['company/usesMultiplePayrollProviders']) {
    showOnlyBackgroundLogo(store.state.company)
  } else {
    generateTaskWorkflowBrandingLogos({ store })
  }
}

/**
 * Creates a task from the form data retrieved from the user
 *
 * @param formData - Data retrieved from form inputs
 * @param requestType - The type of request this is
 */
export async function submitTaskFormData({ formData, requestType, store }) {
  const analyticsInstance = Analytics.get()

  switch (requestType) {
    case REQUEST_TYPES.AUTHENTICATION:
      analyticsInstance.track({
        event: 'Clicked Button To Start Authentication',
        payload: {
          distributionType: store.state.distribution.distributionType,
          ...(store.state.distribution.distributionType !==
            DISTRIBUTION_TYPES.TOTAL && {
            distributionAmount: store.state.distribution.distributionAmount,
          }),
        },
      })

      if (
        store.getters['taskWorkflow/authenticationMethod'] !==
        AUTHENTICATION_METHODS.UPLINK
      ) {
        await store.dispatch('task/startTaskTimer')
      }

      await initTaskWorkflow({ store, userInput: formData })
      break
    case REQUEST_TYPES.LOGIN_RECOVERY:
      analyticsInstance.track({
        event: 'Clicked Button To Start Login Recovery',
        payload: {
          distributionType: store.state.distribution.distributionType,
        },
      })
      await initTaskWorkflow({ store, userInput: formData })
      break
    case REQUEST_TYPES.PRE_SCREENED_LOGIN:
      analyticsInstance.track({
        event: 'Clicked Button To Start Pre-Screened Login',
      })
      await initTaskWorkflow({ store })
      break
    case REQUEST_TYPES.EXTERNAL_LOGIN_RECOVERY:
    case REQUEST_TYPES.MFA:
    case REQUEST_TYPES.SMART_AUTH_FORM:
    case REQUEST_TYPES.FORM:
      await store.dispatch('task/taskResponseHandler', formData)
      break
  }

  // If we don't have the bank account information yet because the customer will pass
  // it to us on demand, and a deposit is being done, tell the customer we need their
  // data now
  await _requestAccountDataIfNeeded({ store, analyticsInstance })
  // If we don't have the card information yet because the customer will pass
  // it to us on demand, and a switch is being done, tell the customer we need their
  // data now
  await _requestCardDataIfNeeded({ store, analyticsInstance })

  // Since the user has begun a task, mark them as accepting our terms and conditions
  // so they are not presented with the element that says they are our terms and conditions again
  await store.dispatch('main/updateInitializeTerms', { hasAccepted: true })
}

// TODO: This function can be shared between this file and util/task-workflow.js
async function _requestCardDataIfNeeded({ store }) {
  const paymentMethod = store.state.payLink.paymentMethod

  if (
    store.getters['taskWorkflow/productsIncludeSwitch'] &&
    isCard(paymentMethod) &&
    !hasValidToken(paymentMethod)
  ) {
    Analytics.get().track({ event: 'Task Requires Card Information' })

    emitSdkEvent(SDK_EVENT_TYPES.DATA_REQUEST, {
      fields: [DATA_REQUESTS.CARD],
      taskWorkflowId: store.state.taskWorkflow.taskWorkflowId,
      taskId: store.state.task.taskId,
      userId: store.state.user.userData._id,
      identifier: store.state.user.userData.identifier,
      properties: {
        lastFour: paymentMethod.lastFour,
        title: paymentMethod.title,
      },
    })

    // TODO: Do we need this?
    await store.dispatch('task/updateHasRequestedDataFromCustomer', true)
  }
}

async function _requestAccountDataIfNeeded({ store, analyticsInstance }) {
  if (
    !store.state.user.account &&
    !store.state.task.hasRequestedDataFromCustomer &&
    store.getters['taskWorkflow/productsIncludeDeposit']
  ) {
    analyticsInstance.track({ event: 'Task Requires Account Information' })
    emitSdkEvent(SDK_EVENT_TYPES.DATA_REQUEST, {
      fields: [DATA_REQUESTS.ACCOUNT],
      taskWorkflowId: store.state.taskWorkflow.taskWorkflowId,
      taskId: store.state.task.taskId,
      userId: store.state.user.userData._id,
      identifier: store.state.user.userData.identifier,
    })
    await store.dispatch('task/updateHasRequestedDataFromCustomer', true)
  }
}

function _handleDistributionConfig({ store }) {
  // Set the distribution type to "TOTAL" if clicked on payroll provider that doesn't support
  // fractional deposits

  // We are now showing gig providers in search results when a distribution is provided AND
  // they have canUpdate set to true. Modifying this check so that the store doesn't update
  // too early that would affect how search results are seen when pressing back.
  if (
    !store.getters['company/connectorSupportsFractionalDeposits'] &&
    !store.getters['company/isGigProvider']
  ) {
    store.dispatch(
      'distribution/updateDistributionType',
      DISTRIBUTION_TYPES.TOTAL,
    )
  }
}

function _handleConfigurationParameters({ store }) {
  if (store.state.company.isConfiguredWithParameters) {
    store.dispatch('search/clearSearchedCompanyName')
  }
}

function _handleSmartAuthAI({ store }) {
  store.dispatch('smartAuth/resetAuthState')
}
