<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    :style="iconStyles"
    v-bind="svgProps"
  >
    <line x1="5" y1="12" x2="19" y2="12"></line>
    <polyline points="12 5 19 12 12 19"></polyline>
  </svg>
</template>
<script>
import IconBase from '@/components/Icons/IconBase.vue'

export default {
  name: 'IconArrowRight',
  extends: IconBase,
}
</script>
