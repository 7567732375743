<template>
  <div class="scrollable-wrap" :class="{ 'no-padding': noPadding }">
    <div
      :data-test-id="dataTestId"
      class="scrollable-container"
      :style="{ 'padding-bottom': customBottomPadding }"
    >
      <FadeBar position="top" />
      <slot></slot>
      <FadeBar position="bottom" :square-edges="squareEdges" />
    </div>
  </div>
</template>

<script>
import FadeBar from '@/components/Shared/FadeBar.vue'

export default {
  name: 'ScrollableWrap',
  components: {
    FadeBar,
  },
  props: {
    noPadding: Boolean,
    dataTestId: String,
    squareEdges: Boolean,
    customBottomPadding: String,
  },
}
</script>

<style lang="scss" scoped>
.scrollable-wrap {
  flex: 1;
  overflow: hidden;
  position: relative;
  transition: all 200ms ease-in-out;
  transform: translate3d(0, 0, 0);
  &.no-padding {
    padding: 0;
  }
}
.scrollable-container {
  scrollbar-width: thin;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  min-height: 200px;
  padding: 16px 0 32px;
}
</style>
