import sleep from 'await-sleep'
import { emitSdkEvent } from '@/util/sdk'
import { SDK_EVENT_TYPES } from '@/util/constants'
import { sendInternalDeferredEvaluateAction } from './deferred-action'

/**
 * Trigger polling is awesome. It allows us to continuously run commands on the client's browser.
 * They are like the interrupts on our backend RPA processes. Every half second, we can run any
 * number of commands and get the responses back from the client's browser (which actually executes the JavaScript).
 *
 * Currently, there are two primary triggers.
 *
 * The assistant title text trigger, if it returns truthy, will show some helper text in the client's browser
 * to help the user along their merry way
 *
 * The action evaluator triggers, are like if / else statements. If the `detector` function returns truthy, then it
 * will execute the `executor` function. These are helpful to do simple automation for the user to help them along the
 * process. Some examples are like helping them move through their company's VPN bridge dashboards, or helping the user
 * get to a page that includes a form.
 *
 * These triggers leverage the DeferredAction class, which lets us defer an action to be run using the Promise class.
 * The code is awaited while we send the function to be executed to the client's device, and then to return back the response.
 * Once that is complete, the DeferredAction's promise is resolve,d and the code continues.
 */
export async function beginTriggerPolling({ store, page }) {
  // Wait for two seconds to allow the initial navigation to finish
  await sleep(1000)

  // Specify that we want to start running the triggers. This is set to false when the user closes out of Uplink.
  await store.dispatch('userDeviceAutomation/updateActiveSession', {
    updates: { runTriggers: true },
  })

  const { triggers } =
    store.getters['userDeviceAutomation/parsedUserAutomationDeviceConfig']

  _pollTriggers({ store, triggers, page })
}

async function _pollTriggers({ store, triggers, page }) {
  while (store.state.userDeviceAutomation.activeSession.runTriggers) {
    await _handleAssistantTitleTextTrigger({
      store,
      page,
      assistantTitleTextTrigger: triggers.assistantTitleTextTrigger,
    })

    await _handleActionEvaluatorTriggers({
      store,
      page,
      actionEvaluatorTriggers: triggers.actionEvaluatorTriggers,
    })

    await sleep(300)
  }
}

async function _handleAssistantTitleTextTrigger({
  store,
  page,
  assistantTitleTextTrigger,
}) {
  if (
    !_verifiedTriggerShouldRunNow({ store, trigger: assistantTitleTextTrigger })
  ) {
    return
  }

  const result = await _getTriggerResult({
    store,
    page,
    action: assistantTitleTextTrigger.executor,
  })

  emitSdkEvent(SDK_EVENT_TYPES.AUTOMATION_HANDOFF, {
    type: result ? 'show-assistant-title-text' : 'hide-assistant-title-text',
    text: result,
    pageHandle: page?.handle?.toString(),
  })
}

async function _handleActionEvaluatorTriggers({
  store,
  page,
  actionEvaluatorTriggers,
}) {
  for (let trigger of actionEvaluatorTriggers) {
    if (!_verifiedTriggerShouldRunNow({ store, trigger })) {
      continue
    }

    const detectorResult = await _getTriggerResult({
      store,
      page,
      action: trigger.detector,
    })

    if (detectorResult) {
      await _getTriggerResult({
        store,
        page,
        action: trigger.executor,
      })
    }
  }
}

async function _getTriggerResult({ store, page, action }) {
  if (page) {
    return page.evaluate(action.stringifiedFunctionToEvaluate, [
      action.functionParameters,
    ])
  } else {
    return sendInternalDeferredEvaluateAction({ store, action })
  }
}

function _verifiedTriggerShouldRunNow({ store, trigger }) {
  return (
    (trigger.executeDuringAuth &&
      !store.state.userDeviceAutomation.activeSession.authenticationComplete) ||
    (trigger.executeAfterAuth &&
      store.state.userDeviceAutomation.activeSession.authenticationComplete)
  )
}
