import fontLoader from 'webfontloader'
import { ATOMIC_BRANDING } from '@/util/constants'
const DEFAULT_REGULAR_WEIGHT = 500
const DEFAULT_BOLD_WEIGHT = 700

const FONT_VARS = {
  BODY: '--font',
  BUTTONS: '--buttonFont',
  HEADINGS: '--headingFont',
}

/**
 *
 * @param {Array} fontList An array of objects containing { name: String, area: String}
 */
export function loadFonts(fontList) {
  const googleFonts = _buildGoogleFontList(fontList)

  if (googleFonts.length > 0) {
    fontLoader.load({
      google: {
        families: googleFonts,
      },
      active: function () {
        _updateFontVariables(fontList)
      },
    })
  } else {
    _updateFontVariables(fontList)
  }
}

/**
 *
 * @param {Array} fontList
 * @returns Array of google font API friendly fonts with weights
 */
function _buildGoogleFontList(fontList) {
  const fontFamiliesWithWeights = new Map()

  fontList
    .filter(
      (f) => f.name !== ATOMIC_BRANDING.DEFAULT_FONT && f.name !== undefined,
    )
    .forEach((font) => {
      if (!fontFamiliesWithWeights.has(font.name)) {
        fontFamiliesWithWeights.set(font.name, [_getFontAreaWeight(font)])
      } else {
        const currentWeights = fontFamiliesWithWeights.get(font.name)
        const areaWeight = _getFontAreaWeight(font)

        if (!currentWeights.includes(areaWeight)) {
          fontFamiliesWithWeights.set(font.name, [
            ...currentWeights,
            areaWeight,
          ])
        }
      }
    })

  const googleFontList = Array.from(fontFamiliesWithWeights).map(
    ([fontFamily, fontWeights]) => `${fontFamily}:${String(fontWeights)}`,
  )

  return googleFontList
}

/**
 *
 * @param {{ name: String, area: String}} fontObject
 * @returns
 */
function _getFontAreaWeight({ name, area }) {
  if (!SUPPORTED_FONTS.has(name)) {
    return null
  }

  let fontConfig = SUPPORTED_FONTS.get(name)
  if (area === 'HEADINGS') {
    return fontConfig.boldWeight ?? DEFAULT_BOLD_WEIGHT
  } else {
    return fontConfig.mediumWeight ?? DEFAULT_REGULAR_WEIGHT
  }
}

/**
 * Apply font updates to CSS variables
 * @param {Object} customFonts
 */
function _updateFontVariables(customFonts) {
  const r = window?.document.querySelector(':root')

  customFonts.forEach((font) => {
    const activeFont = font.name ?? ATOMIC_BRANDING.DEFAULT_FONT
    r.style.setProperty(FONT_VARS[font.area], activeFont)
  })
}

/**
 * Master list of supported fonts.
 * To add a new font: Add a new array entry containing the
 * key (string): font name to load
 * value (object): containing a label (string, required) and a value(string, required)
 */
export const SUPPORTED_FONTS = new Map([
  [
    'Inter',
    {
      label: 'Inter',
      value: 'Inter',
    },
  ],
  [
    'Lato',
    {
      label: 'Lato',
      value: 'Lato',
      mediumWeight: 400,
    },
  ],
  [
    'Montserrat',
    {
      label: 'Montserrat',
      value: 'Montserrat',
    },
  ],
  [
    'Open Sans',
    {
      label: 'Open Sans',
      value: 'Open Sans',
    },
  ],
  [
    'Poppins',
    {
      label: 'Poppins',
      value: 'Poppins',
    },
  ],

  [
    'PT Sans',
    {
      label: 'PT Sans',
      value: 'PT Sans',
      mediumWeight: 400,
    },
  ],

  [
    'Raleway',
    {
      label: 'Raleway',
      value: 'Raleway',
    },
  ],
  [
    'Roboto',
    {
      label: 'Roboto',
      value: 'Roboto',
    },
  ],
  [
    'Roboto Condensed',
    {
      label: 'Roboto Condensed',
      value: 'Roboto Condensed',
      mediumWeight: 400,
    },
  ],
  [
    'Rubik',
    {
      label: 'Rubik',
      value: 'Rubik',
    },
  ],
  [
    'Source Sans Pro',
    {
      label: 'Source Sans Pro',
      value: 'Source Sans Pro',
      mediumWeight: 400,
    },
  ],
])
