<template>
  <ModalContent>
    <ModalBody>
      <div class="content-wrap">
        <ModalLabel :level="1" class="sr-only">ADP Direct Deposit</ModalLabel>
        <Loading v-if="state === $CONSTANTS.LOADING_STATES.LOADING" />
        <iframe
          :srcdoc="`
              <form id='initialize-sdk' method='post' action='${sdkBaseUrl}/core/v1/associate-feature-management/payment-distribution/enrollments'>
                <input type='hidden' name='sessionUUID' placeholder='Access Session Token' value='${modalState.data.accessSessionToken}' />
                <input type='hidden' name='JWToken' placeholder='JWT' value='${modalState.data.jwt}' />
                <input type='hidden' name='redirectUrl' placeholder='Redirect URL' value='*' />
                <input type='hidden' name='keepAliveUrl' placeholder='Keep Alive URL' value='*' />
                <input type='hidden' name='parentHostUrl' value='${originAncestry}' />
                <input type='hidden' name='statusPostUrl' value='${webhookBaseUrl}/webhooks/adp-edde-sdk' />
              </form>
              <script type='text/javascript'>window.onload = function() { document.getElementById('initialize-sdk').submit()}</script>
              `"
          title="ADP Direct Deposit"
          class="sdk-iframe"
          :class="{ loaded: state === $CONSTANTS.LOADING_STATES.LOADED }"
        ></iframe>
      </div>
    </ModalBody>
  </ModalContent>
</template>

<script>
import {
  LOADING_STATES,
  PAGES,
  USER_INPUT_REQUEST_TYPES,
} from '@/util/constants'
import { mapActions, mapState } from 'vuex'
import Loading from '@/components/Shared/Loading.vue'
import ModalContent from '@/components/Modal/ModalContent.vue'
import ModalBody from '@/components/Modal/ModalBody.vue'
import ModalLabel from '@/components/Modal/ModalLabel.vue'

export default {
  name: 'AdpEddeSdkModal',
  components: {
    Loading,
    ModalBody,
    ModalContent,
    ModalLabel,
  },
  data() {
    return {
      state: LOADING_STATES.LOADING,
    }
  },
  computed: {
    ...mapState('environment', ['apiEndpoint', 'adpCoauthEndpoint']),
    ...mapState('modal', ['modalState']),
    originAncestry() {
      let currentOrigin = window.location.origin
      const isEmbeddedIframe = window.location !== window.parent.location
      const ancestry = window.location.ancestorOrigins?.length
        ? [...window.location.ancestorOrigins]
        : [document.referrer]
      const originAncestry = isEmbeddedIframe
        ? [currentOrigin, ...ancestry]
        : [currentOrigin]

      return originAncestry.join(' ')
    },
    sdkBaseUrl() {
      return this.adpCoauthEndpoint
    },
    webhookBaseUrl() {
      return (
        import.meta.env.VITE_APP_ADP_EDDE_SDK_WEBHOOK_BASE_URL ??
        this.apiEndpoint
      )
    },
  },
  methods: {
    ...mapActions('modal', ['closeModal']),
    ...mapActions('task', ['taskResponseHandler']),
    async close() {
      await this.closeModal()
      window.removeEventListener('message', this.processSdkEvent)
    },
    processSdkEvent(event) {
      if (event.origin === this.sdkBaseUrl) {
        switch (event.data.statusCode) {
          case 301: // Application loaded
            this.state = LOADING_STATES.LOADED
            break
          case 302: // Session ended
            this.close()
            break
          // These events are not sent via webhooks and must be processed
          case 700: // User authenticated
            this.taskResponseHandler({
              [USER_INPUT_REQUEST_TYPES.SESSION]: {
                statusCode: event.data.statusCode,
              },
            })
            break
          case 904: // Session expired
          case 905: // Session cancelled
            this.taskResponseHandler({
              [USER_INPUT_REQUEST_TYPES.SESSION]: {
                statusCode: event.data.statusCode,
              },
            })
            this.close()
            break
          // All other status codes are handled via webhook events
          default:
            break
        }
      }
    },
  },
  created() {
    this.$analytics.track({
      event: `Viewed ${PAGES.ADP_EDDE_SDK}`,
    })

    window.addEventListener('message', this.processSdkEvent)
  },
}
</script>

<style lang="scss" scoped>
.content-wrap {
  height: 100%;
}

.sdk-iframe {
  border: none;
  display: none;
  height: 100%;
  width: 100%;

  &.loaded {
    display: block;
  }
}
</style>
