import { store } from '@/store'

const StoreFocusedElement = {
  install: function (Vue) {
    Vue.directive('store-focused-element', {
      beforeMount: function (el, binding) {
        el.addEventListener('click', _bindClick(el, binding))
      },
      unmounted: function (el, binding) {
        el.removeEventListener('click', _bindClick(el, binding))
      },
    })
  },
}

function _bindClick(element, binding) {
  return async () => {
    try {
      if (element.id) {
        store.commit('activeElement/addActiveElement', element.id)
      }

      binding.value.call()
    } catch (error) {
      console.error(error)
    }
  }
}

export default StoreFocusedElement
