<template>
  <ModalContent>
    <ModalBody>
      <ModalContentWrap>
        <DistributionType @choseDistribution="choseDistribution" />
      </ModalContentWrap>
    </ModalBody>
  </ModalContent>
</template>

<script>
import DistributionType from '@/components/Distribution/DistributionType.vue'
import ModalBody from '@/components/Modal/ModalBody.vue'
import ModalContentWrap from '@/components/Modal/ModalContentWrap.vue'
import ModalContent from '@/components/Modal/ModalContent.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'DistributionOptionsModal',
  components: {
    ModalContentWrap,
    ModalContent,
    ModalBody,
    DistributionType,
  },
  computed: {
    ...mapState('distribution', [
      'distributionState',
      'distributionType',
      'distributionAmount',
    ]),
  },
  methods: {
    ...mapActions('modal', ['closeModal']),
    ...mapActions('distribution', [
      'handleFractionalDepositUpdates',
      'commitNewDistributionSettings',
      'resetNewDistributionSettings',
    ]),
    ...mapActions('taskWorkflow', ['updateTaskWorkflowState']),
    async choseDistribution() {
      await this.commitNewDistributionSettings()
      await this.closeModal()
    },
  },
}
</script>
