<template>
  <div
    class="screen"
    :class="{
      'pnc-action-bar': isPNCFeature,
      'cover-action-bar': coverActionBar,
      'no-pad-top': noPadTop,
    }"
  >
    <div
      class="screen-content"
      :class="{ 'justify-content-end': justifyContentEnd }"
    >
      <slot name="content"></slot>
    </div>
    <div class="screen-footer" v-if="hasFooter">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { featureUniqueForPNC } from '@/util/customization'

export default {
  name: 'AppScreen',
  props: {
    isNested: Boolean,
    noPadTop: Boolean,
    justifyContentEnd: Boolean,
  },
  computed: {
    hasFooter() {
      return !!this.$slots['footer']
    },
    ...mapState('user', ['userData']),
    isPNCFeature() {
      const customer = this.userData?.customer
      return featureUniqueForPNC({ customer })
    },
    ...mapState('main', ['coverActionBar']),
  },
}
</script>

<style lang="scss" scoped>
.screen {
  padding-top: calc(var(--appBodyPadding) + env(safe-area-inset-top));
  transition: all 500ms;
  display: flex;
  flex-direction: column;

  &.pnc-action-bar {
    --appBodyPadding: 76px;
  }

  &.cover-action-bar {
    padding-top: calc(8px + env(safe-area-inset-top));
  }

  &.no-pad-top {
    padding-top: 0;
  }
}

.screen-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 1px; // TO ALLOW FOR SCROLLING TO BE ENABLED #WEIRD
}

.screen-footer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  width: 100%;
}
</style>
