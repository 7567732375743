const state = {
  customFailureTitle: undefined,
  customFailureMessage: undefined,
}

const actions = {
  updateCustomMessaging(
    { commit },
    { customFailureTitle, customFailureMessage },
  ) {
    if (customFailureTitle) commit('setCustomFailureTitle', customFailureTitle)
    if (customFailureMessage)
      commit('setCustomFailureMessage', customFailureMessage)
  },
  resetCustomMessaging({ commit }) {
    commit('clearCustomMessaging')
  },
}

const mutations = {
  setCustomFailureTitle: (state, title) => {
    state.customFailureTitle = title
  },
  setCustomFailureMessage: (state, message) => {
    state.customFailureMessage = message
  },
  clearCustomMessaging: (state) => {
    state.customFailureTitle = undefined
    state.customFailureMessage = undefined
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
