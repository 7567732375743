import { store } from '@/store'

export function magic(major, minor, patch) {
  return (major << 16) | (minor << 8) | patch
}

export async function version() {
  // TODO - actually call into sdk to get the version

  const platform = store.state.main.platform

  const { major, minor, patch, preRelease, build } =
    /(?<major>\d+)\.(?<minor>\d+)\.(?<patch>\d+)(?:-(?<preRelease>[^+]+))?(?:\+(?<build>[^-]+))?/.exec(
      platform.sdkVersion,
    )?.groups ?? {}

  const num = (s) => (s ? parseInt(s) : 0)

  return {
    number: magic(num(major), num(minor), num(patch)),
    major: num(major),
    minor: num(minor),
    patch: num(patch),
    preRelease,
    build,
    os: platform.name,
  }
}
