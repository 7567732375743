<template>
  <component
    class="title"
    :is="element"
    id="focusHeading"
    ref="focusHeading"
    tabindex="-1"
  >
    <slot></slot>
  </component>
</template>

<script>
import { mapState } from 'vuex'
import { FOCUS_DELAYS } from '@/util/constants/focus-delays'
import { store } from '@/store'

export default {
  name: 'FocusHeading',
  props: {
    level: {
      type: Number,
      validator: (propValue) => {
        return propValue > 0 && propValue < 7
      },
      required: true,
    },
    currentIdx: {
      type: Number,
      required: false,
    },
    currentStep: {},
    shouldFocus: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState('authenticator', ['authenticatorOptional', 'authenticators']),
    element() {
      return `h${this.level}`
    },
  },
  methods: {
    stepNeedsHeadingFocus() {
      const firstStepWithAuthenticators =
        this.currentIdx === 0 &&
        this.authenticatorOptional &&
        this.authenticators.length > 0

      return (
        firstStepWithAuthenticators ||
        this.currentIdx === null ||
        this.currentStep?.component === 'select'
      )
    },
    focusOnHeading() {
      setTimeout(() => {
        this.$refs.focusHeading?.focus()
      }, FOCUS_DELAYS.HEADER_FOCUS_DELAY)
    },
  },
  mounted() {
    if (
      (this.shouldFocus || this.stepNeedsHeadingFocus()) &&
      !store.state.activeElement.isNavigatingBackward
    ) {
      this.focusOnHeading()
    }
  },
}
</script>
