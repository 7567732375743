export const taskHistory = {
  title: 'Bienvenidos de nuevo',
  subtitle: 'Resumen de sus conexiones',
  continue: 'Conectar otra cuenta',
  remainder: 'Resto',
  entirePaycheck: 'Monto total',
  update: 'Actualizar',
  updated: 'Actualizado',
  updatePending: 'Actualización pendiente...',
  failed: 'Fallido',
  automationNotSupported: 'Automatización no compatible',
  fix: 'Arreglar',
  tryAgain: 'Intentar otra vez',
  setUpManually: 'Configurar manualmente',
  modify: 'Modificar',
  badCredentials: 'Malas credenciales',
  updateFailed: 'Actualización falló',
  unknownError: 'Error desconocido',
}
