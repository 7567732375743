import { TASK_FAIL_REASON } from '@/util/constants'

export function generateTaskFailureMessaging({
  store,
  failReason,
  customFailureTitle,
  customFailureMessage,
}) {
  const failReasonMessaging =
    store.getters['i18n/phrases'].errorMessage[failReason] ??
    store.getters['i18n/phrases'].errorMessage[TASK_FAIL_REASON.UNKNOWN_FAILURE]
  const message = customFailureMessage || failReasonMessaging.message
  const title = customFailureTitle || failReasonMessaging.title

  return { message, title }
}
