<template>
  <span class="area-left">
    <DecorativeImage
      v-if="iconLeft"
      class="mr-1"
      :src="iconLeft"
      aria-hidden="true"
    />
    <span v-if="text.left" class="text text-left">{{ text.left }}</span>
  </span>
</template>

<script>
import DecorativeImage from '@/components/Shared/DecorativeImage.vue'

export default {
  name: 'BaseButtonLeft',
  props: {
    text: [String, Object],
    iconLeft: String,
    fontSize: String,
  },
  components: {
    DecorativeImage,
  },
  computed: {
    buttonFontSize() {
      return this.fontSize || '1.6rem'
    },
  },
}
</script>

<style lang="scss" scoped>
.area-left {
  display: flex;
  align-items: center;
}
.text {
  transition: opacity 100ms linear;
  font-family: var(--buttonFont);
  font-size: v-bind(buttonFontSize);
  font-weight: 500;
}
</style>
