export const manualFallback = {
  otherWays: 'Set up direct deposit manually',
  prefilledMethod: 'Pre-filled form',
  prefilledDescription: `Get a pre-filled form with the info you’ll need to set up direct deposit with your employer.`,
  copyMethod: 'Copy account details',
  copyDescription: `Give your employer your account details for direct deposit setup.`,
  setUpManuallyButton: 'Set up manually',
  showAccountNumber: 'Show account number',
  whichMethodPreferred: 'Which set up method do you prefer?',
  whichMethodPreferredDescription: `Use one of the following methods to set up direct deposit with your employer.`,
  createPrefilledForm: `Let's create your prefilled form`,
  fullName: 'Full name',
  changeTimeline: 'Updates may take 1-2 pay cycles to take effect.',
  ssChangeTimeline: 'Updates may take 30-60 days to take effect.',
  formIsReady: `Your direct deposit form is ready!`,
  generatingForm: 'Please wait while we generate your form...',
  name: 'Name',
  amount: 'Deposit amount',
  account: 'Account',
  routingNumber: 'Routing number',
  accountNumber: 'Account number',
  downloadForm: 'Download form',
  editDepositAmount: 'Change deposit amount',
  useAccountInfoTitle: 'Set up direct deposit using your account details.',
  useAccountInfoDescription:
    'Follow your employer’s process to set up direct deposit using this info.',
  niceNextUp: 'Nice! You’re almost done.',
  giveToHR: `Now follow your employer’s process to set up direct deposit using this info.`,
  entirePaycheck: 'Entire paycheck',
  setUpManuallyHeading: 'Set up direct deposit manually',
  setUpManuallySubheading:
    'Get a pre-filled form or copy your account details.',
}
