import { USER_MESSAGE_TYPES } from '@/util/constants'
import Ably from '@/plugins/ably'

export function subscribeUserCardUpdates({ userId, handler = () => {} }) {
  const userChannel = Ably.get().channels.get(`${userId}:update`)

  // unsubscribe from previous handler to avoid duplicate handlers
  userChannel.unsubscribe(USER_MESSAGE_TYPES.USER_CARDS_UPDATED)
  userChannel.subscribe(USER_MESSAGE_TYPES.USER_CARDS_UPDATED, (message) => {
    if (message?.data.cards) {
      handler({ cards: message.data.cards })
    }
  })
}

export function subscribeUserAccountUpdates({ userId, handler = () => {} }) {
  const userChannel = Ably.get().channels.get(`${userId}:update`)

  // unsubscribe from previous handler to avoid duplicate handlers
  userChannel.unsubscribe(USER_MESSAGE_TYPES.USER_ACCOUNTS_UPDATED)
  userChannel.subscribe(USER_MESSAGE_TYPES.USER_ACCOUNTS_UPDATED, (message) => {
    if (message?.data.accounts) {
      handler({ accounts: message.data.accounts })
    }
  })
}

export function publishUserSessionEvent({ store, eventName, eventData }) {
  Ably.get()
    .channels.get(`${store.state.user.userData._id}:sessions`)
    .publish(eventName, eventData)
}

export function subscribeUserSessionEvent({ store, eventName }, callback) {
  const channel = Ably.get().channels.get(
    `${store.state.user.userData._id}:sessions`,
  )
  channel.subscribe(eventName, callback)
}

export function unsubscribeUserSessionEvent({ store }, callback) {
  const channel = Ably.get().channels.get(
    `${store.state.user.userData._id}:sessions`,
  )
  channel.unsubscribe(callback)
}

export function subscribeTrueAuthAppAnalytics({ store }, callback) {
  subscribeUserSessionEvent(
    { store, eventName: 'true-auth-app-interaction' },
    callback,
  )

  store.dispatch('analytics/updateTrackAnalyticsEvents', false)
  store.dispatch('analytics/updateTrueAuthInteractionEventCallback', callback)
}

export function unsubscribeTrueAuthAppAnalytics({ store }) {
  if (!store.state.analytics.trueAuthInteractionEventCallback) {
    return
  }

  unsubscribeUserSessionEvent(
    { store },
    store.state.analytics.trueAuthInteractionEventCallback,
  )

  store.dispatch('analytics/resetAnalyticsTracking')
}
