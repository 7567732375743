export const interstitial = {
  addCardTitle: ({ customerName, companyName }) =>
    `Vincula de forma segura tu tarjeta ${customerName} a ${companyName}`,
  addCardDescription:
    'Escanea, ingresa o agrega una tarjeta a través de la billetera de tu dispositivo móvil.',
  paymentDescription: 'Después de iniciar sesión, se vinculará tu pago.',
  paymentMethodFeeDisclaimer: ({ companyName, paymentMethodType, fee }) => {
    const paymentMethodDisplayName = {
      credit: 'tarjeta de crédito',
      debit: 'tarjeta de débito',
      ach: 'ACH',
    }[paymentMethodType]

    return `${companyName} puede cobrar una tarifa del ${fee} por pagos con ${paymentMethodDisplayName}.`
  },
  uplink: {
    title: ({ name }) => `Inicia sesión en ${name} para conectar tu cuenta`,
    description:
      'Una vez que inicies sesión, comenzaremos a conectar tu cuenta.',
    continue: ({ name }) => `Iniciar sesión en ${name}`,
  },
}
