<template>
  <ModalContent aria-describedby="">
    <ModalBody>
      <ModalContentWrap>
        <DynamicHeader
          v-if="!managingCompanyFound"
          :title="selection.company.name"
          :subtitle="subtitle"
          :logos="{
            primary: logo,
            replacementText: selection.company.name,
          }"
          id="modalLabel"
        />
        <SelectionDetailModalContentCompleted
          v-if="isCompleted"
          :selection="selection"
          :task="task"
          @close="handleClickClose"
          @update-payment-method="navigateToLogin"
        />
        <SelectionDetailModalContentFailed
          v-else-if="isFailed"
          :selection="selection"
          :task="task"
          @remove-selection="handleRemoveSelection"
          @try-again="navigateToLogin"
          @close="handleClickClose"
        />
        <SelectionDetailModalContentManagingCompanyFound
          v-else-if="managingCompanyFound"
          :selection="selection"
          :task="task"
          @try-with-managing-company="handleTryWithManagingCompany"
          @close="handleClickClose"
        />
        <SelectionDetailModalContentUserInputRequired
          v-else-if="requiresUserInput"
          :selection="selection"
          :task="task"
          @fulfill-user-input-requirement="handleFulfillUserInputRequirement"
          @close="handleClickClose"
        />
        <SelectionDetailModalContentInProgress
          v-else
          :selection="selection"
          :task="task"
          @close="handleClickClose"
        />
      </ModalContentWrap>
    </ModalBody>
  </ModalContent>
</template>

<script>
import ModalContent from '@/components/Modal/ModalContent.vue'
import ModalBody from '@/components/Modal/ModalBody.vue'
import ModalContentWrap from '@/components/Modal/ModalContentWrap.vue'
import DynamicHeader from '@/components/Header/DynamicHeader.vue'
import SelectionDetailModalContentCompleted from '@/components/Switch/SelectionDetailModalContentCompleted.vue'
import SelectionDetailModalContentFailed from '@/components/Switch/SelectionDetailModalContentFailed.vue'
import SelectionDetailModalContentInProgress from '@/components/Switch/SelectionDetailModalContentInProgress.vue'
import SelectionDetailModalContentManagingCompanyFound from '@/components/Switch/SelectionDetailModalContentManagingCompanyFound.vue'
import SelectionDetailModalContentUserInputRequired from '@/components/Switch/SelectionDetailModalContentUserInputRequired.vue'
import { getBrandingLogo } from '@/util/branding'
import { mapActions, mapState } from 'vuex'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { createSelectionAnalyticsPayload } from '@/util/pay-link'
import { navigateToCompanyLogin } from '@/util/navigation'
import {
  navigateToSmartAuthDataRequest,
  navigateToTaskInterrupt,
} from '@/util/pay-link/navigation'
import {
  USER_COMPANY_SELECTION_STATUS,
  PRODUCTS,
  PAGES,
  SELECTION_REMOVAL_STRATEGY,
  USER_MESSAGE_TYPES,
} from '@/util/constants'
import { sleep } from '@/util/animation'

dayjs.extend(advancedFormat)

export default {
  name: 'PayLinkDetailModal',
  components: {
    DynamicHeader,
    ModalContentWrap,
    ModalBody,
    ModalContent,
    SelectionDetailModalContentCompleted,
    SelectionDetailModalContentFailed,
    SelectionDetailModalContentInProgress,
    SelectionDetailModalContentManagingCompanyFound,
    SelectionDetailModalContentUserInputRequired,
  },
  computed: {
    ...mapState('modal', ['modalState']),
    selection() {
      return this.modalState.data.selection
    },
    task() {
      return this.switchTask || this.presentTask
    },
    switchTask() {
      return this.selection.tasks?.find(
        (task) => task.product === PRODUCTS.SWITCH,
      )
    },
    presentTask() {
      return this.selection.tasks?.find(
        (task) => task.product === PRODUCTS.PRESENT,
      )
    },
    logo() {
      return getBrandingLogo(this.selection.company.branding)
    },
    linkedDate() {
      return dayjs(this.task?.createdAt).format('MMM Do, YYYY')
    },
    isFailed() {
      return this.selection.status === USER_COMPANY_SELECTION_STATUS.FAILED
    },
    isInProgress() {
      return this.selection.status === USER_COMPANY_SELECTION_STATUS.IN_PROGRESS
    },
    isCompleted() {
      return this.selection.status === USER_COMPANY_SELECTION_STATUS.COMPLETED
    },
    requiresUserInput() {
      return (
        this.selection.status ===
        USER_COMPANY_SELECTION_STATUS.REQUIRES_USER_INPUT
      )
    },
    managingCompanyFound() {
      return (
        this.selection.status ===
        USER_COMPANY_SELECTION_STATUS.MANAGING_COMPANY_FOUND
      )
    },
    subtitle() {
      return {
        [USER_COMPANY_SELECTION_STATUS.COMPLETED]:
          this.phrases.detail.linkedDate(this.linkedDate),
        [USER_COMPANY_SELECTION_STATUS.FAILED]:
          this.phrases.shared.linkingFailed,
        [USER_COMPANY_SELECTION_STATUS.REQUIRES_USER_INPUT]:
          this.phrases.shared.actionNeeded,
        [USER_COMPANY_SELECTION_STATUS.IN_PROGRESS]:
          this.phrases.shared.linkingInProgress,
      }[this.selection.status]
    },
  },
  methods: {
    ...mapActions('modal', ['closeModal']),
    ...mapActions('payLink', [
      'updateIsLoading',
      'removeSelection',
      'swapSelection',
    ]),
    ...mapActions('task', ['showUplink']),
    async navigateToLogin() {
      const companyId = this.selection.company._id

      this.updateIsLoading(true)
      this.closeModal()
      try {
        await navigateToCompanyLogin({
          companyId,
          store: this.$store,
          router: this.$router,
        })
      } finally {
        this.updateIsLoading(false)
      }
    },
    async handleFulfillUserInputRequirement() {
      this.updateIsLoading(true)
      this.closeModal()

      try {
        switch (this.selection.userInputRequest.type) {
          case 'show-user-inputs':
            if (
              this.selection.userInputRequest.eventName ===
              USER_MESSAGE_TYPES.SMART_AUTH_DATA_REQUEST
            ) {
              await navigateToSmartAuthDataRequest({
                selection: this.selection,
                store: this.$store,
                router: this.$router,
              })
            } else {
              await navigateToTaskInterrupt({
                selection: this.selection,
                store: this.$store,
                router: this.$router,
              })
            }
            break
          case 'show-uplink':
            try {
              await this.showUplink(this.selection.userInputRequest.taskId)
            } finally {
              // Wait until Uplink is shown before hiding loading state
              // Uplink is shown via RPC, so we can't respond to it
              await sleep(1000)
            }
            break
        }
      } finally {
        this.updateIsLoading(false)
      }
    },
    async handleClickClose() {
      this.$analytics.track({
        event: `Closed ${PAGES.SELECTION_DETAILS}`,
        payload: createSelectionAnalyticsPayload(this.selection),
      })
      await this.closeModal()
    },
    handleRemoveSelection() {
      this.removeSelection({
        selection: this.selection,
        strategy: SELECTION_REMOVAL_STRATEGY.SINGLE,
      })
      this.closeModal()
    },
    async handleTryWithManagingCompany(managingCompany) {
      this.updateIsLoading(true)
      this.swapSelection({
        selection: this.selection,
        replacementCompany: managingCompany,
      })
      this.closeModal()

      try {
        await navigateToCompanyLogin({
          companyId: managingCompany._id,
          store: this.$store,
          router: this.$router,
        })
      } finally {
        this.updateIsLoading(false)
      }
    },
  },
  created() {
    this.$analytics.track({
      event: `Viewed ${PAGES.SELECTION_DETAILS}`,
      payload: createSelectionAnalyticsPayload(this.selection),
    })
  },
}
</script>

<style scoped></style>
