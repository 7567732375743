import {
  isADPConnector,
  isSSConnector,
  isSSAConnector,
  isAmazonCompany,
} from '@/util/connector'
import { store } from '@/store'
import { EXPERIMENTS, DEEPLINK_STEPS } from '@/util/constants'
import {
  featureUniqueForGo2Bank,
  featureUniqueForChime,
} from '@/util/customization'

export const authenticationGuards = {
  usingUserAutomatedDevice: () =>
    store.getters['userDeviceAutomation/usingUserAutomatedDevice'],
  activeConnectorIsAdp: _activeConnectorIsADP,
  activeConnectorIsSS: _activeConnectorIsSS,
  showADPInterstitial: _shouldGoToADPInterstitial,
  showUplinkInterstitial: _shouldGoToUplinkInterstitial,
  isTrueAuthSessionContext: () =>
    store.getters['main/isTrueAuthSessionContext'],
  showAmazonInterstitial: _shouldGoToAmazonInterstitial,
  showDoordashInterstitial: _shouldGoToDoordashInterstitial,
  shouldGoBackToAmazonInterstitial: _shouldGoBackToAmazonInterstitial,
  showSocialSecurityInterstitial: _shouldGoToSocialSecurityInterstitial,
  showSocialSecurityLoginOptions: _shouldGoToSocialSecurityLoginOptions,
  showSocialSecuritySsi: _shouldGoToSocialSecurityManual,
  isScopePayLinkAndNotSmartAuth: _isScopePayLinkAndNotSmartAuth,
  showADPLogin: _shouldGoToADPLogin,
  showADPLoginRecovery: _shouldGoToADPLoginRecovery,
  hasPreviousStep: (ctx) => ctx.currentStepIdx > 0,
  shouldStartTask: ({
    showConfirmDistributionPage,
    productsIncludeDeposit,
    updatedDepositExperience,
  }) =>
    updatedDepositExperience ||
    !showConfirmDistributionPage ||
    !productsIncludeDeposit,
  companyHasMultiplePayrollProviders: ({ usesMultiplePayrollProviders }) =>
    usesMultiplePayrollProviders,
  useCoAuthConnector: () => store.getters['company/useCoAuthConnector'],
  useConfigurableConnectorSearch: ({ isConfigurableConnector }) =>
    isConfigurableConnector,
  supportsUserProvidedConnectorParameters: ({
    supportsUserProvidedConnectorParameters,
  }) => supportsUserProvidedConnectorParameters,
  companyUsesSmartAuth: () => store.getters['company/usesSmartAuth'],
  hasUserProvidedFlag: ({ featureFlags }) =>
    !!featureFlags?.transactUserProvidedOrganizationId,
  defaultConnectorUsesSmartAuth: ({ usesSmartAuth }, event) =>
    usesSmartAuth && event.isDefaultConnector,
  showBankInterstitial: (ctx) => _shouldGoToBankInterstitial(ctx),
  canGoBackToSearchFromDeeplink: _canGoBackToSearchFromDeeplink,
}

function _activeConnectorIsADP() {
  return isADPConnector(store.state.company.activeConnector)
}

function _activeCompanyIsAmazonCatchall() {
  return store.state.company?._id === '5e4c4d18b7d75c37aac54a47'
}

function _activeCompanyIsAmazon() {
  return isAmazonCompany(store.state.company._id)
}

function _activeCompanyIsDoordash() {
  return store.state.company?._id === '5df8043e6e517060b0629b41'
}

function _activeConnectorIsSS() {
  return isSSConnector(store.state.company.activeConnector)
}

function _activeConnectorIsSSA() {
  return isSSAConnector(store.state.company.activeConnector)
}

// If onlyAdpLoginRecovery is true AND transactAdpLoginExperiment is true, disregard transactAdpLoginExperiment
// If transactAdpLoginExperiment is true AND is login, then go to the adp login screen
// If transactAdpLoginExperiment is true AND is recovery, then go to the adp login recovery screen
// If transactAdpLoginExperiment is true the default is to go to the adp interstitial screen

function _shouldGoToADPInterstitial(ctx, event) {
  return (
    (_activeConnectorIsADP() || _selectedConnectorIsADP(ctx, event)) &&
    !_shouldGoToBankInterstitial(ctx) && // temporary for the bank interstitial experiment
    !ctx.featureFlags.onlyAdpLoginRecovery &&
    ctx.featureFlags.transactAdpLoginExperiment ===
      EXPERIMENTS.ADP_INTERSTITIAL &&
    !store.getters['main/isTrueAuthSessionContext'] &&
    !store.getters['company/useCoAuthConnector']
  )
}

function _shouldGoToADPLogin(ctx, event) {
  return (
    (_activeConnectorIsADP() || _selectedConnectorIsADP(ctx, event)) &&
    !ctx.featureFlags.onlyAdpLoginRecovery &&
    ctx.featureFlags.transactAdpLoginExperiment === EXPERIMENTS.ADP_LOGIN
  )
}

function _shouldGoToADPLoginRecovery(ctx, event) {
  return (
    (_activeConnectorIsADP() || _selectedConnectorIsADP(ctx, event)) &&
    (ctx.featureFlags.onlyAdpLoginRecovery ||
      ctx.featureFlags.transactAdpLoginExperiment ===
        EXPERIMENTS.ADP_RECOVERY) &&
    !store.getters['main/isTrueAuthSessionContext']
  )
}

function _selectedConnectorIsADP(_, event) {
  return !!event.selectedConnector && isADPConnector(event.selectedConnector)
}

function _shouldGoToUplinkInterstitial() {
  return (
    store.getters['userDeviceAutomation/uplinkIsAvailable'] &&
    !store.getters['main/isTrueAuthSessionContext'] &&
    !store.getters['main/isScopePayLink'] &&
    !store.getters['authentication/hasAttemptedTrueAuthWithEntity'](
      store.getters['company/companyId'],
    ) &&
    _ssInterstitialHasBeenVisited()
  )
}

function _shouldGoToAmazonInterstitial() {
  return _activeCompanyIsAmazonCatchall()
}

function _shouldGoBackToAmazonInterstitial() {
  return _activeCompanyIsAmazon()
}

function _shouldGoToDoordashInterstitial() {
  return (
    _activeCompanyIsDoordash() &&
    !featureUniqueForChime({ customer: store.state.user.userData.customer })
  )
}

function _shouldGoToSocialSecurityInterstitial() {
  return _activeConnectorIsSS()
}

function _shouldGoToSocialSecurityManual() {
  return (
    _activeConnectorIsSSA() &&
    featureUniqueForGo2Bank({ customer: store.state.user.userData.customer })
  )
}

function _shouldGoToSocialSecurityLoginOptions() {
  return (
    _activeConnectorIsSS() &&
    !store.getters['userDeviceAutomation/usingUserAutomatedDevice']
  )
}

function _shouldGoToBankInterstitial(ctx) {
  const companyTags = store.state.company.tags
  return (
    companyTags.includes('financial-institution') &&
    ctx.featureFlags.bankInterstitial
  )
}

function _isScopePayLinkAndNotSmartAuth() {
  return (
    store.getters['main/isScopePayLink'] &&
    !store.getters['company/usesSmartAuth']
  )
}

function _ssInterstitialHasBeenVisited() {
  if (
    !_activeConnectorIsSS() ||
    (store.getters[
      'authentication/hasSeenSocialSecurityInterstitialWithTrueAuth'
    ] &&
      _activeConnectorIsSS())
  ) {
    return true
  } else {
    return false
  }
}

function _canGoBackToSearchFromDeeplink() {
  return (
    store.state.main.deeplink.step === DEEPLINK_STEPS.LOGIN_COMPANY &&
    store.getters['theme/showBackButton']
  )
}
