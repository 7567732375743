import { userAgent } from './user-agent'

export default function ({ userAutomationDeviceConfig, userState }) {
  let parsedPayload = JSON.parse(userAutomationDeviceConfig.payload)

  if (parsedPayload.autofillAssistant && userState.account) {
    parsedPayload.autofillAssistant = _addAutofillsToAssistant({
      userState,
      autofillAssistantJsonString: parsedPayload.autofillAssistant,
    })
  }

  if (!parsedPayload.userAgent) parsedPayload.userAgent = userAgent()

  return parsedPayload
}

function _addAutofillsToAssistant({ userState, autofillAssistantJsonString }) {
  let parsedAutofillAssistant = JSON.parse(autofillAssistantJsonString)

  parsedAutofillAssistant.customerLogo =
    userState.userData.customer?.branding?.logo?.url
  parsedAutofillAssistant.autofills = {
    'routing-number': {
      title: 'Your routing number',
      value: userState.account.routingNumber,
    },
    'account-number': {
      title: 'Your account number',
      value: userState.account.accountNumber,
    },
  }

  return JSON.stringify(parsedAutofillAssistant)
}
