<template>
  <SkeletonTemplate>
    <SkeletonBlock
      class="mt-5"
      shape="box"
      shapeWidth="56px"
      shapeHeight="56px"
    />
    <SkeletonBlock
      class="mt-2"
      shape="box"
      shapeWidth="100%"
      shapeHeight="32px"
    />
    <SkeletonBlock
      class="mt-3"
      shape="box"
      shapeWidth="100%"
      shapeHeight="148px"
    />
    <SkeletonBlock
      class="mt-auto"
      shape="box"
      shapeWidth="100%"
      shapeHeight="40px"
    />
  </SkeletonTemplate>
</template>

<script>
import SkeletonTemplate from '@/components/SkeletonLoader/SkeletonTemplate.vue'
import SkeletonBlock from '@/components/SkeletonLoader/SkeletonBlock.vue'
export default {
  name: 'LoginSkeleton',
  components: {
    SkeletonBlock,
    SkeletonTemplate,
  },
}
</script>
