import { USER_INPUT_ELEMENT_TYPES } from '@/util/constants'
import { get, last, groupBy, entries, pick } from 'lodash-es'

export function parseAuthenticationSteps({ store, connector, elements }) {
  return {
    steps: elements.map((element, idx) => ({
      ...element,
      id: `${connector._id}-${idx}`,
      title:
        element.title ||
        store.getters['i18n/phrases'].login.signInToTitle({
          name: store.getters['company/signingInToName'],
        }),
    })),
  }
}

export function parseSmartAuthSteps({ store, elements }) {
  return {
    steps: elements.map((element) => ({
      ...element,
      title: _getSmartAuthFormTitle({ store }),
    })),
  }
}

function _getSmartAuthFormTitle({ store }) {
  return store.state.smartAuth.activeAuthenticator
    ? store.state.smartAuth.activeAuthenticator.text
    : store.getters['i18n/phrases'].login.signInToTitle({
        name: store.getters['company/signingInToName'],
      })
}

export function getRequiredElementData(element) {
  return pick(element, [
    'id',
    'component',
    'payload',
    'value',
    'meta',
    'processedId',
    'prediction',
  ])
}

export function parseRecoverySteps({ title, subtitle, elements }) {
  return _parseGatherSteps({ title, subtitle, elements })
}

export function parseMfaSteps({ title, subtitle, elements }) {
  return _parseGatherSteps({ title, subtitle, elements })
}

function _parseGatherSteps({ title, subtitle, elements }) {
  const allElementsAreButtons = elements.every(
    (element) => element.component === USER_INPUT_ELEMENT_TYPES.BUTTON,
  )

  if (allElementsAreButtons) {
    return {
      steps: [
        _convertElementsIntoSelect({
          title,
          subtitle,
          elements,
          translatedComponent: USER_INPUT_ELEMENT_TYPES.BUTTON,
        }),
      ],
    }
  }

  const inputElements = elements.filter(
    (element) => element.component !== USER_INPUT_ELEMENT_TYPES.BUTTON,
  )

  const stepsWithConvertedRadios = entries(
    groupBy(inputElements, (element) => `${element.component}.${element.name}`),
  ).map(([elementKey, elements]) => {
    const [component] = elementKey.split('.')

    if (component === USER_INPUT_ELEMENT_TYPES.RADIO) {
      return _convertElementsIntoSelect({
        title,
        subtitle,
        elements,
        translatedComponent: USER_INPUT_ELEMENT_TYPES.RADIO,
      })
    } else {
      let [element] = elements
      return {
        title,
        subtitle,
        autocompleteType: _getElementAutocompleteType({ subtitle, element }),
        ...element,
        id: element.id ?? element.name,
        options: _getPotentialElementOptions({ element }),
      }
    }
  })

  return {
    steps: stepsWithConvertedRadios,
    submitButton: _getSubmitButton(elements),
  }
}

function _getPotentialElementOptions({ element }) {
  if (element.options) {
    return element.options.map((option) => ({
      ...option,
      title: option.title || option.label,
    }))
  }
}

function _getSubmitButton(elements) {
  const lastElement = last(elements)
  return lastElement.component === USER_INPUT_ELEMENT_TYPES.BUTTON
    ? lastElement
    : null
}

function _convertElementsIntoSelect({
  title,
  subtitle,
  elements,
  translatedComponent,
}) {
  return {
    title: elements[0].title || title,
    subtitle: elements[0].subtitle || subtitle,
    translatedComponent,
    component: elements[0].multiple
      ? USER_INPUT_ELEMENT_TYPES.MULTISELECT
      : USER_INPUT_ELEMENT_TYPES.SELECT,
    name: elements[0].name,
    id: `sel_${elements[0].name}`,
    options: elements.map(
      ({ id, value, meta, label, title, subtitle, payload, accountType }) => ({
        id,
        value,
        meta,
        title: title || label,
        subtitle: title ? subtitle : undefined,
        accountType,
        payload,
      }),
    ),
  }
}

export function getNextStepIdx({
  currentFormData,
  requestType,
  product,
  steps,
  currentStepIdx,
}) {
  let stepIdx = currentStepIdx
  let findingNextStep = true

  while (findingNextStep) {
    stepIdx++
    const step = steps[stepIdx]

    if (
      !step ||
      !step.hidden ||
      _stepMeetsConditions({ currentFormData, requestType, product, step })
    ) {
      return stepIdx
    }
  }
}

export function hasCurrentStepData({ currentStep, formData, requestType }) {
  return !!(currentStep && get(formData, `${requestType}.${currentStep.name}`))
}

export function hasPotentialNextStep({
  formData,
  requestType,
  product,
  flow,
  currentFormFlowIdx,
}) {
  const nextStepIdx = getNextStepIdx({
    currentFormData: formData,
    requestType: requestType,
    product: product,
    steps: flow.steps,
    currentStepIdx: currentFormFlowIdx,
  })

  return (
    !!flow.steps[nextStepIdx] ||
    _futureStepWillBeShown({ flow, currentFormFlowIdx })
  )
}

function _stepMeetsConditions({ currentFormData, requestType, product, step }) {
  return (step.conditions || []).some((condition) => {
    const valueToCheck = get(
      currentFormData,
      `${requestType}.${condition.inputName}.value`,
    )

    return (
      _meetsInputCondition(valueToCheck)(condition) ||
      _meetsProductCondition({ productToCheck: product, condition })
    )
  })
}

function _meetsInputCondition(valueToCheck) {
  return (condition) =>
    condition.type === 'input' && valueToCheck === condition.equals
}

function _meetsProductCondition({ productToCheck, condition }) {
  return (
    condition.type === 'product' && condition.products.includes(productToCheck)
  )
}

function _getElementAutocompleteType({ subtitle, element }) {
  const codeTextPresent = (text) => text?.match(/code/i)

  return codeTextPresent(subtitle) ||
    codeTextPresent(element.name) ||
    codeTextPresent(element.placeholder)
    ? 'one-time-code'
    : undefined
}

function _futureStepWillBeShown({ flow, currentFormFlowIdx }) {
  const potentialFutureSteps = flow.steps.slice(currentFormFlowIdx + 1)
  const currentStep = flow.steps[currentFormFlowIdx]
  const currentStepIsSelect =
    currentStep?.component === 'select' && currentStep?.options?.length > 0

  return (
    currentStepIsSelect &&
    potentialFutureSteps.some(
      (potentialFutureStep) =>
        potentialFutureStep.hidden &&
        currentStep?.options?.some((option) =>
          potentialFutureStep.conditions.some(
            _meetsInputCondition(option.value),
          ),
        ),
    )
  )
}
