export const welcome = {
  protectYouButtonText: "Learn how you're protected",
  depositTitle: ({ customerName }) =>
    `Update your direct deposit to ${customerName}`,
  verifyTitle: ({ customerName }) =>
    `Connect your payroll account to ${customerName}`,
  hasAccessTitle: 'Only allows access to',
  noAccessTitle: "Doesn't allow access to",
  accessBulletDeposit: ({ customerName }) =>
    `Update your direct deposit to your ${customerName} account.`,
  accessBulletVerify: 'Income, paystubs, and timesheets.',
  accessBulletIdentify: 'Name, address, and contact info.',
  newDepositTitle: ({ customerName }) =>
    `${customerName} uses Atomic to update your Direct Deposit`,
  newVerifyIdentifyTitle: ({ customerName }) =>
    `${customerName} uses Atomic to connect your payroll account`,
  taxTitle: ({ customerName }) =>
    `${customerName} uses Atomic to retrieve your tax documents`,
  tax: {
    bullet: {
      secure: {
        title: 'Secure',
        description: 'We encrypt your login details.',
      },
      protect: {
        title: 'Protect',
        description: 'We work to keep your info safe.',
      },
    },
  },
  v2: {
    deposit: {
      title1: 'Move',
      title2: 'your',
      title3: 'money',
      subtitle: 'How this works',
      step1: 'Find who pays you',
      step1Description: 'Gig, Employer, SSI',
      step2: 'Enter your credentials',
      step3: 'We connect and update your payroll',
      button: `Let's do this`,
      securedByAtomic: 'Secured by Atomic',
    },
  },
}
