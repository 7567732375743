export const configurableConnectorSearch = {
  employerSearchPlaceholder: 'Employer name',
  findEmployerSearch: ({ connectorName }) => `Sign in to ${connectorName}`,
  findEmployerSubtitle: "First you'll need to find your employer",
  userProvidedOrgIdButton: ({ connectorName, fieldName }) =>
    `Try ${connectorName} ${fieldName}`, // "Try Workday Organization ID"
  requestCompanyButton: 'Request Company',
  noMatchTitle: ({ searchQuery }) =>
    `We couldn't find any employers named "${searchQuery}".`,
  noMatchHelpOrgId: ({ connectorName, fieldName }) =>
    `Try using your employers ${connectorName} ${fieldName} instead.`, //"Try using your employer's Workday Organization ID instead."
  noMatchHelpRequestCompany: 'Submit a request and we will add your employer.',
  requestCompanyTitle: ({ companyName }) =>
    `Sorry, we will need a moment to add "${companyName}" to our database.`,
  requestCompanySubtitle:
    'We suggest you try again later after we add your employer to our list.',
  findEmployerSearchResults: ({ searchQuery }) =>
    `Search results for "${searchQuery}"`,
}
