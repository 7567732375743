<template>
  <ModalContent>
    <CloseButton v-if="inSdk" class="close-button" @close="close" />
    <ModalBody> <NoInternetConnection /></ModalBody>
  </ModalContent>
</template>

<script>
import CloseButton from '@/components/Shared/CloseButton.vue'
import ModalContent from '@/components/Modal/ModalContent.vue'
import NoInternetConnection from '@/components/Connection/NoInternetConnection.vue'
import ModalBody from '@/components/Modal/ModalBody.vue'
import { appClose } from '@/util/app-event'
import { SDK_EVENT_TYPES, PAGES } from '@/util/constants'
import { mapState } from 'vuex'

export default {
  name: 'OfflineModal',
  components: {
    CloseButton,
    ModalBody,
    NoInternetConnection,
    ModalContent,
  },
  computed: {
    ...mapState('main', ['inSdk']),
    ...mapState('network', ['isOnline']),
  },
  created() {
    this.$analytics.track({
      event: `Viewed ${PAGES.NO_CONNECTION}`,
    })
  },
  methods: {
    close() {
      appClose({ store: this.$store, eventType: SDK_EVENT_TYPES.CLOSE })

      this.$analytics.track({
        event: 'Clicked Close',
        payload: { exitScreen: this.$route.path, isOnline: this.isOnline },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.close-button {
  padding: calc(env(safe-area-inset-top) + 24px) 24px
    unquote('max(24px, env(safe-area-inset-bottom))');
  @media screen and (max-height: 639px) {
    padding: var(--appBodyPadding) 24px 24px 24px;
  }
}
</style>
