import { SDK_EVENT_TYPES } from '@/util/constants'
import { broadcastEvent } from '@/util/broadcast'

export async function invoke({ type, payload }) {
  return new Promise((resolve) => {
    const message = {
      type,
      payload,
      id: Math.random().toString(36),
    }

    const handler = ({ detail: { name, payload: response } }) => {
      const id = response.id

      if (id !== message.id) return // not ours

      document.body.removeEventListener('sdk-native-event', handler)

      resolve({ name, payload: response.payload })
    }

    document.body.addEventListener('sdk-native-event', handler)

    broadcastEvent({
      event: SDK_EVENT_TYPES.AUTOMATION_HANDOFF,
      payload: message,
    })
  })
}
