// TODO: Add the other /task/* API calls to this service
export class TaskService {
  constructor({ makeRequest }) {
    this._makeRequest = makeRequest
  }

  async postUserEvent({ type }) {
    return this._makeRequest({
      method: 'POST',
      endpoint: '/task/user-event',
      data: {
        event: {
          type,
        },
      },
    })
  }
}
