export const FILTERED_PAYROLL_PROVIDERS = new Set([
  '614a2dcb06386500092df038', // ukg
  '60e5fbcbb627cd0008e7836b', // workday
  '63a2084b9c97540011ea0f51', // social security
  '6323784800292500096f2b49', // social security
  '6142614a3680f80008e5301e', // social security
  '5fc810d63279fe0009c493ea', // dfas mypay
  '5e38d9abd383b3be9f7f0485', // upwork
  '6102ec706ff9ef000822bddc', // sheetz
  '61002b2178471600081776bd', // staffmark
  '62d8aa2c7916400009761b1f', // intuit (employee)
  '62cddbdee879390009dfc834', // rover
  '62a2454826327800097e8a80', // bamboo hr
  '60ef364b16b9ce0008506094', // heartland payroll
])
