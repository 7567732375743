import { PRODUCTS } from '@/util/constants'

export const detail = {
  linkedDate: (date) => `Linked ${date}`,
  managedByCompany: {
    title: ({ companyName, managingCompanyName }) =>
      `Your ${companyName} subscription is managed by ${managingCompanyName}`,
    message: ({ managingCompanyName, product }) =>
      product === PRODUCTS.PRESENT
        ? `Sign in to ${managingCompanyName} to continue.`
        : `Sign in to your ${managingCompanyName} account to finish updating your payment method.`,
  },
  userInputRequired: {
    title: 'Finish verifying your account',
    message: ({ companyName, product }) =>
      product === PRODUCTS.PRESENT
        ? `${companyName} needs to verify your account to finish connecting.`
        : `${companyName} needs to verify your account to update your payment method.`,
  },
}
