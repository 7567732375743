import {
  AUTHENTICATION_EVENTS,
  authenticationService,
} from '@/machines/authentication'
import { haptics } from '@/util/client-side-automation'
import {
  PAGES,
  TASK_WORKFLOW_RESULT,
  TASK_WORKFLOW_VIEW,
  USER_MESSAGE_TYPES,
} from '@/util/constants'
import {
  completeProgressAnimation,
  fadeOutTaskCountdown,
  finishTaskWorkflowAnimation,
  pauseProgressAnimation,
} from '@/util/animation'
import { completeProgressStep } from '@/util/progress/progress-actions'
import { TaskWorkflowOrchestrator } from '@/services'

export function subscribeToTaskWorkflowOrchestratorEvents({
  analytics,
  store,
  taskWorkflowId,
}) {
  TaskWorkflowOrchestrator.subscribe({
    messageType: USER_MESSAGE_TYPES.AUTHENTICATION_SUCCESSFUL,
    taskWorkflowId,
    subscriptionHandler: _authenticationSuccessful({ store }),
  })

  TaskWorkflowOrchestrator.subscribe({
    messageType: USER_MESSAGE_TYPES.MANUAL_MODE,
    taskWorkflowId,
    subscriptionHandler: _manualMode({ store }),
  })

  TaskWorkflowOrchestrator.subscribe({
    messageType: USER_MESSAGE_TYPES.SMART_AUTH_DATA_REQUEST,
    taskWorkflowId,
    subscriptionHandler: _smartAuthDataRequest({ store }),
  })

  TaskWorkflowOrchestrator.subscribe({
    messageType: USER_MESSAGE_TYPES.TASK_FAILED,
    taskWorkflowId,
    subscriptionHandler: _taskFailed({
      analytics,
      store,
    }),
  })

  TaskWorkflowOrchestrator.subscribe({
    messageType: USER_MESSAGE_TYPES.TASK_WORKFLOW_FINISHED,
    taskWorkflowId,
    subscriptionHandler: _taskWorkflowFinished({ store }),
  })
}

function _authenticationSuccessful({ store }) {
  return () => {
    fadeOutTaskCountdown()
    store.dispatch('task/clearAllTaskTimers')
    store.dispatch('task/clearTaskCountdown')
    if (!store.getters['progress/isAuthenticated']) {
      completeProgressStep()
    }
  }
}

function _determineFinishStatus({ store }) {
  // Check if authentication progress step is complete
  if (store.getters['progress/isAuthenticated']) {
    return TASK_WORKFLOW_RESULT.ONLY_AUTH_COMPLETED
  } else {
    return TASK_WORKFLOW_RESULT.FULFILLMENT_AVAILABLE
  }
}

function _manualMode({ analytics, store }) {
  return async () => {
    const status = _determineFinishStatus({ store })

    await completeProgressAnimation()

    store.dispatch('taskWorkflow/updateTaskWorkflowState', {
      view: TASK_WORKFLOW_VIEW.FINISHED,
      result: status,
    })
    store.dispatch('task/clearAllTaskTimers')
    store.dispatch('task/clearTaskCountdown')

    finishTaskWorkflowAnimation()

    analytics.track({
      event: `Viewed ${PAGES.AUTHENTICATION_PAUSED}`,
    })
  }
}

function _smartAuthDataRequest({ store }) {
  return (message) => {
    store.dispatch('task/pauseTaskCountdown')
    store.dispatch('task/clearAllTaskTimers')
    store.dispatch('messaging/resetCustomMessaging')
    store.dispatch(
      'smartAuth/updateRequestData',
      message.data.event.data.eventData,
    )
    pauseProgressAnimation()

    if (message.data.event.data.eventData.exceptionMessage) {
      store.dispatch(
        'smartAuth/updateExceptionMessage',
        message.data.event.data.eventData.exceptionMessage,
      )
      authenticationService.send(AUTHENTICATION_EVENTS.SMART_AUTH_FINISHED)
    } else {
      authenticationService.send(AUTHENTICATION_EVENTS.SWITCH_METHOD)
    }

    store.dispatch('taskWorkflow/updateTaskWorkflowState', {
      view: TASK_WORKFLOW_VIEW.LOGIN,
    })
  }
}

function _taskFailed({ analytics, store }) {
  return async (message) => {
    store.dispatch('task/clearAllTaskTimers')
    store.dispatch('task/clearTaskCountdown')
    store.dispatch('messaging/resetCustomMessaging')
    store.dispatch('progress/clearHookAnimationTimer')

    if (message.data.event.data.eventData) {
      store.dispatch('messaging/updateCustomMessaging', {
        customFailureMessage:
          message.data.event.data.eventData.customFailureMessage,
        customFailureTitle:
          message.data.event.data.eventData.customFailureTitle,
      })
      store.dispatch(
        'task/updateTaskFailReason',
        message.data.event.data.eventData.failReason,
      )
    }

    await completeProgressAnimation()

    store.dispatch('taskWorkflow/updateTaskWorkflowState', {
      view: TASK_WORKFLOW_VIEW.FINISHED,
      result: TASK_WORKFLOW_RESULT.FAILED,
    })

    finishTaskWorkflowAnimation()

    analytics.track({
      event: `Viewed ${PAGES.TASK_FAILED}`,
      payload: {
        failReason: message?.data?.event?.data?.eventData?.failReason,
      },
    })
  }
}

function _taskWorkflowFinished({ store }) {
  return async (message) => {
    const { failReason, state } = message.data.event.data.eventData.productState

    if (state === TASK_WORKFLOW_RESULT.FAILED) {
      store.dispatch('taskWorkflow/updateTaskWorkflowFailReason', failReason)
      await completeProgressAnimation()
      store.dispatch('taskWorkflow/updateTaskWorkflowState', {
        view: TASK_WORKFLOW_VIEW.FINISHED,
        result: TASK_WORKFLOW_RESULT.FAILED,
      })
      finishTaskWorkflowAnimation()
    } else if (state === TASK_WORKFLOW_RESULT.COMPLETED) {
      await store.dispatch('messaging/resetCustomMessaging')

      store.dispatch('messaging/updateCustomMessaging', {
        customFailureMessage:
          message.data.event.data.eventData.customFailureMessage,
        customFailureTitle:
          message.data.event.data.eventData.customFailureTitle,
      })

      await store.dispatch('modal/closeModal')

      store.dispatch('taskWorkflow/updateTaskWorkflowState', {
        view: TASK_WORKFLOW_VIEW.FINISHED,
        result: TASK_WORKFLOW_RESULT.COMPLETED,
      })

      haptics.notifySuccess()
      finishTaskWorkflowAnimation()
    }
  }
}
