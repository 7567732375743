export const manualDepositInstructions = {
  accountInformationHiddenAriaLabel:
    'Información de la cuenta; oculta para su protección.',
  accountInformationVisibleAriaLabel:
    'Información de la cuenta; visible y puede ser copiada a su portapapeles.',
  accountInformationVisibleAriaLive:
    'La información de la cuenta ahora es visible y puede ser copiada.',
  accountNumber: 'Número de cuenta',
  copyWebsiteLink: 'Copiar enlace del sitio web:',
  websiteLink: 'Número de cuenta',
  copied: 'Copiado',
  copyAccountNumber: 'Copiar número de cuenta.',
  copyRoutingNumber: 'Copiar número de ruta.',
  disabledCopyButtonAriaLabel:
    'Proceda al botón "mostrar número de cuenta" para copiar la información de la cuenta.',
  routingNumber: 'Número de ruta',
  showAccountInformation: 'Mostrar número de cuenta',
  updateYourDeposit: ({ name }) => `Actualice su depósito directo de ${name}`,
}
