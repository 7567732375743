export const uplinkInterstitial = {
  title: ({ name }) => `Inicia sesión directamente en ${name}`,
  depositDescription:
    'Una vez que hayas iniciado sesión, serás redirigido aquí para terminar de configurar su depósito directo.',
  verifyDescription:
    'Una vez que hayas iniciado sesión, serás redirigido aquí para terminar de conectar su cuenta.',
  continue: ({ name }) => `Continuar en ${name}`,
  scanQRCode: 'Escanea este código QR con la cámara de su smartphone',
  cameraScanQR({ companyName }) {
    return `Escanea el código QR con la aplicación de la cámara del dispositivo para iniciar sesión en ${companyName}`
  },
  openTrueAuthDeposit:
    'Abra la herramienta TrueAuth para cargar su depósito directo',
  openTrueAuthTax:
    'Abra la herramienta TrueAuth para cargar su declaración de impuestos',
  openTrueAuthVerify({ companyName }) {
    return `Abra TrueAuth para conectarse directamente a su cuenta ${companyName}.`
  },
  qrCodeGenerationDeposit({ companyName }) {
    return `Esto iniciará TrueAuth, una herramienta que le ayuda a cargar su  depósito directo directamente de ${companyName}.`
  },
  qrCodeGenerationTax({ companyName }) {
    return `Esto iniciará TrueAuth, una herramienta que le ayuda a cargar su declaración de impuestos directamente de ${companyName}.`
  },
  qrCodeGenerationFallback({ companyName }) {
    return `Esto lanzará TrueAuth, una herramienta que se conecta directamente a su cuenta de ${companyName}.`
  },
  trueAuthHelpsDeposit({ companyName }) {
    return `TrueAuth nos ayuda a cargar su depósito directo directamente de ${companyName}.`
  },
  trueAuthHelpsTax({ companyName }) {
    return `TrueAuth nos ayuda a cargar su declaración de impuestos directamente de ${companyName}.`
  },
  trueAuthHelpsVerify({ companyName }) {
    return `TrueAuth nos ayuda a conectar sus datos directamente desde ${companyName}.`
  },
  notWorking: '¿No funciona?',
  openNow: 'Abierto ahora',
  copyLink() {
    return IS_ANDROID
      ? 'Copiar enlace y pegar en Chrome'
      : 'Copiar enlace y pegar en Safari'
  },
  linkCopied: 'Enlace copiado!',
  afterCopy: `Después de copiar la URL, péguela en el navegador web de su dispositivo.`,
  openTrueAuth: 'Abierto TrueAuth',
  signInWithTrueAuth: 'Iniciar sesión con TrueAuth',
  signInWithQRCode: 'Iniciar sesión con código QR',
  enterYourInfoManually: '"Ingresa su información manualmente."',
  usingAndroid:
    'Si está utilizando un dispositivo Android, es posible que deba ',
  enableInstantApps: 'habilitar las instant apps',
  turnOnGooglePlayInstant: 'Cómo activar Google Play Instant',
  step1: 'Abre la aplicación Google Play.',
  step2: 'En la parte superior derecha, toca el ícono de perfil.',
  step3: 'Toca Configuración > General > Google Play Instant.',
  step4: 'Activar o desactivar los enlaces web de actualización.',
  tip: `Consejo: Si Google Play Instant no está en su configuración, es posible que no esté disponible para su tipo de dispositivo.`,
}
