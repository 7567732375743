import { startsWith } from 'lodash-es'

export function initializeEnvironmentFromConfig({ config, store }) {
  store.dispatch(
    'environment/updateEnvironment',
    _isTestToken(config) ? 'sandbox' : 'production',
  )
  store.dispatch('environment/updateApiEndpoint', _getApiEndpoint(config))
  store.dispatch('environment/updatePciEndpoint', _getPciEndpoint(config))
  store.dispatch('environment/updateMixpanelId', _getMixpanelId(config))
  store.dispatch(
    'environment/updateAdpCoauthEndpoint',
    _getAdpCoauthEndpoint(config),
  )
}

function _isTestToken(config) {
  const token = config.token || config.publicToken
  return startsWith(token, 'test-')
}

function _getApiEndpoint(config) {
  if (config.testApiBaseUrl) {
    return config.testApiBaseUrl
  }

  return _isTestToken(config)
    ? import.meta.env.VITE_APP_SANDBOX_ATOMIC_API
    : import.meta.env.VITE_APP_PRODUCTION_ATOMIC_API
}

function _getAdpCoauthEndpoint(config) {
  return _isTestToken(config)
    ? import.meta.env.VITE_APP_SANDBOX_ADP_EDDE_SDK_BASE_URL
    : import.meta.env.VITE_APP_ADP_EDDE_SDK_BASE_URL
}

function _getPciEndpoint(config) {
  if (config.testPciBaseUrl) {
    return config.testPciBaseUrl
  }

  return _isTestToken(config)
    ? import.meta.env.VITE_APP_SANDBOX_PCI_API
    : import.meta.env.VITE_APP_PRODUCTION_PCI_API
}

function _getMixpanelId(config) {
  return _isTestToken(config)
    ? import.meta.env.VITE_APP_SANDBOX_MIXPANEL
    : import.meta.env.VITE_APP_PRODUCTION_MIXPANEL
}

export function isLocalDev() {
  return import.meta.env.MODE === 'development-local'
}
