export const SURVEYS = {
  EXAMPLE: 'example',
  GIG_PAYOUT: 'gig-payout',
  SOCIAL_SECURITY: 'social-security',
  PAYCHECK_ALLOCATION: 'paycheck-allocation',
  WELCOME_EXIT: 'welcome-exit',
  SEARCH_EXIT: 'search-exit',
  AUTHENTICATION_EXIT: 'authentication-exit',
  DEPOSIT_DISTRIBUTION_EXIT: 'deposit-distribution-exit',
  ERROR_EXIT: 'error-exit',
  TRUE_AUTH_APP_SKIP: 'true-auth-app-skip',
}
