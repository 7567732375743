export const sanitizeFullDOMScript = `
  document
    .getElementsByTagName('html')[0]
    .outerHTML.replace(
      /<script\b[^<]*(?:(?!<\\/script>)<[^<]*)*<\\/script>|<style\\b[^<]*(?:(?!<\\/style>)<[^<]*)*<\\/svg>|<svg\\b[^<]*(?:(?!<\\/svg>)<[^<]*)*<\\/svg>/gi
    )
`

export const attachDOMPullListenerScript = `
  if (!window.createdDOMEvents) {
    window.createdDOMEvents = true
            
    const events = ['DOMSubtreeModified', 'DOMNodeInserted', 'DOMNodeRemoved', 'DOMNodeRemovedFromDocument', 'DOMNodeInsertedIntoDocument', 'DOMAttrModified', 'DOMCharacterDataModified']
    
    events.forEach(event =>
      document.addEventListener(event, () =>
        window.webkit.messageHandlers["dom-changed"].postMessage("dom-changed")
      )
    )
  }
`

export const getAuthIsCompleteEvaluatorScript = ({
  stringifiedFunctionToEvaluate,
  functionParameters,
}) => {
  return `
    const helperFunctions = ${functionParameters.helpers ?? '{}'}
    Object.keys(helperFunctions).forEach(key => helperFunctions[key] = eval(helperFunctions[key]))
    
    const fn = ${stringifiedFunctionToEvaluate}
    
    return fn({
      ${_spreadStringifiedObject(functionParameters)}
      helpers: helperFunctions,
      url: window.location.href,
      document,
      window,
    })
      .then((result) => !!result)
  `
}

export const getEvaluateOnDeviceScript = (
  { stringifiedFunctionToEvaluate, functionParameters = {} },
  isForDeferredAction = false,
) => {
  return `
    const helperFunctions = ${functionParameters.helpers ?? '{}'}
    Object.keys(helperFunctions).forEach(key => helperFunctions[key] = eval(helperFunctions[key]))
    
    const fn = ${stringifiedFunctionToEvaluate}
    
    return fn({
      ${_spreadStringifiedObject(functionParameters)}
      helpers: helperFunctions,
      url: window.location.href,
      document,
      window,
    })
      .then(response => ({ response: response || undefined, isForDeferredAction: ${isForDeferredAction} }))
      .catch(err => ({ err: { message: err.message } }))
  `
}

export const getNetworkOnDeviceScript = ({ axiosParameters }) => {
  _handleGraphQLNewLineCharacters({ axiosParameters })

  return `
    try {
      const xsrf = document.cookie.split('; ').find(row => row.startsWith('XSRF-TOKEN='))?.split('=')[1]
  
      const res = await fetch('${axiosParameters.url}', {
        method: '${axiosParameters.method || 'get'}',
        credentials: ${
          axiosParameters.withCredentials ? `'include'` : undefined
        },
        headers: {
          ...(xsrf ? { 'x-xsrf-token': xsrf } : {}),
          ${_spreadStringifiedObject(axiosParameters.headers || {})}
        },
        body: ${
          !axiosParameters.data
            ? undefined
            : `\`${_getRequestData(axiosParameters.data)}\``
        }
      })
      
      const contentType = res.headers.get('content-type')
  
      let data
  
      try {
        data = contentType?.includes('application/json')
          ? await res.json()
          : await res.text()
      } catch (err) {
        // Log for Open Replay
        console.error(err)
        data = {
          message: 'Unable to parse response body.',
          err: {
            message: err.message
          }
        }
      }
  
      return {
        response: {
          data,
          status: res.status,
          headers: Object.fromEntries(res.headers)
        }
      }
    } catch (err) {
      // Log for Open Replay
      console.error(err)
      return {
        err: {
          message: err.message
        }
      }
    }
  `
}

/**
 * Since we started sending the JavaScript to execute commands from Transact instead of keeping the JavaScript in the SDK,
 * the SDK's now rip out the new line characters because we interpolate in the JavaScript at the native level.
 * So GraphQL requests will fail that have new line's in them, like in Gusto's integration. This appends another
 * \ in front of the \n so that the new line characters stick around in the SDK
 */
function _handleGraphQLNewLineCharacters({ axiosParameters }) {
  if (typeof axiosParameters?.data?.query === 'string') {
    axiosParameters.data.query = axiosParameters.data.query.replace(
      /\n/gi,
      '\\n',
    )
  }
}

function _spreadStringifiedObject(object) {
  const stringifiedObject = JSON.stringify(object)
  const spreadedStringifiedObject = stringifiedObject.substring(
    1,
    stringifiedObject.length - 1,
  )

  return spreadedStringifiedObject ? `${spreadedStringifiedObject},` : ''
}

function _getRequestData(data) {
  if (typeof data === 'string') return data

  return JSON.stringify(data)
}
