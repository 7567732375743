export const findEmployerHelpModal = {
  title: 'Cómo encontrar a su empleador',
  step1: {
    title: 'Revisa sus recibos de pago',
    description:
      'Normalmente puedes encontrar esta información en documentos relacionados con el trabajo, como un recibo de pago.',
  },
  step2: {
    title: 'Pregunta a su empleador o compañero de trabajo',
    description:
      'Suempleador, gerente o un compañero de trabajo probablemente sepan quién es su proveedor de nómina.',
  },
}
