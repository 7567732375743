<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    fill="none"
    v-bind="iconSize"
  >
    <title v-if="title">{{ title }}</title>
    <path
      d="M14.3334 5L7.00002 12.3333L3.66669 9"
      v-bind="iconStroke"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
import IconBase from '@/components/Icons/Atomic/IconBase.vue'

export default {
  name: 'IconCheck',
  extends: IconBase,
}
</script>
