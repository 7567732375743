import {
  AUTOMATION_DEVICES,
  DISTRIBUTION_TYPES,
  TASK_WORKFLOW_TYPES,
  PRODUCTS,
  TASK_WORKFLOW_VIEW,
  ERROR_CODES,
  AUTHENTICATION_METHODS,
  SESSION_CONTEXTS,
  TAX_DOCUMENTS,
  USER_INPUT_REQUEST_TYPES,
  ROUTES,
  SDK_EVENT_TYPES,
  TASK_INTERACTION_EVENTS,
  DATA_REQUESTS,
  USER_COMPANY_SELECTION_STATUS,
} from '@/util/constants'
import { isEmpty, get, cloneDeep } from 'lodash-es'
import { handleAPIError } from '@/util/requests'
import sleep from 'await-sleep'
import { store } from '@/store'
import {
  getBranding,
  getBrandingUrl,
  getLogoBackgroundColor,
} from '@/util/branding'
import { makeRequest } from '@/util/requests'
import { subscribeRealtimeTaskWorkflowChannels } from '@/util/realtime-helpers/task-workflow'
import { setOpenReplayMetadataDeprecated } from '@/util/client-side-automation/script-injections'
import { completeProgressAnimation } from '@/util/animation'
import OpenReplay from '@/plugins/openreplay'
import Analytics from '@/plugins/analytics'
import {
  featureUniqueForGo2Bank,
  featureUniqueForAlbert,
} from '@/util/customization'
import router from '@/router'

import {
  canUseCoAuthConnector,
  isAfterHoursForConnector,
  isFirstSmartAuthDepositUser,
  isMaintenanceMode,
  isManualConnector,
  isManualFallback,
  isManualFallbackAccountRouting,
  isManualFallbackPrefilled,
  isManualFallbackPrefilledSuccess,
  isRoutingNumberBlocked,
  isScopePayLink,
  routeQueryDefinesInitialView,
  userNeedsToAddCard,
  useCustomManualInstructions,
  uplinkIsAvailable,
} from '@/util/lib/task-workflow-view-helpers'
import { publishUserSessionEvent } from '@/util/realtime-helpers/user'
import { setOpenReplayMetadata } from '@/util/client-side-automation/page-scripts'
import { emitSdkEvent } from '@/util/sdk'
import {
  isCard,
  isAch,
  hasValidToken,
  missingFullAccountNumber,
} from '@/util/pay-link'

export function convertToTaskWorkflowStructure({ product, store }) {
  const tasks = store?.state?.taskWorkflow?.tasks || []
  if (!tasks.find((task) => task.product === product)) {
    store.dispatch('taskWorkflow/updateTaskWorkflowTasks', [
      ...tasks,
      { product },
    ])
  }
}

export function taskGenerator({ tasks, store }) {
  const cleanTasks = _convertOperationToProduct({ tasks })

  const firstProduct = cleanTasks[0].product
  switch (firstProduct) {
    case TASK_WORKFLOW_TYPES.TAX:
      return _setupTaxTasks({ store, cleanTasks })
    default:
      return cleanTasks
  }
}

export async function initTaskWorkflow({ store, userInput }) {
  // The task counter keeps track of how many tasks the user has run during their
  // Transact session. We use this field to specify searchable metadata on the Open Replay session
  await store.dispatch('task/incrementTaskCounter')

  const data = buildTaskWorkflowPayload({
    store,
    userInput,
  })

  Analytics.get().track({
    event: 'User Input Size',
    payload: {
      size: !data.userInput ? 0 : JSON.stringify(data.userInput).length,
    },
    internal: true,
  })

  const response = await makeRequest({
    method: 'POST',
    endpoint: '/task-workflow',
    data,
    timeout: 30000,
  })

  const {
    data: { taskWorkflow, task, company },
  } = response.data

  // Update the taskWorkflow ID to start subscribing to realtime events
  await store.dispatch('taskWorkflow/updateTaskWorkflowId', taskWorkflow._id)

  // Update the taskId of the first task in the taskWorkFlow
  await store.dispatch('task/updateCurrentTaskId', { taskId: task._id })

  _emitTaskInitializedEvents(taskWorkflow._id, task._id)

  // Launch into Backoffice for helpful debugging
  if (import.meta.env.VITE_APP_BACKOFFICE_DEBUGGING === 'true') {
    setTimeout(
      () =>
        window.open(
          `http://localhost:8080/task/detail/${task._id}`,
          '_blank',
          'location=yes,height=800,width=1200,scrollbars=yes,status=yes',
        ),
      1000,
    )
  }

  // Update the current company data with what comes back from the task response
  // This way in case a new company was created, we'll update the data
  await store.dispatch('company/updateSelectedCompany', company)

  // Clear the searched company name if needed since we've sent the company request,
  // and we don't want to create another request if they end up failing credentials and
  // need to try again
  await store.dispatch('search/clearSearchedCompanyName')

  // Subscribe to realtime events for this task workflow
  await subscribeRealtimeTaskWorkflowChannels({
    taskWorkflowId: taskWorkflow._id,
    userId: store.state.user.userData._id,
    subscribeToRPC: true,
  })

  // Notify user device automation that task was created
  if (store.getters['userDeviceAutomation/usingUserAutomatedDevice']) {
    publishUserSessionEvent({
      store,
      eventName: 'task-workflow-created',
      eventData: { taskId: task._id, taskWorkflowId: taskWorkflow._id },
    })

    if (await store.getters['userDeviceAutomation/useQuantum']) {
      await _handleQuantumPagePostTaskCreation({ store, task })
    }
    // @DEPRECATED
    else {
      setOpenReplayMetadataDeprecated({
        store,
        metadata: {
          [store.getters['task/openReplayTaskMetadataKey']]: task._id,
        },
      })
    }
  }

  // Update Transact's Open Replay instance with metadata
  OpenReplay.setMetadata(
    store.getters['task/openReplayTaskMetadataKey'],
    task._id,
  )
}

function _emitTaskInitializedEvents(taskWorkflowId, taskId) {
  emitSdkEvent(SDK_EVENT_TYPES.INTERACTION, {
    name: TASK_INTERACTION_EVENTS.INITIALIZED_TASK,
    value: {
      taskWorkflowId: taskWorkflowId,
      taskId: taskId,
    },
  })
}

async function _handleQuantumPagePostTaskCreation({ store, task }) {
  const latestPage = store.getters['userDeviceAutomation/latestPage']

  latestPage?.evaluate(setOpenReplayMetadata, [
    {
      metadata: {
        [store.getters['task/openReplayTaskMetadataKey']]: task._id,
      },
    },
  ])
}

/**
 * Creates the payload data for creating a taskWorkflow
 */
export function buildTaskWorkflowPayload({
  authenticationMethodOverride,
  automationDeviceOverride,
  companyIdBackup,
  connectorIdOverride,
  store,
  userInput,
}) {
  const company = store.getters['company/selectedCompany']

  const companyId = company._id || store.state.company._id || companyIdBackup
  const connectorId =
    connectorIdOverride ||
    company?.connector?._id ||
    store.state.company.activeConnector._id
  const automationDevice =
    automationDeviceOverride || store.getters['taskWorkflow/automationDevice']

  let structuredPayload = {
    company: companyId,
    connector: connectorId,
    type: store.state.taskWorkflow.taskWorkflowType,
    tasks: _formatTasks({ store }),
    useSimulator: store.state.main.useSimulator,
    metadata: store.state.main.metadata,
    automationDevice,
    authenticationMethod:
      authenticationMethodOverride ||
      store.getters['taskWorkflow/authenticationMethod'],
    userInput: {},
    fingerprint: window.fingerprint,
    authenticationFlowId: 'auth',
    sessionContext: store.state.main.sessionContext ?? SESSION_CONTEXTS.DEFAULT,
    uplinkPageHandle: store.state.userDeviceAutomation.latestPageHandle,
    scope: store.state.main.scope,
    processingMode: store.getters['main/processingMode'],
  }

  structuredPayload.tasks = structuredPayload.tasks.map((task) => ({
    ...task,
    userPlatform: store.state.main.platform,
    distribution: undefined, // This gets copied over into the deposit settings below
    userOpenReplayKey: store.getters['main/useTransactOpenReplay']
      ? store.getters['task/openReplayTaskMetadataKey']
      : undefined,
    uplinkOpenReplayKey:
      automationDevice === AUTOMATION_DEVICES.USER
        ? store.getters['task/openReplayTaskMetadataKey']
        : undefined,
    // eslint-disable-next-line no-undef
    debugTemporal: DEBUG_TEMPORAL,
    settings: {
      [task.product]: { safeMode: store.state.main.safeMode || false },
    },
  }))

  if (!isEmpty(store.state.withholding.withholding)) {
    const withholdTask = structuredPayload.tasks.find(
      (task) => task.product === PRODUCTS.WITHHOLD,
    )
    withholdTask.settings.withhold = store.state.withholding.withholding
  }

  if (store.getters['task/isInLoginRecovery']) {
    structuredPayload.authenticationFlowId = store.state.task.recoveryFlow.id
  }

  if (store.getters['authenticator/usingAuthenticator']) {
    structuredPayload.authenticator = store.state.authenticator.authenticatorId
  }

  if (store.state.main.linkedAccountId) {
    structuredPayload.linkedAccount = store.state.main.linkedAccountId
  } else if (store.state.task.taskIdToConvert) {
    structuredPayload.taskIdToConvert = store.state.task.taskIdToConvert
  } else if (
    store.getters['taskWorkflow/authenticationMethod'] ===
    AUTHENTICATION_METHODS.UPLINK
  ) {
    if (
      store.state.taskWorkflow.taskWorkflowType ===
      TASK_WORKFLOW_TYPES.UPLINK_DEMO
    ) {
      structuredPayload.userInput = {
        [USER_INPUT_REQUEST_TYPES.AUTH]: {
          username: store.state.demo.uplinkDemoUsername,
        },
      }
    } else {
      structuredPayload.userInput = store.state.formFlow.templatedFormData
    }
  } else {
    structuredPayload.userInput = userInput
  }

  if (store.getters['taskWorkflow/productsIncludeDeposit']) {
    const depositTask = structuredPayload.tasks.find(
      (task) => task.product === PRODUCTS.DEPOSIT,
    )

    // For unique cases like Coinbase's workflow, where user's may click on a company in the payroll carousel,
    // or on a Gig provider company that doesn't support fractional deposits, we need to reset the distribution type
    // to a full deposit, otherwise we'd attempt to do a fractional deposit on a connector that does not support it
    if (!store.getters['company/connectorSupportsFractionalDeposits']) {
      depositTask.settings.deposit.distributionType = DISTRIBUTION_TYPES.TOTAL
      depositTask.settings.deposit.distributionAmount = undefined
    } else {
      depositTask.settings.deposit.distributionType =
        store.state.distribution.distributionType
      depositTask.settings.deposit.distributionAmount =
        store.state.distribution.distributionAmount
    }

    depositTask.settings.deposit.distributionAction =
      store.state.distribution.distributionAction
    depositTask.settings.deposit.distributionEnforced =
      store.state.distribution.distributionEnforced
    depositTask.settings.destinationUserAccountId =
      store.state.user?.account?._id
  }

  if (store.getters['taskWorkflow/productsIncludeSwitch']) {
    const switchTask = structuredPayload.tasks.find(
      (task) => task.product === PRODUCTS.SWITCH,
    )

    switchTask.settings.switch.paymentMethod = store.state.payLink.paymentMethod
  }

  if (store.getters['taskWorkflow/productsIncludeUserAction']) {
    const userActionTask = structuredPayload.tasks.find(
      (task) => task.product === PRODUCTS.ACTION,
    )
    userActionTask.settings[PRODUCTS.ACTION].flow =
      store.getters['taskWorkflow/userAction'].flow
  }

  if (store.state.company.searchedCompanyName) {
    structuredPayload.companyRequest = {
      name: store.state.company.searchedCompanyName,
      connectorId: store.state.company.connector._id,
    }
  }

  return structuredPayload
}

export function checkIfProductExistsInTaskWorkflowProducts(product) {
  const taskWorkflowProducts = store?.getters['taskWorkflow/products']
  return taskWorkflowProducts.includes(product)
}

export function productQueryString() {
  const taskWorkflowProducts = store.getters['taskWorkflow/products']
  let queryString = ''
  taskWorkflowProducts.forEach((product, idx, array) => {
    const addAmpersand = !Object.is(array.length - 1, idx) ? '&' : ''
    queryString = `${queryString}products=${product}${addAmpersand}`
  })

  return queryString
}

export function generateTaskWorkflowBrandingLogos({ store }) {
  store.dispatch('taskWorkflow/updateTaskWorkflowBranding', getBranding())
}

export function updateForegroundLogo(entity) {
  store.dispatch('taskWorkflow/updateTaskWorkflowBrandingForeground', {
    logoBackgroundColor: getLogoBackgroundColor(entity),
    url: getBrandingUrl(entity),
  })
}

export function showOnlyBackgroundLogo(entity) {
  store.dispatch('taskWorkflow/updateTaskWorkflowBranding', {
    background: {
      logoBackgroundColor: getLogoBackgroundColor(entity),
      url: getBrandingUrl(entity),
    },
  })
}

function _formatTasks({ store }) {
  // Copy tasks into new array since we'll be modifying them in line
  const tasks = cloneDeep(store.state.taskWorkflow.tasks || [])

  return tasks.map((task) => {
    if (
      store.getters['taskWorkflow/authenticationMethod'] ===
      AUTHENTICATION_METHODS.SMART_AUTH
    ) {
      task.tags = ['smart-auth']
    }

    return task
  })
}

function _setupTaxTasks({ store, cleanTasks }) {
  store.dispatch('taskWorkflow/updateTaskWorkflowType', TASK_WORKFLOW_TYPES.TAX)
  return [
    {
      product: PRODUCTS.VERIFY,
      forms: cleanTasks[0].forms || ['w2s'],
    },
  ]
}

export function getInitialTaskWorkflowView({ route, store }) {
  const conditions = [
    routeQueryDefinesInitialView,
    isMaintenanceMode,
    isAfterHoursForConnector,
    isRoutingNumberBlocked,
    canUseCoAuthConnector,
    userNeedsToAddCard,
    isScopePayLink,
    uplinkIsAvailable,
    isFirstSmartAuthDepositUser,
    isManualFallback,
    isManualFallbackPrefilled,
    isManualFallbackPrefilledSuccess,
    isManualFallbackAccountRouting,
    useCustomManualInstructions,
    isManualConnector,
  ]

  for (let condition of conditions) {
    const conditionView = condition({ route, store })

    if (conditionView) {
      return conditionView
    }
  }

  return TASK_WORKFLOW_VIEW.LOGIN
}

export async function getInitialTaskWorkflowViewForTaskBypass({ store }) {
  if (store.getters['main/showConfirmDistributionBeforeTaskBypass']) {
    await store.dispatch('taskWorkflow/updateTaskWorkflowState', {
      view: TASK_WORKFLOW_VIEW.CONFIRM_DISTRIBUTION,
    })
  } else {
    await handleTaskCredentialsBypass({ store })
  }
}

export async function startTaskFormBypass({ store }) {
  // If the task workflow includes a user action, we need to show the loader
  if (store.getters['taskWorkflow/productsIncludeUserAction']) {
    await store.dispatch('taskWorkflow/updateTaskWorkflowState', {
      view: TASK_WORKFLOW_VIEW.USER_ACTION.LOADER,
    })
  }
  // If the task workflow is an uplink workflow in concurrent processing mode,
  // we need to show the pay link home page
  else if (
    store.getters['taskWorkflow/authenticationMethod'] ===
      AUTHENTICATION_METHODS.UPLINK &&
    store.getters['main/isConcurrentProcessingMode']
  ) {
    store.dispatch('payLink/setOptimisticStatus', {
      selection: store.getters['payLink/activeSelection'],
      status: USER_COMPANY_SELECTION_STATUS.IN_PROGRESS,
    })
    router.push({
      name: ROUTES.PAY_LINK_HOME,
    })
  }
  // Otherwise, we need to show the in progress view
  else {
    await store.dispatch('taskWorkflow/updateTaskWorkflowState', {
      view: TASK_WORKFLOW_VIEW.IN_PROGRESS,
    })
    await store.dispatch('task/startTaskCountdown')
  }
}

export async function handleTaskCredentialsBypass({ store, demo }) {
  await store.dispatch('task/startTaskCountdown')
  await startTaskFormBypass({ store })

  const userInput =
    demo && store.state.demo.uplinkDemoUsername
      ? { username: store.state.demo.uplinkDemoUsername }
      : undefined

  try {
    await initTaskWorkflow({ store, ...(userInput ?? {}) })
    await _requestCardDataIfNeeded({ store })
    await _requestAccountDataIfNeeded({ store })
  } catch (err) {
    if (
      get(err, 'response.data.code') === ERROR_CODES.GLOBAL_MAINTENANCE_MODE
    ) {
      await _handleTaskMaintenance()
    } else {
      await handleAPIError({
        errorCode: ERROR_CODES.GLOBAL_40000,
        data: err,
      })
    }
  }
}

async function _requestCardDataIfNeeded({ store }) {
  const paymentMethod = store.state.payLink.paymentMethod

  if (
    store.getters['taskWorkflow/productsIncludeSwitch'] &&
    isCard(paymentMethod) &&
    !hasValidToken(paymentMethod)
  ) {
    Analytics.get().track({ event: 'Task Requires Card Information' })

    emitSdkEvent(SDK_EVENT_TYPES.DATA_REQUEST, {
      fields: [DATA_REQUESTS.CARD],
      taskWorkflowId: store.state.taskWorkflow.taskWorkflowId,
      taskId: store.state.task.taskId,
      userId: store.state.user.userData._id,
      identifier: store.state.user.userData.identifier,
      properties: {
        lastFour: paymentMethod.lastFour,
        title: paymentMethod.title,
      },
    })
  }
}
async function _requestAccountDataIfNeeded({ store }) {
  const paymentMethod = store.state.payLink.paymentMethod

  if (
    store.getters['taskWorkflow/productsIncludeSwitch'] &&
    isAch(paymentMethod) &&
    missingFullAccountNumber(paymentMethod)
  ) {
    Analytics.get().track({ event: 'Task Requires Account Information' })

    emitSdkEvent(SDK_EVENT_TYPES.DATA_REQUEST, {
      fields: [DATA_REQUESTS.ACCOUNT],
      taskWorkflowId: store.state.taskWorkflow.taskWorkflowId,
      taskId: store.state.task.taskId,
      userId: store.state.user.userData._id,
      identifier: store.state.user.userData.identifier,
      properties: {
        accountNumberLastFour: paymentMethod.accountNumberLastFour,
        title: paymentMethod.title,
      },
    })
  }
}

async function _handleTaskMaintenance() {
  await sleep(2000)
  await completeProgressAnimation()
  await store.dispatch('taskWorkflow/resetTaskWorkflowState', {
    initialView: TASK_WORKFLOW_VIEW.INTERSTITIAL.MAINTENANCE_MODE,
  })
}

function _convertOperationToProduct({ tasks }) {
  return tasks.map((task) => {
    return {
      ...task,
      product: task.product || task.operation,
      operation: undefined,
    }
  })
}

export function forceUsersToSeeSSAManualInstructions({ customer }) {
  return (
    featureUniqueForGo2Bank({ customer }) ||
    featureUniqueForAlbert({ customer })
  )
}

export function taxTaskIncludesW2s({ tasks }) {
  return tasks[0]?.forms.includes(TAX_DOCUMENTS.W2S)
}

export function taxTaskIncludes1040s({ tasks }) {
  return tasks[0]?.forms.includes(TAX_DOCUMENTS['1040S'])
}
