import { SDK_EVENT_TYPES } from '@/util/constants'
import { store } from '@/store'

import { broadcastEvent } from '@/util/broadcast'
import { eventHandler } from '@/util/client-side-automation/sdk-automation-events'
import { initAblyChannelEvents } from '@/util/client-side-automation/ably-automation-events'

export async function triggerOld({ type, payload: rawPayload }) {
  const channel = await initAblyChannelEvents({ store })

  return new Promise((resolve, reject) => {
    const message = {
      type,
      ...rawPayload,
      messageUUID: Math.random().toString(36),
    }

    const handler = ({ detail: { name, payload } }) => {
      const id = payload.messageUUID

      if (id !== message.messageUUID) return // not ours

      document.body.removeEventListener('sdk-native-event', handler)
      eventHandler({ name, store, channel, payload })
        .then(() => resolve({ name, payload }))
        .catch(reject)
    }

    document.body.addEventListener('sdk-native-event', handler)

    broadcastEvent({
      event: SDK_EVENT_TYPES.AUTOMATION_HANDOFF,
      payload: message,
    })
  })
}
