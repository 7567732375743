<template>
  <svg
    width="39"
    height="39"
    viewBox="0 0 39 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="security-icon-svg"
    aria-hidden="true"
  >
    <path
      d="M15.8334 18.1667V14.8333C15.8334 13.7283 16.2724 12.6684 17.0538 11.887C17.8352 11.1056 18.895 10.6667 20 10.6667C21.1051 10.6667 22.1649 11.1056 22.9463 11.887C23.7277 12.6684 24.1667 13.7283 24.1667 14.8333V18.1667"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M25.8333 18.1667H14.1667C13.2462 18.1667 12.5 18.9128 12.5 19.8333V25.6667C12.5 26.5871 13.2462 27.3333 14.1667 27.3333H25.8333C26.7538 27.3333 27.5 26.5871 27.5 25.6667V19.8333C27.5 18.9128 26.7538 18.1667 25.8333 18.1667Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconTLS',
}
</script>
