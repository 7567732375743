import { invoke } from '@/util/native/invoke'

import { SHOW } from '@/util/native/events'

export async function show(options) {
  const message = await invoke({
    type: SHOW,
    payload: options,
  })

  return message.payload
}
