/* eslint-disable no-empty-pattern */
import { makeRequest } from '@/util/requests'
import { isEmpty } from 'lodash-es'
import {
  COMPANY_SEARCH_PERFORMANCE_ACTIONS,
  SEARCH_TAGS,
  PRODUCTS,
  DISTRIBUTION_TYPES,
  COMPANY_TAGS,
} from '@/util/constants'
import { checkIfProductExistsInTaskWorkflowProducts } from '@/util/task-workflow'
import { ApiError } from '@/util/custom-errors'
import {
  isMyPay,
  featureUniqueForMolecularVIP,
  featureUniqueForSpruce,
  isNotAPerfectPayLinkCompanyYet,
} from '@/util/customization'

const state = {
  companySearchQuery: '',
  connectorSearchQuery: '',
  payrollSearchQuery: '',
  franchiseSearchQuery: '',
  payLinkSearchQuery: '',
  activeTag: SEARCH_TAGS.POPULAR,
  focusedTagIndex: 0,
  showSearchNoMatch: false,
  searchedCompanyName: undefined,
  enforcedSearchParams: {},
  searchPerformanceEvents: [],
  employerCompanies: [],
  connectorCompanies: [],
  payrollCompanies: [],
  franchiseCompanies: [],
  payLinkCompanies: [],
  companiesRequestedAt: 0,
  payrollRequestedAt: 0,
  skipStaggerAnimation: false,
  isLoading: false,
  searchRule: {
    id: undefined,
    disableSearch: false,
    product: undefined,
    suggestedCompanies: [],
    excludedCompanies: [],
  },
}

const getters = {
  searchParamsEnforced: (state) => !isEmpty(state.enforcedSearchParams),
  enforcingUnemployment: (state, getters) =>
    getters.searchParamsEnforced &&
    state.enforcedSearchParams.tags?.length === 1 &&
    state.enforcedSearchParams.tags[0] === COMPANY_TAGS.UNEMPLOYMENT,
  shouldShowSearchTags: (state, getters) => {
    return (
      !state.enforcedSearchParams?.tags && !getters.shouldShowCustomizedSearch
    )
  },
  shouldShowSearchInput: (state, getters, rootState, rootGetters) => {
    if (
      rootGetters['taskWorkflow/taskWorkflowTypeIsTax'] &&
      rootGetters['taskWorkflow/taskWorkflowTypeIsTaxWith1040s']
    ) {
      return false
    }
    return (
      !state.searchRule ||
      !state.searchRule?.suggestedCompanies?.length ||
      !state.searchRule.disableSearch
    )
  },
  shouldShowCustomizedSearch: (state, getters, rootState, rootGetters) => {
    return (
      state.searchRule.id &&
      state.searchRule.suggestedCompanies.length > 0 &&
      (state.searchRule.product === rootGetters['taskWorkflow/firstProduct'] ||
        (state.searchRule.product === 'tax' &&
          rootGetters['taskWorkflow/taskWorkflowTypeIsTax']))
    )
  },
}

const actions = {
  async updateEnforcedSearchParams({ commit }, params) {
    commit('setEnforcedSearchParams', params)
  },
  async searchEmployerCompanies(state, params = {}) {
    const searchResultsMutation = params.connectorId
      ? 'setConnectorCompanies'
      : 'setEmployerCompanies'

    await _searchCompanies(state, params, searchResultsMutation)
  },
  async searchFranchiseCompanies(state, params = {}) {
    await _searchCompanies(state, params, 'setFranchiseCompanies')
  },
  async searchPayrollCompanies(
    { commit, dispatch },
    {
      query = '',
      tags = [COMPANY_TAGS.PAYROLL_PROVIDER],
      useStrictSearch = false,
    } = {},
  ) {
    const requestedAt = Date.now()
    const companies = await dispatch('queryCompanies', {
      payload: {
        query,
        tags,
        useStrictSearch,
      },
      headers: { 'x-mock-response-name': 'Payroll Only' },
    })

    if (requestedAt >= state.payrollRequestedAt) {
      commit('setPayrollCompanies', { companies, requestedAt })
    }
  },
  async searchPayLinkCompanies(state, params = {}) {
    await _searchCompanies(state, params, 'setPayLinkCompanies')
  },
  async queryCompanies({ rootState, rootGetters }, { payload, headers }) {
    try {
      const response = await makeRequest({
        method: 'POST',
        endpoint: '/company/search',
        headers,
        data: {
          ...payload,
          ...(rootState.experiment.ldFlags.smartSwitchSearch
            ? {}
            : { excludedConnectorIds: ['654e88d1975fa0f39c124c36'] }),
          scopes: [rootState.main.scope],
          products: rootGetters['taskWorkflow/products'],
          distributionType: rootState.distribution.distributionEnforced
            ? rootState.distribution.distributionType
            : DISTRIBUTION_TYPES.TOTAL,
          canReferToTrueAuthApp: !!rootState.experiment.ldFlags.trueAuthAppClip,
        },
      })

      return _filteredCompanySearchResults({
        taskWorkflowTypeIsTax:
          rootGetters['taskWorkflow/taskWorkflowTypeIsTax'],
        customer: rootState.user.userData.customer,
        companies: response.data.data,
      })
    } catch (error) {
      // We return 401's from the API for a variety of reasons as defined in the following codes.
      // If the code is not one of these, we want to know about it
      const intentional401codes = [40107, 40108, 40109]

      if (intentional401codes.includes(error?.response?.data?.code)) {
        return []
      }

      console.error(new ApiError(error.message, error.response))

      return []
    }
  },
  updateSkipStaggerAnimation({ commit }, payload) {
    commit('setSkipStaggerAnimation', payload)
  },
  updateShowSearchNoMatch({ commit }, payload) {
    commit('setShowSearchNoMatch', payload)
  },
  updateSearchedCompanyName({ commit }, name) {
    commit('setSearchedCompanyName', name)
  },
  updateCompanySearchQuery({ commit }, query) {
    commit('setCompanySearchQuery', query)
  },
  updateConnectorSearchQuery({ commit }, query) {
    commit('setConnectorSearchQuery', query)
  },
  updateConnectorCompanies({ commit }, { companies, requestedAt }) {
    commit('setConnectorCompanies', { companies, requestedAt })
  },
  updatePayrollSearchQuery({ commit }, query) {
    commit('setPayrollSearchQuery', query)
  },
  updateFranchiseSearchQuery({ commit }, query) {
    commit('setFranchiseSearchQuery', query)
  },
  updatePayLinkSearchQuery({ commit }, query) {
    commit('setPayLinkSearchQuery', query)
  },
  updateActiveTag({ commit }, tag) {
    commit('setActiveTag', tag)
  },
  updateFocusedTagIndex({ commit }, tagIndex) {
    commit('setFocusedTagIndex', tagIndex)
  },
  updateSearchRuleId({ commit }, ruleId) {
    commit('setSearchRuleId', ruleId)
  },
  updateSearchRule({ commit }, rule) {
    commit('setSearchRule', rule)
  },
  clearPayLinkSearchQuery({ commit }) {
    commit('setPayLinkSearchQuery', undefined)
  },
  clearSearchedCompanyName({ commit }) {
    commit('setSearchedCompanyName', undefined)
  },
  clearFranchiseSearchQuery({ commit }) {
    commit('setFranchiseSearchQuery', '')
  },
  addSearchPerformanceEvent(
    { commit, rootGetters, state },
    { action, query, searchResults, selectedCompany },
  ) {
    let event = {
      action,
      query,
      products: rootGetters['taskWorkflow/products'],
      createdAt: new Date().toISOString(),
    }

    switch (action) {
      case COMPANY_SEARCH_PERFORMANCE_ACTIONS.SELECT:
      case COMPANY_SEARCH_PERFORMANCE_ACTIONS.PAYROLL_SELECT: {
        event.selectedResult = {
          _id: selectedCompany._id,
          name: selectedCompany.name,
          subtext: selectedCompany.subtext,
        }
        event.selectedResultIndex =
          searchResults &&
          searchResults.findIndex(({ _id }) => _id === selectedCompany._id)

        break
      }
      case COMPANY_SEARCH_PERFORMANCE_ACTIONS.SEARCH:
      case COMPANY_SEARCH_PERFORMANCE_ACTIONS.PAYROLL_SEARCH:
      default: {
        event.searchResults = searchResults.map(({ _id, name, subtext }) => ({
          _id,
          name,
          subtext,
        }))
      }
    }

    commit('setSearchPerformanceEvents', [
      ...state.searchPerformanceEvents,
      event,
    ])
  },
  async trackSearchPerformanceEvents({ commit, state }) {
    if (state.searchPerformanceEvents.length > 0) {
      await makeRequest({
        method: 'POST',
        endpoint: '/company/search-performance',
        data: state.searchPerformanceEvents,
      })

      commit('setSearchPerformanceEvents', [])
    }
  },
}

const mutations = {
  setShowSearchNoMatch: (state, value) => {
    state.showSearchNoMatch = value
  },
  setSearchedCompanyName: (state, name) => {
    state.searchedCompanyName = name
  },
  setActiveTag: (state, tag) => {
    state.activeTag = tag
  },
  setFocusedTagIndex: (state, tagIndex) => {
    state.focusedTagIndex = tagIndex
  },
  setSkipStaggerAnimation: (state, value) => {
    state.skipStaggerAnimation = value
  },
  setEnforcedSearchParams: (state, params) => {
    state.enforcedSearchParams = { ...state.enforcedSearchParams, ...params }
  },
  setSearchPerformanceEvents: (state, searchPerformanceEvents) => {
    state.searchPerformanceEvents = searchPerformanceEvents
  },
  setCompanySearchQuery: (state, query) => {
    state.companySearchQuery = query
  },
  setConnectorSearchQuery: (state, query) => {
    state.connectorSearchQuery = query
  },
  setPayrollSearchQuery: (state, query) => {
    state.payrollSearchQuery = query
  },
  setFranchiseSearchQuery: (state, query) => {
    state.franchiseSearchQuery = query
  },
  setPayLinkSearchQuery: (state, query) => {
    state.payLinkSearchQuery = query
  },
  setEmployerCompanies: (state, { companies, requestedAt }) => {
    state.employerCompanies = companies
    state.companiesRequestedAt = requestedAt || state.companiesRequestedAt
  },
  setIsLoading: (state, value) => {
    state.isLoading = value
  },
  setConnectorCompanies: (state, { companies, requestedAt }) => {
    state.connectorCompanies = companies
    state.companiesRequestedAt = requestedAt || state.companiesRequestedAt
  },
  setPayrollCompanies: (state, { companies, requestedAt }) => {
    state.payrollCompanies = companies
    state.payrollRequestedAt = requestedAt || state.payrollRequestedAt
  },
  setFranchiseCompanies: (state, { companies, requestedAt }) => {
    state.franchiseCompanies = companies
    state.companiesRequestedAt = requestedAt || state.companiesRequestedAt
  },
  setPayLinkCompanies: (state, { companies }) => {
    state.payLinkCompanies = companies
  },
  setSearchRuleId: (state, ruleId) => {
    state.searchRule.id = ruleId
  },
  setSearchRule: (state, rule) => {
    state.searchRule.id = rule._id
    state.searchRule.product = rule.product
    state.searchRule.disableSearch = rule.disableSearch
    state.searchRule.suggestedCompanies = rule.suggestedCompanies
    state.searchRule.excludedCompanies = rule.excludedCompanies
  },
}

async function _searchCompanies(
  { commit, dispatch, state, rootState },
  { query = '', tags, excludedTags, connectorId, franchiseParent } = {},
  searchResultsMutation,
) {
  const requestedAt = Date.now()
  // The searchable tags will be what is passed in on demand, or what the customer has enforced
  tags = tags || state.enforcedSearchParams.tags
  excludedTags = excludedTags || state.enforcedSearchParams.excludedTags

  // Skip the network request when there is no query, and no other enforced scenarios are present
  if (
    !query &&
    !tags &&
    !excludedTags &&
    !rootState.distribution.distributionEnforced &&
    !checkIfProductExistsInTaskWorkflowProducts(PRODUCTS.WITHHOLD) &&
    !franchiseParent &&
    !connectorId
  ) {
    commit(searchResultsMutation, {
      companies: [],
      requestedAt: state.companiesRequestedAt,
    })
  } else {
    const companies = await dispatch('queryCompanies', {
      payload: {
        query,
        tags,
        excludedTags,
        connectorId,
        gigProvidersAreSearchableWithDistributionType:
          !!rootState.distribution.canUpdateDistribution,
        franchiseParent,
        platform: rootState.main.platform,
      },
      headers: { 'x-mock-response-name': 'All Companies' }, // cypress?
    })

    if (requestedAt >= state.companiesRequestedAt) {
      commit(searchResultsMutation, { companies, requestedAt })
    }
  }
}

function _filteredCompanySearchResults({
  taskWorkflowTypeIsTax,
  customer,
  companies,
}) {
  return companies
    .filter((company) => !(taskWorkflowTypeIsTax && isMyPay({ company })))
    .filter(
      (company) =>
        !(
          (featureUniqueForMolecularVIP({ customer }) ||
            featureUniqueForSpruce({ customer })) &&
          isNotAPerfectPayLinkCompanyYet({ company })
        ),
    )
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
