export const socialSecurityInterstitial = {
  title: 'What type of Social Security do you receive?',
  socialSecurityBenefits: 'Social Security Benefits',
  socialSecurityHelperText: 'Retirement, disability, survivor',
  supplementSecurityIncome: 'Supplemental Security Income',
  supplementSecurityIncomeHelperText:
    'Limited income, disability, or over age 65',
  both: 'Both',
  bothHelpertext: 'Social Security Benefits & SSI',
  ssaEmployer: `I'm an SSA employee`,
  noDoNot: `I don't receive Social Security`,
}
