export const walmartManual = {
  title: 'Configura el depósito directo de Walmart',
  step1: {
    title: 'Visita este enlace:',
    link: 'https://hello.one.app/setup-dd',
  },
  step2: {
    title: 'Inicia sesión en su cuenta de Walmart Associate.',
  },
  step3: {
    title: 'Seleccione One como depósito directo.',
  },
  showAccountNumbers: 'Mostrar números de cuenta',
}
