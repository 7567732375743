import { USER_DEVICE_AUTOMATION_EVENTS } from '@/util/constants'
import { getEvaluateOnDeviceScript } from '@/util/client-side-automation/sdk-scripts'
import { triggerOld } from '@/util/native/trigger-old'

export async function sendInternalDeferredEvaluateAction({ action, store }) {
  if (await store.getters['userDeviceAutomation/useQuantum']) {
    return
  }

  action.script = getEvaluateOnDeviceScript(action, true)

  const result = await triggerOld({
    type: USER_DEVICE_AUTOMATION_EVENTS.EVALUATE_ON_DEVICE,
    payload: action,
  })

  return result.payload?.response?.response || result.payload?.response
}
