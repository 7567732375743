import {
  openPopoverAnimation,
  closePopoverAnimation,
} from '@/util/animations/popover'
import {
  ROUTES,
  SURVEYS,
  TASK_WORKFLOW_VIEW,
  HANDOFF_EVENTS,
  TASK_WORKFLOW_RESULT,
} from '@/util/constants'
import { appClose, shouldUseHandoff } from '@/util/app-event'

export async function triggerSurvey({ surveyId, store, route }) {
  const survey = surveyId || getAvailableExitSurvey({ store, route })
  await store.dispatch('survey/updateActiveSurvey', survey)
  openPopoverAnimation()
}

export async function getSurvey({ surveyId }) {
  return (await import(`./survey-${surveyId}.js`)).default
}

export async function closeSurvey({ store, surveyId }) {
  await closePopoverAnimation()
  store.commit('survey/addViewedSurvey', surveyId)
  if (store.state.exit.data) {
    appClose({ store, ...store.state.exit.data })
  }
  store.dispatch('survey/clearActiveSurvey')
  store.dispatch('exit/clearExitData')
}

export function getAvailableExitSurvey({ store, route }) {
  if (_showWelcomeExitSurvey({ store, route })) {
    return SURVEYS.WELCOME_EXIT
  }
  if (_showSearchExitSurvey({ store, route })) {
    return SURVEYS.SEARCH_EXIT
  }
  if (_showAuthenticationExitSurvey({ store })) {
    return SURVEYS.AUTHENTICATION_EXIT
  }
  if (_showDepositDistributionExitSurvey({ store })) {
    return SURVEYS.DEPOSIT_DISTRIBUTION_EXIT
  }
  if (_showErrorExitSurvey({ store })) {
    return SURVEYS.ERROR_EXIT
  }
  return false
}

function _showWelcomeExitSurvey({ store, route }) {
  return (
    route === ROUTES.WELCOME &&
    store.state.experiment.ldFlags.transactWelcomeExitSurvey &&
    !store.getters['survey/hasViewedSurvey'](SURVEYS.WELCOME_EXIT)
  )
}

function _showSearchExitSurvey({ store, route }) {
  return (
    (route === ROUTES.SEARCH_COMPANY || route === ROUTES.SEARCH_PAYROLL) &&
    store.state.experiment.ldFlags.transactSearchExitSurvey &&
    !store.getters['survey/hasViewedSurvey'](SURVEYS.SEARCH_EXIT)
  )
}

function _showAuthenticationExitSurvey({ store }) {
  const taskWorkflowState = store.state.taskWorkflow.taskWorkflowState
  return (
    store.state.experiment.ldFlags.transactAuthenticationExitSurvey &&
    (taskWorkflowState.view === TASK_WORKFLOW_VIEW.LOGIN ||
      taskWorkflowState.view === TASK_WORKFLOW_VIEW.INTERRUPT) &&
    !shouldUseHandoff({
      store,
      handoffEvent: HANDOFF_EVENTS.EXIT_PROMPT,
    }) &&
    !store.getters['survey/hasViewedSurvey'](SURVEYS.AUTHENTICATION_EXIT)
  )
}

function _showDepositDistributionExitSurvey({ store }) {
  return (
    store.state.experiment.ldFlags.transactDepositDistributionExitSurvey &&
    store.state.taskWorkflow.taskWorkflowState.view ===
      TASK_WORKFLOW_VIEW.CONFIRM_DISTRIBUTION &&
    !shouldUseHandoff({
      store,
      handoffEvent: HANDOFF_EVENTS.EXIT_PROMPT,
    }) &&
    !store.getters['survey/hasViewedSurvey'](SURVEYS.DEPOSIT_DISTRIBUTION_EXIT)
  )
}

function _showErrorExitSurvey({ store }) {
  const taskWorkflowState = store.state.taskWorkflow.taskWorkflowState
  return (
    store.state.experiment.ldFlags.transactErrorExitSurvey &&
    taskWorkflowState.view === TASK_WORKFLOW_VIEW.FINISHED &&
    taskWorkflowState.result === TASK_WORKFLOW_RESULT.FAILED &&
    !store.getters['survey/hasViewedSurvey'](SURVEYS.ERROR_EXIT)
  )
}
