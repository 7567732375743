import { mapActions, mapState } from 'vuex'
import * as $CONSTANTS from '@/util/constants'

export default {
  data() {
    return {
      viewsToIgnore: [
        $CONSTANTS.MODAL_VIEW.OFFLINE,
        $CONSTANTS.MODAL_VIEW.ERROR,
      ],
    }
  },
  mounted() {
    window.addEventListener('keydown', async (event) => {
      if (event.key === 'Escape' && this.canCloseModal) {
        await this.closeModal()
      }
    })
  },
  methods: {
    ...mapActions('modal', ['closeModal']),
  },
  computed: {
    ...mapState('modal', ['modalState']),
    canCloseModal() {
      return (
        !this.viewsToIgnore.includes(this.modalState.view) &&
        !!this.modalState.view
      )
    },
  },
}
