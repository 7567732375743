<template>
  <div class="keypad-wrap mt-2" :class="{ dark: useDarkMode }">
    <div class="keypad-row">
      <button
        class="number content-only"
        data-test-id="1"
        @click="handleClick(1)"
      >
        1
      </button>
      <button
        class="number content-only"
        data-test-id="2"
        @click="handleClick(2)"
      >
        2
      </button>
      <button
        class="number content-only"
        data-test-id="3"
        @click="handleClick(3)"
      >
        3
      </button>
    </div>
    <div class="keypad-row">
      <button
        class="number content-only"
        data-test-id="4"
        @click="handleClick(4)"
      >
        4
      </button>
      <button
        class="number content-only"
        data-test-id="5"
        @click="handleClick(5)"
      >
        5
      </button>
      <button
        class="number content-only"
        data-test-id="6"
        @click="handleClick(6)"
      >
        6
      </button>
    </div>
    <div class="keypad-row">
      <button
        class="number content-only"
        data-test-id="6"
        @click="handleClick(7)"
      >
        7
      </button>
      <button
        class="number content-only"
        data-test-id="7"
        @click="handleClick(8)"
      >
        8
      </button>
      <button
        class="number content-only"
        data-test-id="8"
        @click="handleClick(9)"
      >
        9
      </button>
    </div>
    <div class="keypad-row">
      <span class="number"></span>
      <button
        class="number content-only"
        data-test-id="0"
        @click="handleClick(0)"
      >
        0
      </button>
      <button
        class="number content-only"
        @click="handleClick('delete')"
        data-test-id="keypad-delete"
      >
        <img :src="iconDelete" alt="Delete" />
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Keypad',
  emits: ['handleClick'],
  computed: {
    ...mapGetters('theme', ['useDarkMode', 'getIcon']),
    iconDelete() {
      return this.getIcon('keypadDelete')
    },
  },
  methods: {
    handleClick(value) {
      this.$emit('handleClick', {
        clickedValue: value,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.keypad-wrap {
  background: var(--white);
  display: flex;
  flex-direction: column;
  width: 100%;
  &.dark {
    background: var(--gray-950);
  }

  .keypad-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    flex: 1;
    height: auto;
    max-height: 64px;
    min-height: 64px;

    .number {
      align-items: center;
      border-radius: var(--defaultBorderRadius);
      cursor: pointer;
      display: flex;
      font-size: 3.2rem;
      justify-content: center;
      touch-action: manipulation; //Prevent zoom on double tap only on the numbers
      user-select: none;
      color: var(--gray-900);
      &:active {
        background: var(--gray-100);
      }
      .dark & {
        color: var(--darkModeTextHigh);
        &:active {
          background-color: var(--gray-900);
        }
      }
    }

    @media (min-width: 851px) {
      max-height: 56px;
      min-height: 56px;

      .number {
        font-size: 2.4rem;
      }
    }

    @media (max-height: 739px) {
      max-height: 56px;
      min-height: 56px;

      .number {
        font-size: 2.4rem;
      }
    }

    @media (max-height: 667px) {
      max-height: 48px;
      min-height: 48px;
    }
  }
}
</style>
