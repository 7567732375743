import {
  ROUTES,
  TASK_WORKFLOW_RESULT,
  TASK_WORKFLOW_VIEW,
  CONNECTOR_SLUGS,
} from '@/util/constants'
import {
  AUTHENTICATION_STATES,
  AUTHENTICATION_EVENTS,
} from '@/machines/authentication'

export function copyText({ text }) {
  let shadowElement = document.createElement('textarea')
  document.body.appendChild(shadowElement)
  shadowElement.value = text
  shadowElement.select()
  shadowElement.setSelectionRange(0, 999999) //For mobile devices
  navigator.clipboard.writeText(text)
  document.body.removeChild(shadowElement)
}
export function getGlobalCSSValue(name) {
  return getComputedStyle(document.documentElement).getPropertyValue(name)
}

export function convertGlobalCSSPixelValueToNumber(name) {
  return Number(getGlobalCSSValue(name).replace('px', ''))
}

export function backButtonTextBasedOnRoute({ routeName, store, authService }) {
  if (
    routeName === ROUTES.SEARCH_PAYROLL ||
    routeName === ROUTES.SEARCH_FRANCHISE
  ) {
    return store.getters['i18n/phrases'].shared.backToSearch
  } else if (routeName === ROUTES.TASK) {
    return _taskRouteBackText({ store, authService })
  } else {
    return store.getters['i18n/phrases'].shared.back
  }
}

export function headingDataBasedOnRoute({ routeName, store }) {
  if ([ROUTES.SEARCH_COMPANY, ROUTES.PAY_LINK_SEARCH].includes(routeName)) {
    if (store.getters['taskWorkflow/productsIncludeSwitch']) {
      return {
        center: false,
        text: store.getters['i18n/phrases'].searchCompany.switch.title,
      }
    } else if (store.getters['taskWorkflow/productsIncludePresent']) {
      return {
        center: false,
        text: store.getters['i18n/phrases'].searchCompany.present.title,
      }
    } else if (
      store.getters['taskWorkflow/taskWorkflowTypeIsTax'] &&
      !store.getters['taskWorkflow/taskWorkflowTypeIsTaxWithW2s']
    ) {
      return {
        center: false,
        text: store.getters['i18n/phrases'].searchCompany.titleTax,
      }
    } else {
      return {
        center: false,
        text: store.getters['i18n/phrases'].searchCompany.title,
      }
    }
  } else if (routeName === ROUTES.SEARCH_PAYROLL) {
    return {
      center: false,
      text: store.getters['i18n/phrases'].shared.searchPayrollProviders,
    }
  }
  return null
}

export function shouldUseCompanyName({ connectorSlug }) {
  return (
    connectorSlug === CONNECTOR_SLUGS.SMART_AUTH ||
    connectorSlug?.includes(CONNECTOR_SLUGS.API_KEY)
  )
}

function _taskRouteBackText({ store, authService }) {
  const taskWorkflowView = store.state.taskWorkflow.taskWorkflowState.view
  const taskWorkflowResult = store.state.taskWorkflow.taskWorkflowState.result

  if (
    taskWorkflowView === TASK_WORKFLOW_VIEW.MANUAL ||
    taskWorkflowView === TASK_WORKFLOW_VIEW.INTERSTITIAL.MANUAL_FALLBACK
  ) {
    return store.getters['i18n/phrases'].shared.backToSearch
  } else if (
    taskWorkflowView === TASK_WORKFLOW_VIEW.MANUAL_FALLBACK_PREFILLED ||
    taskWorkflowView === TASK_WORKFLOW_VIEW.MANUAL_FALLBACK_ACCOUNT_ROUTING ||
    taskWorkflowView === TASK_WORKFLOW_VIEW.MANUAL_FALLBACK_PREFILLED_SUCCESS
  ) {
    return store.getters['i18n/phrases'].shared.back
  } else if (taskWorkflowView === TASK_WORKFLOW_VIEW.LOGIN) {
    return _loginRouteBackText({ store, authService })
  } else if (
    taskWorkflowView === TASK_WORKFLOW_VIEW.CONFIRM_DISTRIBUTION &&
    (store.state.taskWorkflow.taskWorkflowNavigation.history[0].view ===
      TASK_WORKFLOW_VIEW.INTERSTITIAL.MANUAL_FALLBACK ||
      store.state.taskWorkflow.taskWorkflowNavigation.history[0].view ===
        TASK_WORKFLOW_VIEW.MANUAL_FALLBACK_PREFILLED)
  ) {
    return store.getters['i18n/phrases'].shared.back
  } else if (taskWorkflowView === TASK_WORKFLOW_VIEW.CONFIRM_DISTRIBUTION) {
    return store.getters['i18n/phrases'].shared.backToLogin
  } else if (taskWorkflowView === TASK_WORKFLOW_VIEW.MANUAL_FALLBACK_SUMMARY) {
    return store.getters['i18n/phrases'].shared.back
  } else if (
    taskWorkflowView === TASK_WORKFLOW_VIEW.FINISHED &&
    taskWorkflowResult === TASK_WORKFLOW_RESULT.FAILED
  ) {
    return store.getters['i18n/phrases'].shared.backToLogin
  } else if (
    taskWorkflowView === TASK_WORKFLOW_VIEW.INTERSTITIAL.PAY_LINK_PRE_LOGIN ||
    taskWorkflowView === TASK_WORKFLOW_VIEW.INTERSTITIAL.ADD_CARD
  ) {
    return store.getters['i18n/phrases'].shared.backToSelections
  } else {
    return undefined
  }
}

function _loginRouteBackText({ store, authService }) {
  const { state } = authService
  const authenticationState = state.value.value
  const eventType = state.value.event.type
  const eventHistoryType = state.value.history?.event?.type

  if (
    authenticationState ===
    AUTHENTICATION_STATES.SEARCHING_CONFIGURABLE_CONNECTOR_TYPEAHEAD
  ) {
    return store.getters['i18n/phrases'].shared.backToSearch
  } else if (
    authenticationState === AUTHENTICATION_STATES.LOGGING_IN &&
    eventHistoryType === AUTHENTICATION_EVENTS.SELECTED_CONNECTOR
  ) {
    return store.getters['i18n/phrases'].shared.backToSearch
  } else if (
    // TODO: Deal with back text with smart AI
    authenticationState === AUTHENTICATION_STATES.LOGGING_IN &&
    eventType === AUTHENTICATION_EVENTS.STEP &&
    state.value.context.currentStepIdx > 0
  ) {
    return store.getters['i18n/phrases'].shared.back
  } else {
    return store.getters['i18n/phrases'].shared.backToSearch
  }
}

export function getLastNCharacters(string, n = 4) {
  return string.substring(string.length - n)
}

export function maskNumber(number, { unmaskedDigits = 4 } = {}) {
  return number ? `••${getLastNCharacters(number, unmaskedDigits)}` : ''
}

export function screenReaderAccessibleNumber(
  number,
  { unmaskedDigits = 4 } = {},
) {
  return getLastNCharacters(number, unmaskedDigits).split('').join(' ')
}

export async function convertImgToBase64(url) {
  const toDataUrl = async function (url) {
    //Convert to base64
    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest()
      xhr.onload = function () {
        var reader = new FileReader()
        reader.onloadend = function () {
          resolve(reader.result)
        }
        reader.readAsDataURL(xhr.response)
      }
      xhr.onerror = () => {
        reject({
          status: this.status,
          statusText: xhr.statusText,
        })
      }
      xhr.open('GET', url)
      xhr.responseType = 'blob'
      xhr.send()
    })
  }

  return await toDataUrl(url)
}

export function mergeCustomerAndSdkFeatures({ customerFeatures, sdkFeatures }) {
  const features = { ...customerFeatures }

  if (sdkFeatures?.manualDepositFlow) {
    features.manualDepositFlow.enabled = sdkFeatures.manualDepositFlow
  }

  return features
}
