import { store } from '@/store'
import { MODAL_VIEW } from '@/util/constants'

export function demoOverride() {
  if (store.state.demo.customer?.name) {
    store.dispatch('user/updateCustomerName', store.state.demo.customer.name)
  }
  if (store.state.demo.customer?.branding?.logo) {
    store.dispatch(
      'user/updateCustomerLogo',
      store.state.demo.customer?.branding.logo,
    )
  }
  if (store.state.demo.uplink) {
    window.SUPPORTS_NATIVE_AUTHENTICATION = true
  }
}

export function demoUplink({ store }) {
  store.dispatch('modal/openModal', { view: MODAL_VIEW.UPLINK_DEMO })
}
