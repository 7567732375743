import { emitSdkEvent } from '@/util/sdk'
import { SDK_EVENT_TYPES } from '@/util/constants'
import Ably from '@/plugins/ably'
import OpenReplay from '@/plugins/openreplay'

export function cleanupApplication({ store }) {
  OpenReplay.stop()

  // Only close Ably connections and cleanup app memory if we're not in a paylink scope because
  // we want to keep the connections open for any backgrounded swith or present tasks
  if (!store.getters['main/isScopePayLink']) {
    Ably.close()

    // After 10 secondish, emit the cleanup event so the SDK can clean up any remaining state
    setTimeout(() => {
      emitSdkEvent(SDK_EVENT_TYPES.AUTOMATION_HANDOFF, {
        type: 'cleanup-application',
      })
    }, 10000)
  }
}
