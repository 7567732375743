<template>
  <ModalActionBar />
  <ModalContent>
    <ModalBody>
      <ModalContentWrap class="legal-wrap">
        <div class="fixed-modal-title">
          <ModalLabel :level="3">{{ termsAndConditionsHeading }}</ModalLabel>
          <ModalClose />
        </div>
        <ScrollableWrap data-test-id="legal-scroll">
          <ModalDescription element="div">
            <TermsConditions data-test-id="terms-title" />
            <PrivacyPolicy data-test-id="privacy-policy-title" />
            <div class="btn-wrap">
              <BaseButton
                btn-style="hollow"
                data-test-id="legal-back"
                :text="phrases.shared.close"
                @handleClick="closeModal"
              />
            </div>
          </ModalDescription>
        </ScrollableWrap>
      </ModalContentWrap>
    </ModalBody>
  </ModalContent>
</template>

<script>
import ScrollableWrap from '@/components/Layout/ScrollableWrap.vue'
import BaseButton from '@/components/Form/BaseButton.vue'
import TermsConditions from '@/components/Legal/TermsConditions.vue'
import PrivacyPolicy from '@/components/Legal/PrivacyPolicy.vue'
import { PAGES } from '@/util/constants'
import { mapActions } from 'vuex'
import ModalDescription from '@/components/Modal/ModalDescription.vue'
import ModalContentWrap from '@/components/Modal/ModalContentWrap.vue'
import ModalActionBar from '@/components/Modal/ModalActionBar.vue'
import ModalLabel from '@/components/Modal/ModalLabel.vue'
import ModalContent from '@/components/Modal/ModalContent.vue'
import ModalBody from '@/components/Modal/ModalBody.vue'
import ModalClose from '@/components/Modal/ModalClose.vue'

export default {
  name: 'LegalModal',
  components: {
    ModalBody,
    ModalContentWrap,
    ModalDescription,
    ModalActionBar,
    ModalContent,
    ModalLabel,
    ModalClose,
    ScrollableWrap,
    TermsConditions,
    PrivacyPolicy,
    BaseButton,
  },
  computed: {
    termsAndConditionsHeading() {
      return this.phrases.legal.actionTermsOfUse
    },
  },
  methods: {
    ...mapActions('modal', ['closeModal']),
  },
  created() {
    this.$analytics.track({
      event: `Viewed ${PAGES.TERMS_CONDITIONS}`,
    })
  },
}
</script>

<style lang="scss" scoped>
.legal-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0;

  .scrollable-wrap {
    padding: 0 24px 40px;

    .powered-by-atomic-enabled & {
      padding: 0 24px;
    }

    @media (max-width: 850px) {
      padding: 0 24px;
    }

    .scrollable-container {
      position: relative;
      padding-right: 8px;
    }
  }

  .btn-wrap {
    padding: 32px 0;
  }

  .fade-bar {
    &.top {
      position: fixed;
      top: 0;
    }

    &.bottom {
      bottom: 0;

      .powered-by-atomic-enabled & {
        bottom: 0;
      }

      @media (min-width: 851px) {
        bottom: 24px;
      }
    }
  }
}
</style>
