import { EMULATOR_EVENT_TYPES } from '@/util/constants'
import { store } from '@/store'
import { get } from 'lodash-es'
import { SUPPORTED_FONTS } from '@/util/fonts'
import { DataError } from '@/util/custom-errors'

export function listenForEmulatorEvents() {
  window.addEventListener('message', function (message) {
    if (_isBase64Encoded({ data: message.data })) {
      try {
        const event = JSON.parse(atob(message.data))
        _handleEmulatorEvent(event)
      } catch (err) {
        console.error(new DataError(err.message, { message }))
      }
    }
  })
}

function _isBase64Encoded({ data }) {
  return (
    typeof data === 'string' &&
    data &&
    /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/.test(
      data,
    )
  )
}

function _handleEmulatorEvent(event) {
  const getDefaultIcon = store.getters['theme/getDefaultIcon']

  switch (event.type) {
    case EMULATOR_EVENT_TYPES.UPDATE_DARK_MODE:
      store.dispatch('theme/updateDarkMode', event.payload.value)
      break
    case EMULATOR_EVENT_TYPES.UPDATE_LANGUAGE:
      store.dispatch('i18n/updateLanguage', event.payload.value)
      break
    case EMULATOR_EVENT_TYPES.UPDATE_CUSTOMIZATION:
      store.dispatch(
        `${event.payload.namespace}/updateCustomization`,
        event.payload,
      )
      break
    case EMULATOR_EVENT_TYPES.GET_DEFAULT_ICON:
      _encodeImageUrl(
        getDefaultIcon(event.payload.pathToVuexData),
        (dataUrl) => {
          window.parent.postMessage(
            JSON.stringify({
              ...event.payload,
              value: dataUrl,
            }),
            '*',
          )
        },
      )
      break
    case EMULATOR_EVENT_TYPES.GET_DATA_FROM_VUEX:
      window.parent.postMessage(
        JSON.stringify({
          ...event.payload,
          value: get(
            store.state,
            `${event.payload.namespace}.${event.payload.pathToVuexData}`,
          ),
        }),
        '*',
      )
      break
    case EMULATOR_EVENT_TYPES.GET_SUPPORTED_FONTS:
      window.parent.postMessage(
        JSON.stringify({
          ...event.payload,
          value: [...SUPPORTED_FONTS.values()],
        }),
        '*',
      )
      break
  }
}

function _encodeImageUrl(url, callback) {
  let xhRequest = new XMLHttpRequest()

  xhRequest.onload = function () {
    let reader = new FileReader()

    reader.onloadend = function () {
      callback(reader.result)
    }

    reader.readAsDataURL(xhRequest.response)
  }

  xhRequest.open('GET', url)
  xhRequest.responseType = 'blob'
  xhRequest.send()
}
