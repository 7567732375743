<template>
  <BadgeBase :backgroundColor="colors.background" :border="border" :size="size">
    <IconInformation :size="16" :stroke="colors.icon" />
  </BadgeBase>
</template>

<script>
import { mapGetters } from 'vuex'
import BadgeBase from '@/components/Badge/BadgeBase.vue'
import IconInformation from '@/components/Icons/Atomic/IconInformation.vue'
import { getGlobalCSSValue } from '@/util/general'

const PALETTES = {
  BLUE: 'blue',
  NEUTRAL: 'neutral',
}

const COLORS = {
  [PALETTES.BLUE]: {
    light: {
      icon: getGlobalCSSValue('--info-600'),
      background: getGlobalCSSValue('--info-200'),
    },
    dark: {
      icon: getGlobalCSSValue('--info-300'),
      background: getGlobalCSSValue('--info-600'),
    },
  },
  [PALETTES.NEUTRAL]: {
    light: {
      icon: getGlobalCSSValue('--white'),
      background: getGlobalCSSValue('--gray-600'),
    },
    dark: {
      icon: getGlobalCSSValue('--white'),
      background: getGlobalCSSValue('--gray-600'),
    },
  },
}

export default {
  name: 'BadgeInfo',
  props: {
    palette: {
      type: String,
      default: PALETTES.NEUTRAL,
      validator(value) {
        return Object.values(PALETTES).includes(value)
      },
    },
    border: {
      type: Object,
    },
    size: {
      type: Object,
    },
  },
  components: {
    BadgeBase,
    IconInformation,
  },
  computed: {
    ...mapGetters('theme', ['useDarkMode']),
    colors() {
      return COLORS[this.palette][this.useDarkMode ? 'dark' : 'light']
    },
  },
}
</script>
