<template>
  <div
    class="fade-bar"
    :class="[position, { 'square-edges': squareEdges }, { dark: useDarkMode }]"
  ></div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FadeBar',
  props: {
    position: String,
    squareEdges: Boolean,
  },
  computed: {
    ...mapGetters('theme', ['useDarkMode']),
  },
}
</script>

<style lang="scss" scoped>
.fade-bar {
  height: 32px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 100;

  &.top {
    height: 32px;
    background: linear-gradient(0deg, rgba(#fff, 0) 0%, rgba(#fff, 1) 100%);
    top: 0;

    &.dark {
      background: linear-gradient(
        0deg,
        rgba(11, 14, 30, 0) 0%,
        rgba(11, 14, 30, 1) 100%
      );
    }
  }

  &.bottom {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.5) 40%,
      rgba(255, 255, 255, 1) 100%
    );
    bottom: 0;

    &.square-edges {
      border-radius: 0;
    }

    &.dark {
      background: linear-gradient(
        180deg,
        rgba(11, 14, 30, 0) 0%,
        rgba(11, 14, 30, 0.5) 40%,
        rgba(11, 14, 30, 1) 100%
      );
    }
  }
}
</style>
