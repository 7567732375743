export const taskHistory = {
  title: 'Welcome back',
  subtitle: 'Here is a summary of your activity',
  continue: 'Set up another direct deposit',
  remainder: 'Remainder',
  entirePaycheck: 'Entire paycheck',
  updated: 'Updated',
  updatePending: 'Update pending...',
  failed: 'Failed',
  automationNotSupported: 'Automated update not supported',
  fix: 'Fix',
  tryAgain: 'Try again',
  setUpManually: 'Set up manually',
  modify: 'Modify',
  badCredentials: 'Invalid username or password',
  updateFailed: 'Update failed',
  unknownError: 'Unknown error',
}
