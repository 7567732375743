<template>
  <svg
    v-bind="iconSize"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.9502 12.957H19.9502M19.9502 12.957L12.9502 5.95703M19.9502 12.957L12.9502 19.957"
      v-bind="iconStroke"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
import IconBase from '@/components/Icons/Atomic/IconBase.vue'

export default {
  name: 'IconArrowRight',
  extends: IconBase,
}
</script>
