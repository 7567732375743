import { makeRequest } from '@/util/requests'
import { pick, without } from 'lodash-es'
import {
  DISTRIBUTION_TYPES,
  COMPANY_TAGS,
  CONNECTOR_SLUGS,
  LANGUAGES,
  AUTHENTICATION_METHODS,
} from '@/util/constants'
import { getBranding } from '@/util/branding'
import { ApiError } from '@/util/custom-errors'
import { isADPConnector, isWorkdayConnector } from '@/util/connector'
import OpenReplay from '@/plugins/openreplay'
// These are company-specific fields that should eventually only be
// stored as part of selectedCompanyHistory and then referenced
// with the selectedCompany getter.
const COMPANY_FIELDS_STORED_IN_ROOT_OF_NAMESPACE = [
  '_id',
  'name',
  'connector',
  'defaultConnector',
  'branding',
  'alternativeConnectors',
  'coAuthConnectors',
  'isConfigurableConnector',
  'supportsUserProvidedConnectorParameters',
  'authenticators',
]

const state = {
  _id: '',
  name: '',
  tags: [],
  connector: {}, // this is always the HRIS system
  defaultConnector: {},
  activeConnector: {}, // this may be an HRIS system or authentication system
  franchiseParent: {},
  alternativeConnectors: [],
  defaultUplinkConfig: {},
  coAuthConnectors: [],
  defaultCoAuthConnectors: [],
  isConfigurableConnector: false,
  supportsUserProvidedConnectorParameters: false,
  isConfiguredWithParameters: false,
  monitorConversion: false,
  branding: {
    logo: {
      _id: '',
      url: '',
    },
    color: '',
  },
  selectedCompanyHistory: [],
  manualDeposit: {},
  smartAuth: {},
  preCoAuthConnector: {},
  preCoAuthActiveConnector: {},
}

const getters = {
  activeConnectorName: (state) => {
    return state.activeConnector.name
  },
  activeConnectorId: (state) => {
    return state.activeConnector._id
  },
  brandColor: () => {
    const branding = getBranding()
    return branding.foreground.color
  },
  companyName: (state, getters) => {
    return getters.selectedCompany.name
  },
  companyId: (state, getters) => {
    return getters.selectedCompany._id
  },
  connectorName: (state) => {
    return state.connector.name
  },
  connectorId: (state) => {
    return state.connector._id
  },
  usesMultiplePayrollProviders: (state) => {
    return !!state.alternativeConnectors.length
  },
  supportedCoAuthConnectors: (state, getters, rootState, rootGetters) => {
    return state.coAuthConnectors.filter((connector) => {
      const allProductsInTaskWorkflowAreSupported = rootGetters[
        'taskWorkflow/products'
      ].every((product) => connector.availableProducts.includes(product))

      const allNeededDistributionTypesSupported =
        rootGetters['main/allowFractionalDeposits'] &&
        rootGetters['taskWorkflow/productsIncludeDeposit']
          ? [DISTRIBUTION_TYPES.FIXED, DISTRIBUTION_TYPES.PERCENT].some(
              (distributionType) =>
                connector.capabilities.distributionTypes.includes(
                  distributionType,
                ),
            )
          : true

      // We are hardcoding some phrases in English on the back end for the experiment
      const languageIsSupported = rootState.i18n.language === LANGUAGES.ENGLISH

      return (
        allProductsInTaskWorkflowAreSupported &&
        allNeededDistributionTypesSupported &&
        getters.isParticipatingInCoAuthExperiment &&
        languageIsSupported &&
        !rootGetters['authentication/hasAttemptedCoAuthWithEntity'](
          connector._id,
        )
      )
    })
  },
  useCoAuthConnector: (_, getters) => {
    return getters.supportedCoAuthConnectors.length > 0
  },
  isParticipatingInCoAuthExperiment: (state, _, rootState) => {
    const inWorkdayExperiment =
      isWorkdayConnector(state.connector) &&
      !!rootState.experiment.ldFlags.credentiallessLoginWorkday

    const inAdpExperiment =
      isADPConnector(state.connector) &&
      !!rootState.experiment.ldFlags.credentiallessLoginAdp

    const inPreScreenExperiment =
      isADPConnector(state.connector) &&
      rootState.experiment.ldFlags.useCoAuthUserIdentity &&
      rootState.user.userData?.hasRequiredCoAuthIdentity

    return inWorkdayExperiment || inAdpExperiment || inPreScreenExperiment
  },
  recoveryOptions: (state, _, rootState) => {
    const flowOptions =
      state.activeConnector?.options?.loginRecoveryOptions.filter(
        ({ action }) => action === 'flow',
      )

    const urlOptions =
      state.activeConnector?.options?.loginRecoveryOptions?.filter(
        ({ action }) => action === 'url',
      )

    const hasEnabledRecoveryFlows =
      rootState.experiment.ldFlags.enableLoginRecoveryFlows &&
      flowOptions?.length

    return hasEnabledRecoveryFlows ? flowOptions : urlOptions
  },
  isGigProvider: (state) => {
    return state.tags.includes(COMPANY_TAGS.GIG_ECONOMY)
  },
  selectedCompany: (state) => state.selectedCompanyHistory[0] ?? {},
  selectedCompanyAuthenticators: (_, getters) =>
    getters.selectedCompany.authenticators,
  selectedCompanyHasAuthenticators: (_, getters) =>
    getters.selectedCompanyAuthenticators?.length > 0,
  connectorSupportsFractionalDeposits: (state) => {
    const distributionTypes =
      state?.connector?.capabilities?.distributionTypes || []

    return (
      distributionTypes.includes(DISTRIBUTION_TYPES.FIXED) ||
      distributionTypes.includes(DISTRIBUTION_TYPES.PERCENT)
    )
  },
  usesSmartAuth: (state, getters, rootState, rootGetters) => {
    return (
      state.smartAuth?.enabled &&
      !!rootState.experiment.ldFlags.useSmartAuth &&
      !rootGetters['userDeviceAutomation/usingUserAutomatedDevice']
    )
  },
  hasInitialSmartAuthState: (state) => {
    return !!state.smartAuth?.initialSmartAuthState
  },
  signingInToName: (state, getters, rootState, rootGetters) => {
    if (state.activeConnector.slug === CONNECTOR_SLUGS.SMART_AUTH) {
      return state.name
    } else if (state.activeConnector.slug.includes(CONNECTOR_SLUGS.API_KEY)) {
      return state.name + ' ' + rootGetters['i18n/phrases'].login.usingApiKeys
    }

    if (rootGetters['main/isScopePayLink']) {
      return getters.companyName
    }

    return state.activeConnector.name
  },
  showConfirmDistributionPage: (state) => {
    return state?.connector?.capabilities?.showConfirmDistributionPage
  },
  onlyAuthenticationMethodIsTrueAuth: (state) => {
    return state.activeConnector?.capabilities?.authenticationMethods?.every(
      (method) => method === AUTHENTICATION_METHODS.UPLINK,
    )
  },
}

const actions = {
  preCoAuthBackup({ commit, dispatch }) {
    dispatch('authenticator/preCoAuthBackup', undefined, { root: true })
    dispatch('taskWorkflow/preCoAuthBackup', undefined, { root: true })
    commit('setPreCoAuthConnector')
    commit('setPreCoAuthActiveConnector')
  },
  preCoAuthRestore({ commit, dispatch, state }) {
    dispatch('authenticator/preCoAuthRestore', undefined, { root: true })
    dispatch('taskWorkflow/preCoAuthRestore', undefined, { root: true })
    commit('setConnector', state.preCoAuthConnector)
    commit('setActiveConnector', state.preCoAuthActiveConnector)
    dispatch('updateSelectedCompany', {
      connector: state.preCoAuthConnector,
    })
  },
  async fetchCompanyDetails(
    { dispatch, commit, rootState, rootGetters },
    companyId,
  ) {
    const getCompany = async () => {
      const scope = _getScopeForGettingCompany(rootState)

      const response = await makeRequest({
        method: 'GET',
        endpoint: `/company/${companyId}?scope=${scope}`,
      })

      return response.data.data
    }

    try {
      const company = await getCompany()
      commit('setCompany', company)

      await dispatch(
        'userDeviceAutomation/updateUserAutomationDeviceConfig',
        company.userAutomationDeviceConfig,
        { root: true },
      )

      await dispatch(
        'authenticator/updateAuthenticators',
        company.authenticators,
        {
          root: true,
        },
      )

      if (rootGetters['main/isScopePayLink']) {
        dispatch('payLink/updatePaymentMethodForConnector', null, {
          root: true,
        })
      }

      if (company.tags.includes(COMPANY_TAGS.FRANCHISE_PARENT)) {
        commit('setFranchiseParent', company)
      }

      if (rootGetters['main/useTransactOpenReplay']) {
        OpenReplay.start({ userId: rootState.user.userData._id })
        OpenReplay.setMetadata('CompanyID', company._id)
        OpenReplay.setMetadata('ConnectorID', company.connector._id)
      }
    } catch (error) {
      console.error(new ApiError(error.message, error.response))
    }
  },
  async fetchConnectorDetails({ dispatch }, connectorId) {
    const getConnector = async () => {
      const response = await makeRequest({
        method: 'GET',
        endpoint: `/connector/${connectorId}`,
      })

      return response.data.data
    }

    try {
      const connector = await getConnector()

      dispatch('updateConnector', connector)
      dispatch('updateActiveConnector', connector)

      if (connector.authenticators) {
        await dispatch(
          'authenticator/updateAuthenticators',
          connector.authenticators,
          { root: true },
        )
      }
    } catch (error) {
      console.error(new ApiError(error.message, error.response))
    }
  },
  updateConnector({ commit }, payload) {
    commit('setConnector', payload)
  },
  updateActiveConnector({ commit }, payload) {
    commit('setActiveConnector', payload)
  },
  updateSelectedCompany({ commit }, company) {
    commit('updateSelectedCompany', company)
  },
  goBackInSelectedCompanyHistory({ commit, getters, state }) {
    if (state.selectedCompanyHistory.length > 1) {
      commit('shiftSelectedCompanyHistory')
      commit('setCompany', getters.selectedCompany)
    }
  },
  resetSelectedCompanyHistory({ commit }) {
    commit('resetRootCompanyFields')
    commit('setSelectedCompanyHistory', [])
  },
  async monitorCompanyConversion({ getters }) {
    if (!getters.selectedCompany?.monitorConversion) return

    await makeRequest({
      method: 'POST',
      endpoint: `/company/conversion`,
      data: {
        companyId: getters.selectedCompany._id,
      },
    })
  },
  // When a user selects an alternative connector, we'll query for the company and update the Uplink
  // configuration so that they can log directly into the alternative connector instead of current company's connector
  async getAlternativeConnectorCompany(
    { rootState, dispatch },
    { connectorId },
  ) {
    const scope = _getScopeForGettingCompany(rootState)

    const response = await makeRequest({
      method: 'GET',
      endpoint: `/connector/alternative-connector-company/${connectorId}?scope=${scope}`,
    })

    const company = response.data.data

    if (company) {
      await dispatch(
        'userDeviceAutomation/updateUserAutomationDeviceConfig',
        company.userAutomationDeviceConfig,
        { root: true },
      )
    }

    return response.data.data
  },
  async resetUplinkConfiguration({ state, dispatch }) {
    await dispatch(
      'userDeviceAutomation/updateUserAutomationDeviceConfig',
      state.defaultUplinkConfig,
      { root: true },
    )
  },
  resetCoAuthConnectors({ commit, state }) {
    commit('setCoAuthConnectors', state.defaultCoAuthConnectors)
  },
  updateCoAuthConnectors({ commit }, payload) {
    commit('setCoAuthConnectors', payload)
  },
}

const mutations = {
  setCoAuthConnectors: (state, coAuthConnectors) => {
    state.coAuthConnectors = coAuthConnectors
  },
  setCompany: (state, company) => {
    if (company._id) state._id = company._id
    if (company.name) state.name = company.name
    if (company.tags) state.tags = company.tags
    if (company.branding) state.branding = company.branding
    if (company.authenticators) state.authenticators = company.authenticators

    if (company.connector) {
      state.connector = company.connector
      state.activeConnector = company.connector
      state.defaultConnector = company.connector
    }

    if (company.userAutomationDeviceConfig) {
      state.defaultUplinkConfig = company.userAutomationDeviceConfig
    }

    state.isConfigurableConnector =
      company.isConfigurableConnector ?? state.isConfigurableConnector

    state.supportsUserProvidedConnectorParameters =
      company.supportsUserProvidedConnectorParameters ??
      state.supportsUserProvidedConnectorParameters

    state.isConfiguredWithParameters =
      company.isConfiguredWithParameters ?? state.isConfiguredWithParameters

    state.monitorConversion = company.monitorConversion
    state.manualDeposit = company.manualDeposit
    state.smartAuth = company.smartAuth

    state.alternativeConnectors =
      company.alternativeConnectors?.map((connector) => {
        const requiredConnectorParameterNames = connector.options?.parameters
          ?.filter(({ isOptional }) => isOptional !== true)
          .map(({ key }) => key)
        const autofilledCompanyParameterNames =
          connector.options?.sections[0]?.elements
            .filter((element) => element.autofilledByParameter)
            .map((element) => element.autofilledByParameter)

        return {
          ...connector,
          // TODO: this could and should eventually be a virtual field defined in our data model on the back end
          // Note, however, that UI element definition being in code may make this impossible with Mongoose
          hasRequiredParameters:
            without(
              requiredConnectorParameterNames,
              ...autofilledCompanyParameterNames,
            ).length > 0,
        }
      }) ?? []

    const companyCoAuthConnectors = company.coAuthConnectors ?? []
    const defaultConnectorCoAuthConnectors =
      company.connector?.coAuthConnectors ?? []
    const defaultCoAuthConnectors = [
      ...companyCoAuthConnectors,
      ...defaultConnectorCoAuthConnectors,
    ]

    state.coAuthConnectors = defaultCoAuthConnectors
    state.defaultCoAuthConnectors = defaultCoAuthConnectors

    // don't duplicate history, ie when going back
    if (company._id !== state.selectedCompanyHistory[0]?._id) {
      state.selectedCompanyHistory.unshift(
        pick(state, COMPANY_FIELDS_STORED_IN_ROOT_OF_NAMESPACE),
      )
    }
  },
  setConnector: (state, connector) => {
    state.connector = connector
  },
  setPreCoAuthConnector: (state) => {
    state.preCoAuthConnector = state.connector
  },
  setPreCoAuthActiveConnector: (state) => {
    state.preCoAuthActiveConnector = state.activeConnector
  },
  setActiveConnector: (state, activeConnector) => {
    state.activeConnector = activeConnector
  },
  setFranchiseParent: (state, company) => {
    state.franchiseParent = company
  },
  setSelectedCompanyHistory: (state, history) => {
    state.selectedCompanyHistory = history
  },
  shiftSelectedCompanyHistory: (state) => {
    state.selectedCompanyHistory.shift()
  },
  resetRootCompanyFields: (state) => {
    state._id = ''
    state.name = ''
    state.tags = []
    state.connector = {}
    state.activeConnector = {}
    state.defaultConnector = {}
    state.alternativeConnectors = []
    state.isConfigurableConnector = false
    state.supportsUserProvidedConnectorParameters = false
    state.monitorConversion = false
    state.branding = {
      logo: {
        _id: '',
        url: '',
      },
      color: '',
    }
  },
  updateSelectedCompany: (state, company) => {
    state.selectedCompanyHistory[0] = {
      ...state.selectedCompanyHistory[0],
      ...company,
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

function _getScopeForGettingCompany(rootState) {
  return rootState.main.scope
}
