<template>
  <BadgeBase :backgroundColor="badgeBackgroundColor">
    <IconArrowRight :size="14" :stroke="iconColor" />
  </BadgeBase>
</template>

<script>
import BadgeBase from '@/components/Badge/BadgeBase.vue'
import IconArrowRight from '@/components/Icons/Atomic/IconArrowRight.vue'
import { getGlobalCSSValue } from '@/util/general'

export default {
  name: 'BadgeArrowRight',
  components: {
    BadgeBase,
    IconArrowRight,
  },
  computed: {
    badgeBackgroundColor() {
      return getGlobalCSSValue('--gray-200')
    },
    iconColor() {
      return getGlobalCSSValue('--gray-600')
    },
  },
}
</script>
