<template>
  <svg
    width="39"
    height="39"
    viewBox="0 0 39 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="security-icon-svg"
    aria-hidden="true"
  >
    <path
      d="M22.9167 16.25L25.8333 13.3333M27.5 11.6667L25.8333 13.3333L27.5 11.6667ZM19.4917 19.675C19.9219 20.0995 20.264 20.605 20.4981 21.1623C20.7322 21.7196 20.8538 22.3177 20.8558 22.9222C20.8579 23.5267 20.7403 24.1256 20.5099 24.6844C20.2795 25.2433 19.9409 25.751 19.5134 26.1784C19.086 26.6059 18.5783 26.9445 18.0194 27.1749C17.4606 27.4053 16.8617 27.5229 16.2572 27.5208C15.6527 27.5188 15.0546 27.3972 14.4973 27.1631C13.94 26.929 13.4345 26.5869 13.01 26.1567C12.1751 25.2922 11.7131 24.1345 11.7236 22.9327C11.734 21.731 12.216 20.5814 13.0658 19.7317C13.9156 18.8819 15.0652 18.3998 16.2669 18.3894C17.4686 18.379 18.6264 18.8409 19.4908 19.6758L19.4917 19.675ZM19.4917 19.675L22.9167 16.25L19.4917 19.675ZM22.9167 16.25L25.4167 18.75L28.3333 15.8333L25.8333 13.3333L22.9167 16.25Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconAuth',
}
</script>
