export const maintenanceInterstitial = {
  inMaintenanceMode: ({ connectorName }) =>
    `Apologies, we are currently unable to support ${connectorName}.`,
  maintenanceDescDeposit:
    'We are working to restore this system. In the meantime, please set up your direct deposit using an another method.',
  maintenanceDescVerify:
    'In the meantime, please verify your information using another method.',
  maintenanceDescTax: 'Try again later or upload your tax return another way.',
  maintenanceDescDepositManual:
    'We are working to restore this system. Please set up your direct deposit manually in the meantime.',
  maintenanceDescGeneric:
    'We are working to restore this system. Please try again later.',
}
