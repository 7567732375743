<template>
  <ModalContent>
    <ModalBody>
      <ModalActionBar @close="triggerExitTransact" />
      <ModalContentWrap class="modal-error-wrap">
        <div class="modal-header">
          <Logo
            class="logo modal"
            :imgSrc="errorContent.logo"
            :large="true"
            :backgroundColor="errorContent.bgColor"
            :size="50"
          />
        </div>
        <div class="error-content">
          <ModalLabel :level="3" data-test-id="error-title">{{
            errorContent.title
          }}</ModalLabel>
          <ModalDescription
            element="p"
            class="paragraph"
            v-if="errorContent.description"
            v-html="errorContent.description"
          >
          </ModalDescription>
        </div>
        <div class="error-footer">
          <BaseButton
            v-for="(item, index) in errorContent.actionItems"
            :key="index"
            :text="item.title"
            :btn-style="index > 0 ? 'hollow' : 'solid'"
            customer-branded
            @handleClick="() => handledActionItemClick(item)"
          />
        </div>
      </ModalContentWrap>
    </ModalBody>
  </ModalContent>
</template>

<script>
import Logo from '@/components/Shared/LogoWrap.vue'
import BaseButton from '@/components/Form/BaseButton.vue'
import {
  ERROR_CODES,
  MODAL_ACTIONS,
  EXIT_REASONS,
  SDK_EVENT_TYPES,
} from '@/util/constants'
import { mapState } from 'vuex'
import { appClose } from '@/util/app-event'
import ModalContent from '@/components/Modal/ModalContent.vue'
import ModalLabel from '@/components/Modal/ModalLabel.vue'
import ModalDescription from '@/components/Modal/ModalDescription.vue'
import ModalActionBar from '@/components/Modal/ModalActionBar.vue'
import {
  global40000,
  global40103,
  global40104,
  global50X,
  globalSDKParameters,
  globalTaskUnauthorized,
  globalTimeout,
  globalWithholdNotSupported,
} from '@/util/errors'
import ModalBody from '@/components/Modal/ModalBody.vue'
import ModalContentWrap from '@/components/Modal/ModalContentWrap.vue'
import { hideSkeletonLoader } from '@/util/animations/skeleton'

export default {
  name: 'ErrorModal',
  components: {
    ModalContentWrap,
    ModalBody,
    ModalDescription,
    ModalLabel,
    ModalContent,
    ModalActionBar,
    Logo,
    BaseButton,
  },
  computed: {
    ...mapState('main', ['globalErrorStatus']),
    errorContent() {
      const store = this.$store
      switch (this.globalErrorStatus.code) {
        case ERROR_CODES.GLOBAL_40000:
          return global40000({ store })
        case ERROR_CODES.GLOBAL_40103:
          return global40103({ store })
        case ERROR_CODES.GLOBAL_40104:
          return global40104({ store })
        case ERROR_CODES.GLOBAL_40107:
          return globalTaskUnauthorized({
            code: this.globalErrorStatus.code,
            store,
          })
        case ERROR_CODES.GLOBAL_40108:
          return globalTaskUnauthorized({ store })
        case ERROR_CODES.GLOBAL_40109:
          return globalTaskUnauthorized({ store })
        case ERROR_CODES.GLOBAL_50X:
          return global50X({ store })
        case ERROR_CODES.GLOBAL_TIMEOUT:
          return globalTimeout({ store })
        case ERROR_CODES.GLOBAL_SDK_PARAMETERS:
          return globalSDKParameters({ store })
        case ERROR_CODES.GLOBAL_WITHHOLD_NOT_SUPPORTED:
          return globalWithholdNotSupported({ store })
        default:
          return {
            logo: '',
            bgColor: '',
            title: '',
            description: '',
            actionItems: '',
          }
      }
    },
  },
  methods: {
    triggerExitTransact() {
      const exitTransactActionItem = this.errorContent?.actionItems?.find(
        (item) => item.method === MODAL_ACTIONS.EXIT_TRANSACT,
      )

      appClose({
        store: this.$store,
        eventType: SDK_EVENT_TYPES.CLOSE,
        exitReason:
          exitTransactActionItem && exitTransactActionItem.exitReason
            ? exitTransactActionItem.exitReason
            : EXIT_REASONS.UNKNOWN,
      })
    },
    handledActionItemClick(item) {
      switch (item.method) {
        case MODAL_ACTIONS.EXIT_TRANSACT:
          appClose({
            store: this.$store,
            eventType: SDK_EVENT_TYPES.CLOSE,
            exitReason: item.exitReason,
          })
          break
        default:
          console.log('Unhandled event')
      }
    },
  },
  created() {
    hideSkeletonLoader({ store: this.$store })
  },
}
</script>

<style lang="scss" scoped>
.modal-error-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;

  .logo-wrap {
    border: none;
    &.border {
      border: none;
      .dark & {
        border: none;
      }
    }
    .dark & {
      border: none;
    }
  }
}

.error-content {
  flex: 1 0 auto;
  margin-top: 16px;
}
</style>
