import { store } from '@/store'
import { defaultsDeep } from 'lodash-es'

export function convertToThemeObject({ theme }) {
  return {
    buttons: theme.buttons,
    dark: theme.dark,
    colors: {
      brand: theme.brandColor,
      overlay: theme.overlayColor,
      links: theme.linkColor,
      overrideDefaults: theme.overrideDefaults,
    },
    display: theme.display,
    fonts: theme.fonts,
    icons: theme.icons,
    poweredByAtomic: theme.poweredByAtomic,
    ..._navigationOptions({ theme }),
  }
}

function _navigationOptions({ theme }) {
  const defaultNavigationOptions = {
    showBackButtonText: theme.showBackButtonText,
  }

  return {
    navigationOptions: {
      ...defaultNavigationOptions,
      ...(theme.navigationOptions || {}),
    },
  }
}

export function setThemeFromDatabase({ theme, customCSSEnabledAndUsed }) {
  if (customCSSEnabledAndUsed) {
    const customCSS = theme.customCSS
    setCustomCSSFromDatabase({ customCSS })
  }

  store.dispatch(
    'theme/updateTheme',
    defaultsDeep(store.state.theme.custom, theme),
  )
}

function setCustomCSSFromDatabase({ customCSS }) {
  try {
    const [htmlHead] = document.getElementsByTagName('head')
    const styleElement = document.createElement('style')
    const stylesTextNode = document.createTextNode(customCSS)

    styleElement.appendChild(stylesTextNode)
    htmlHead.appendChild(styleElement)
  } catch (e) {
    throw new Error(`Failed to apply custom styles: ${e}`)
  }
}

export function customCSSIsEnabledAndUsed({ userData }) {
  return (
    userData?.customer?.features?.customCSS?.enabled &&
    userData?.customer?.transactConfiguration?.theme?.customCSS?.length > 0
  )
}
