import { APP_CLIP_STATES } from '@/util/constants'

const state = {
  hasOpenedAppClip: false,
  appClipState: APP_CLIP_STATES.LOADING,
  shortUrl: undefined,
}

const actions = {
  updateHasOpenedAppClip({ commit }, value) {
    commit('setHasOpenedAppClip', value)
  },
  updateAppClipState({ commit }, value) {
    commit('setAppClipState', value)
  },
  updateAppClipShortUrl({ commit }, shortUrl) {
    commit('setAppClipShortUrl', shortUrl)
  },
}

const mutations = {
  setHasOpenedAppClip: (state, value) => {
    state.hasOpenedAppClip = value
  },
  setAppClipState: (state, value) => {
    state.appClipState = value
  },
  setAppClipShortUrl: (state, shortUrl) => {
    state.shortUrl = shortUrl
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
