import { PAY_LINK_USER_STATUS } from '@/util/constants'

export const home = {
  [PAY_LINK_USER_STATUS.SELECTING]: {
    title: 'Start updating your payments',
    subtitle: `Here's what you've selected so far.`,
  },
  [PAY_LINK_USER_STATUS.PRESELECTED]: {
    title: 'Start linking your payments',
    subtitle: `We've preselected these merchants for you to get started.`,
  },
  [PAY_LINK_USER_STATUS.IN_PROGRESS]: {
    title: 'Nice work so far',
    subtitle: `Here's where you left off.`,
  },
  [PAY_LINK_USER_STATUS.FINISHED]: {
    title: 'Great work!',
    subtitle: `You've finished updating these payments.`,
  },
  emptyState: {
    title: 'No payments selected',
    subtitle: 'Select payments to link',
  },
  linkAnotherPayment: 'Link another payment',
  selectMorePayments: 'Select more payments',
}
