<template>
  <ModalActionBar />
  <ModalContent>
    <ModalBody>
      <ModalContentWrap class="security-wrap">
        <div class="fixed-modal-title">
          <ModalLabel :level="3" data-test-id="security-title"
            >{{ phrases.security.title }}
          </ModalLabel>
          <ModalClose />
        </div>

        <ScrollableWrap data-test-id="security-scroll">
          <dl>
            <ModalDescription element="div">
              <dd>
                <p class="paragraph intro">{{ phrases.security.intro }}</p>
              </dd>
              <div class="security-bullet">
                <div class="icon"><IconAES /></div>
                <div class="security-content">
                  <dt>
                    <h4 class="bullet-title">
                      {{ phrases.security.bullet1Title }}
                    </h4>
                  </dt>
                  <dd>
                    <p class="paragraph">
                      {{ phrases.security.bullet1Text }}
                    </p>
                  </dd>
                </div>
              </div>
              <div class="security-bullet">
                <div class="icon"><IconTLS /></div>
                <div class="security-content">
                  <dt>
                    <h4 class="bullet-title">
                      {{ phrases.security.bullet2Title }}
                    </h4>
                  </dt>
                  <dd>
                    <p class="paragraph">
                      {{ phrases.security.bullet2Text }}
                    </p>
                  </dd>
                </div>
              </div>
              <div class="security-bullet">
                <div class="icon"><IconAuth /></div>
                <div class="security-content">
                  <dt>
                    <h4 class="bullet-title">
                      {{ phrases.security.bullet3Title }}
                    </h4>
                  </dt>
                  <dd>
                    <p class="paragraph">
                      {{ phrases.security.bullet3Text }}
                    </p>
                  </dd>
                </div>
              </div>
              <div class="security-bullet">
                <div class="icon"><IconAudit /></div>
                <div class="security-content">
                  <dt>
                    <h4 class="bullet-title">
                      {{ phrases.security.bullet4Title }}
                    </h4>
                  </dt>
                  <dd>
                    <p class="paragraph">
                      {{ phrases.security.bullet4Text }}
                    </p>
                  </dd>
                </div>
              </div>
            </ModalDescription>
          </dl>
          <BaseButton
            data-test-id="security-back"
            btn-style="hollow"
            :text="phrases.shared.close"
            @handleClick="closeModal"
          />
        </ScrollableWrap>
      </ModalContentWrap>
    </ModalBody>
  </ModalContent>
</template>

<script>
import { mapActions } from 'vuex'
import ScrollableWrap from '@/components/Layout/ScrollableWrap.vue'
import BaseButton from '@/components/Form/BaseButton.vue'
import ModalContent from '@/components/Modal/ModalContent.vue'
import { PAGES } from '@/util/constants'
import ModalLabel from '@/components/Modal/ModalLabel.vue'
import ModalDescription from '@/components/Modal/ModalDescription.vue'
import ModalActionBar from '@/components/Modal/ModalActionBar.vue'
import ModalBody from '@/components/Modal/ModalBody.vue'
import ModalContentWrap from '@/components/Modal/ModalContentWrap.vue'
import ModalClose from '@/components/Modal/ModalClose.vue'
import IconAES from '@/components/Icons/IconAES.vue'
import IconAuth from '@/components/Icons/IconAuth.vue'
import IconAudit from '@/components/Icons/IconAudit.vue'
import IconTLS from '@/components/Icons/IconTLS.vue'
export default {
  name: 'ProtectYouModal',
  components: {
    ModalContentWrap,
    ModalBody,
    ModalDescription,
    ModalLabel,
    ModalActionBar,
    ModalContent,
    ScrollableWrap,
    IconAES,
    IconAuth,
    IconAudit,
    IconTLS,
    BaseButton,
    ModalClose,
  },
  methods: {
    ...mapActions('modal', ['closeModal']),
  },
  created() {
    this.$analytics.track({
      event: `Viewed ${PAGES.YOU_ARE_PROTECTED}`,
    })
  },
}
</script>

<style lang="scss" scoped>
.security-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0;

  .scrollable-wrap {
    padding: 0px 24px 72px 24px;

    .powered-by-atomic-enabled & {
      padding: 0px 24px;
    }

    @media (max-width: 850px) {
      padding: 0px 24px;
    }

    .scrollable-container {
      position: relative;
    }
  }

  .security-bullet {
    align-items: flex-start;
    display: flex;
    flex-shrink: 0;
    margin-top: 32px;

    svg {
      border-radius: var(--defaultBorderRadius);
      width: 40px;
      height: 40px;
      color: var(--gray-700);
      background-color: var(--gray-100);

      .dark & {
        color: var(--gray-200);
        background-color: var(--gray-900);
      }
    }
  }

  .security-content {
    margin-left: 16px;

    .bullet-title {
      margin-top: 8px;
    }
    .paragraph {
      margin-top: 8px;
      color: var(--gray-600);

      .dark & {
        color: var(--gray-500);
      }
    }
  }
  .btn {
    margin: 32px auto;
  }
}
</style>

<style lang="scss" scoped>
.security-wrap {
  .fade-bar.top {
    position: fixed;
    top: 0;
  }
  .fade-bar.bottom {
    bottom: 0;

    .powered-by-atomic-enabled & {
      bottom: 0;
    }

    @media (min-width: 851px) {
      bottom: 72px;
    }
  }
}
</style>
