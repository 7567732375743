<template>
  <div class="distribution-amount">
    <div class="distribution-content">
      <div class="d-flex justify-content-between distribution-title">
        <ModalLabel :level="2" class="title">
          {{ changeDistributionDepositTitle }}
        </ModalLabel>
        <CloseButton @close="close" />
      </div>

      <p v-if="changeDistributionDepositSubtitle" class="paragraph">
        {{ changeDistributionDepositSubtitle }}
      </p>

      <div v-if="isFixedSelected" class="amount-container">
        <div class="fixed-wrap">
          <input type="hidden" v-model="amount" maxlength="5" />

          <div class="fixed-container" :class="{ dark: useDarkMode }">
            <span class="small-dollar">$</span>
            {{ currencyAmount }}
          </div>
        </div>
        <div class="error-wrap my-1" :class="{ dark: useDarkMode }">
          <Transition name="error-fade-translate">
            <div v-if="amountIsOutOfBounds">
              <AlertPill
                :copy="errorCopy"
                :level="
                  validationIsError
                    ? $CONSTANTS.DISTRIBUTION_LIMIT_TYPES.ERROR
                    : $CONSTANTS.DISTRIBUTION_LIMIT_TYPES.WARN
                "
              />
            </div>
          </Transition>
        </div>
      </div>

      <div v-if="isPercentSelected" class="amount-container mb-1">
        <div class="percent-wrap">
          <input type="hidden" v-model="amount" maxlength="2" />
          <div class="donut-container">
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 40 40"
              class="donut"
              aria-labelledby="donutTitle donutDesc"
              role="img"
            >
              <title id="donutTitle">
                {{ phrases.percentDeposit.graphTitleText }},
              </title>
              <desc id="donutDesc">
                {{ amount }}%{{ phrases.percentDeposit.ariaDescription }}
              </desc>
              <circle
                class="donut-ring"
                :class="{ dark: useDarkMode }"
                :stroke="taskBrand"
                cx="20"
                cy="20"
                r="16"
                fill="transparent"
                stroke-width="2"
              ></circle>
              <circle
                class="donut-segment"
                :stroke="taskBrand"
                cx="20"
                cy="20"
                r="16"
                fill="transparent"
                stroke-width="2"
                :stroke-dasharray="`${amount} ${currentAmount}`"
                stroke-dashoffset="25"
              ></circle>
            </svg>
            <span class="transfer-amount" :class="{ dark: useDarkMode }">
              {{ amount }}%
            </span>
          </div>
          <div class="donut-legend">
            <div class="legend-item">
              <span
                aria-hidden="true"
                class="circle track"
                :class="{ dark: useDarkMode }"
                :style="{ 'background-color': taskBrand }"
              ></span>
              <p
                :class="{ dark: useDarkMode }"
                :aria-label="
                  phrases.percentDeposit.distributionAmountCurrentAccountLabel({
                    currentAmount,
                  })
                "
              >
                {{ phrases.fixedDeposit.currentAccount }}
              </p>
            </div>
            <div class="legend-item">
              <span
                aria-hidden="true"
                class="circle"
                :style="{ 'background-color': taskBrand }"
              ></span>
              <p
                :class="{ dark: useDarkMode }"
                :aria-label="
                  phrases.percentDeposit.distributionAmountNewAccountLabel({
                    amount,
                  })
                "
              >
                {{ phrases.fixedDeposit.newAccount }}
              </p>
            </div>
          </div>
        </div>
        <div>
          <div class="error-wrap">
            <Transition name="error-fade-translate">
              <div v-if="amountIsOutOfBounds">
                <AlertPill
                  :copy="errorCopy"
                  :level="
                    validationIsError
                      ? $CONSTANTS.DISTRIBUTION_LIMIT_TYPES.ERROR
                      : $CONSTANTS.DISTRIBUTION_LIMIT_TYPES.WARN
                  "
                />
              </div>
            </Transition>
          </div>
        </div>
      </div>
    </div>

    <BaseButton
      btn-style="solid"
      data-test-id="next"
      task-branded
      :text="phrases.shared.continue"
      :isDisabled="!amountEntered || validationIsError"
      @handleClick="btnAction"
    />

    <Keypad @handleClick="clickedValue" />
    <span class="sr-only" aria-live="assertive">{{ ariaFriendlyAmount }}</span>
  </div>
</template>

<script>
import Keypad from '@/components/Shared/Keypad.vue'
import BaseButton from '@/components/Form/BaseButton.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import {
  DISTRIBUTION_TYPES,
  PAGES,
  DISTRIBUTION_LIMIT_TYPES,
  ATOMIC_BRANDING,
} from '@/util/constants'
import ModalLabel from '@/components/Modal/ModalLabel.vue'
import AlertPill from '@/components/AlertPill/AlertPill.vue'
import CloseButton from '@/components/Shared/CloseButton.vue'
import { getTextPhrase } from '@/util/i18n'
import { haptics } from '@/util/client-side-automation'
import { startCase } from 'lodash-es'

export default {
  name: 'DistributionAmount',
  emits: ['cancel', 'changedDistribution', 'choseAmount'],
  components: {
    CloseButton,
    ModalLabel,
    Keypad,
    BaseButton,
    AlertPill,
  },
  props: {
    distributionType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentAmount: 100,
      amount: 0,
      amountEntered: false,
      selectedDistributionType: undefined,
      dirty: false,
    }
  },
  computed: {
    ...mapState('distribution', [
      'newDistributionType',
      'newDistributionAmount',
      'fractionalError',
    ]),
    ...mapGetters('distribution', ['distributionLimits']),
    ...mapState('taskWorkflow', ['taskWorkflowBranding']),
    ...mapGetters('user', ['customerName']),
    ...mapGetters('theme', [
      'useDarkMode',
      'overrideColorDefaults',
      'customerBrandColor',
    ]),
    ariaFriendlyAmount() {
      let ariaFriendlyAmount = ''

      if (this.isFixedSelected) {
        ariaFriendlyAmount = '$' + this.amount
      } else if (this.isPercentSelected) {
        ariaFriendlyAmount = this.amount + '%'
      }

      return this.dirty
        ? this.phrases.shared.ariaUpdateDepositMessage + ariaFriendlyAmount
        : undefined
    },
    changeDistributionDepositTitle() {
      if (this.selectedDistributionType === DISTRIBUTION_TYPES.PERCENT) {
        return getTextPhrase({
          key: 'changeDistributionTitlePercent',
          namespace: 'shared',
          store: this.$store,
        })
      } else {
        return getTextPhrase({
          key: 'changeDistributionTitleFixed',
          namespace: 'shared',
          store: this.$store,
        })
      }
    },
    changeDistributionDepositSubtitle() {
      return this.selectedDistributionType === DISTRIBUTION_TYPES.PERCENT
        ? getTextPhrase({
            key: 'changeDistributionSubtitlePercent',
            store: this.$store,
          })
        : getTextPhrase({
            key: 'changeDistributionSubtitleFixed',
            store: this.$store,
          })
    },
    currencyAmount() {
      return this.$filters.currency(this.amount).replace(/\$/g, '')
    },
    taskBrand() {
      return this.overrideColorDefaults
        ? this.customerBrandColor
        : this.taskWorkflowBranding.foreground?.color ||
            ATOMIC_BRANDING.BRAND_COLOR
    },
    amountIsBelowMin() {
      return this.amount < this.distributionLimits?.minimum?.amount
    },
    amountIsAboveMax() {
      return this.amount > this.distributionLimits?.maximum?.amount
    },
    amountIsOutOfBounds() {
      return this.dirty && (this.amountIsBelowMin || this.amountIsAboveMax)
    },
    errorCopy() {
      if (this.amountIsAboveMax) {
        return this.phrases.distribution[
          `overMaximumFractionalDistribution${startCase(
            this.selectedDistributionType,
          )}`
        ]
      } else if (this.amountIsBelowMin) {
        return this.phrases.distribution[
          `underMinimumFractionalDistribution${startCase(
            this.selectedDistributionType,
          )}`
        ]
      } else {
        return ''
      }
    },
    validationIsError() {
      return (
        (this.amountIsBelowMin &&
          this.distributionLimits?.minimum?.type ===
            DISTRIBUTION_LIMIT_TYPES.ERROR) ||
        (this.amountIsAboveMax &&
          this.distributionLimits?.maximum?.type ===
            DISTRIBUTION_LIMIT_TYPES.ERROR)
      )
    },
    isPercentSelected() {
      return this.distributionType === DISTRIBUTION_TYPES.PERCENT
    },
    isFixedSelected() {
      return this.distributionType === DISTRIBUTION_TYPES.FIXED
    },
  },
  methods: {
    ...mapActions('distribution', [
      'updateNewDistributionAmount',
      'updateNewDistributionType',
      'updateDistributionLimits',
    ]),
    clickedValue($params) {
      haptics.tap()

      // For fixed amounts, we limit the input to 6 digits
      // For percent amounts, we limit the input to 2 digits
      const inputLimit =
        this.selectedDistributionType === DISTRIBUTION_TYPES.FIXED ? 6 : 2
      const inputLength = this.amount.toString().length
      const clickedValue = $params.clickedValue

      if (clickedValue === 'delete') {
        if (inputLength <= 1) {
          this.amount = 0
        } else {
          this.amount = this.amount.slice(0, -1)
        }
      } else {
        if (inputLength < inputLimit) {
          // If inputLength <= 0, meaning the first number they click
          // and the clicked value is 0, then just return
          if (inputLength <= 0 && clickedValue) return
          if (this.amount === 0) {
            this.amount = clickedValue
          } else {
            // Change from into to stiring in order to append the next number
            this.amount = this.amount.toString() + clickedValue
          }
        }
      }
      this.checkNumberLength(this.amount.toString().length)
      this.currentAmount = 100 - parseInt(this.amount)
      this.dirty = true
    },
    checkNumberLength(inputLength) {
      if (inputLength && this.amount) {
        this.amountEntered = true
      } else {
        this.amountEntered = false
      }
    },
    async btnAction() {
      await this.updateNewDistributionType(this.distributionType)
      await this.updateNewDistributionAmount(parseInt(this.amount))

      this.$analytics.track({
        event: `Clicked Continue From ${
          this.selectedDistributionType === DISTRIBUTION_TYPES.FIXED
            ? PAGES.FIXED_DEPOSIT
            : PAGES.PERCENT_DEPOSIT
        }`,
        payload: {
          distributionType: this.selectedDistributionType,
          distributionAmount: this.newDistributionAmount,
        },
      })

      if (this.fractionalError.requiresAction) {
        this.$emit('changedDistribution')
      } else {
        this.$emit('choseAmount')
      }
    },
    close() {
      this.$emit('cancel')
      this.$analytics.track({
        event: 'Closed Distribution Amount Modal',
      })
    },
  },
  mounted() {
    // Copy over the mapped distribution type to be local to this component
    // so that animations work smoothly when the distribution type is changed
    this.selectedDistributionType = this.distributionType

    this.$analytics.track({
      event: `Viewed ${
        this.selectedDistributionType === DISTRIBUTION_TYPES.FIXED
          ? PAGES.FIXED_DEPOSIT
          : PAGES.PERCENT_DEPOSIT
      }`,
    })
  },
}
</script>

<style lang="scss" scoped>
.amount-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 0 auto;

  .powered-by-atomic-enabled & {
    @media (max-height: 667px) {
      margin-bottom: 24px;
    }
  }
}

.distribution-amount {
  display: flex;
  height: 100%;
  flex-direction: column;

  .distribution-content {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;

    .icon-close {
      left: 4px;
      position: relative;
      top: -6px;
      z-index: 1;
    }
  }
}

.error-fade-translate-enter-active,
.error-fade-translate-leave-active {
  transition: all 250ms cubic-bezier(0.45, 1.45, 0.8, 1);
}

.error-fade-translate-enter-from,
.error-fade-translate-leave-to {
  transform: translateY(10px);
  opacity: 0;
}

.fixed-wrap {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 32px;
  max-height: 156px;
}

.fixed-container {
  display: flex;
  color: var(--gray-900);
  font-size: 8rem;
  justify-content: center;
  position: relative;

  .small-dollar {
    font-size: 2.4rem;
    color: var(--gray-600);
    position: absolute;
    left: -16px;
  }

  @media (max-width: 380px) {
    font-size: 6rem;
  }

  &.dark {
    color: var(--darkModeTextHigh);
  }
}

.percent-wrap {
  align-items: center;
  display: flex;
  color: var(--gray-700);
  flex: 1;
  flex-direction: column;

  .donut-container {
    height: auto;
    margin-top: 16px;
    position: relative;
    width: 160px;

    .powered-by-atomic-enabled & {
      @media (max-height: 667px) {
        margin-top: 8px;
        width: 140px;
      }
    }

    @media (min-height: 750px) {
      margin-top: 32px;
    }

    @media (max-width: 850px) and (min-height: 820px) {
      width: 240px;
    }

    @media (max-width: 850px) and (min-height: 900px) {
      width: 320px;
    }

    .transfer-amount {
      color: var(--gray-900);
      font-size: 4.8rem;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);

      &.dark {
        color: var(--darkModeTextHigh);
      }
    }
  }
}

.donut-ring {
  opacity: 0.2;

  &.dark {
    opacity: 0.3;
  }
}

.donut-segment {
  stroke-linecap: round;
  transform-origin: center;
  transition: 1000ms ease-in-out;
}

.donut-legend {
  align-items: center;
  display: flex;
  justify-content: center;

  .legend-item {
    align-items: center;
    display: flex;
    margin: 0 8px;

    p {
      margin: 0;
      font-size: 1rem;
      line-height: 1;
      margin-left: calc(var(--marginBase) * 0.5);
      text-transform: uppercase;

      &.dark {
        color: var(--darkModeTextHigh);
      }
    }

    .circle {
      border-radius: 50%;
      display: inline-block;
      height: 8px;
      width: 8px;

      &.track {
        opacity: 0.2;

        &.dark {
          opacity: 0.3;
        }
      }
    }
  }
}
</style>
