<template>
  <Card
    v-if="productIsSwitch"
    is="div"
    class="mb-4"
    :aria-label="phrases.shared.linkedPaymentMethod"
    :aria-description="
      phrases.shared.paymentMethodAriaDescription({
        paymentMethod: this.task.settings.switch.paymentMethod,
      })
    "
  >
    <PaymentMethodOptionContent
      aria-hidden="true"
      :payment-method="task.settings.switch.paymentMethod"
    />
  </Card>
  <div class="action-list">
    <BaseButton
      v-if="productIsSwitch"
      btn-style="solid"
      data-test-id="update"
      :text="phrases.shared.updatePaymentMethod"
      customer-branded
      @handleClick="handleClickUpdatePaymentMethod"
    />
    <TextButton
      data-test-id="close"
      :text="phrases.shared.close"
      @handleClick="handleClickClose"
    />
  </div>
</template>

<script>
import BaseButton from '@/components/Form/BaseButton.vue'
import TextButton from '@/components/Form/TextButton.vue'
import Card from '@/components/Shared/Card.vue'
import PaymentMethodOptionContent from '@/components/Switch/PaymentMethodOptionContent.vue'
import { PAGES, PRODUCTS } from '@/util/constants'
import { createSelectionAnalyticsPayload } from '@/util/pay-link'

export default {
  name: 'SelectionDetailModalContentCompleted',
  components: {
    BaseButton,
    TextButton,
    Card,
    PaymentMethodOptionContent,
  },
  emits: ['close', 'update-payment-method'],
  props: {
    selection: Object,
    task: Object,
  },
  computed: {
    productIsSwitch() {
      return this.task.product === PRODUCTS.SWITCH
    },
    productIsPresent() {
      return this.task.product === PRODUCTS.PRESENT
    },
  },
  methods: {
    handleClickClose() {
      this.$emit('close')
    },
    handleClickUpdatePaymentMethod() {
      this.$analytics.track({
        event: `Clicked Update Payment Method From ${PAGES.SELECTION_DETAILS}`,
        payload: createSelectionAnalyticsPayload(this.selection),
      })
      this.$emit('update-payment-method')
    },
  },
}
</script>
