import { assign, actions } from 'xstate'
import { store } from '@/store'
import router from '@/router'
import { submitTaskFormData } from '@/util/authentication'
import {
  startTaskAnimation,
  startChangeAuthenticatorAnimation,
  finishChangeAuthenticatorAnimation,
} from '@/util/animation'
import { navigateToCompanyLogin } from '@/util/navigation'
import { TASK_WORKFLOW_VIEW, ROUTES } from '@/util/constants'
import { updateForegroundLogo } from '@/util/task-workflow'
import { AUTHENTICATION_STATES } from './states'
import { pick } from 'lodash-es'
const { choose } = actions

export const authenticationActions = {
  initialize: assign((_, args) => args),
  launchADPRecoveryFlow: () => {
    _launchRecoveryFlow({ flowId: 'loginRecovery' })
  },
  launchRecoveryFlow: assign((_, { recoveryOption }) =>
    _launchRecoveryFlow({ flowId: recoveryOption.flow }),
  ),
  assignQuery: assign((_, { query }) => ({ query })),
  goBackInSelectedCompanyHistory: async () => {
    await store.dispatch('company/goBackInSelectedCompanyHistory')
  },
  clearConnectorSearch: async () => {
    await store.dispatch('search/updateConnectorSearchQuery', '')
    await store.dispatch('search/updateConnectorCompanies', {
      companies: [],
      requestedAt: 0,
    })
  },
  goToPreviousPage: async () => {
    await store.dispatch('main/goBackToPreviousPage')
  },
  goToSearchPage: async () => {
    router.push({ name: ROUTES.SEARCH_COMPANY })
  },
  resetCoAuthConnectors: async () => {
    await store.dispatch('company/resetCoAuthConnectors')
  },
  setStep: assign((_, { stepIdx }) => ({
    currentStepIdx: stepIdx,
  })),
  dispatchGoBackStep: async () => {
    await store.dispatch('formFlow/goBackToPreviousStep')
  },
  resetStep: assign({ currentStepIdx: 0 }),
  launchConfirmDistribution: async () => {
    await store.dispatch('taskWorkflow/updateTaskWorkflowState', {
      view: TASK_WORKFLOW_VIEW.CONFIRM_DISTRIBUTION,
    })
  },
  startTask: async (_, { requestType, formData, store }) => {
    submitTaskFormData({ requestType, formData, store })
    store.dispatch('task/startTaskCountdown')
    await startTaskAnimation({ store })
    store.dispatch('taskWorkflow/updateTaskWorkflowState', {
      view: TASK_WORKFLOW_VIEW.IN_PROGRESS,
    })
  },
  assignInitialState: choose([
    {
      cond: 'activeConnectorIsAdp',
      actions: assign({
        initialState: AUTHENTICATION_STATES.SELECTING_LOGIN_METHOD,
      }),
    },
    {
      actions: assign({ initialState: AUTHENTICATION_STATES.LOGGING_IN }),
    },
  ]),
  changeSelectedCompany: async (_, { companyId, store, router }) => {
    await startChangeAuthenticatorAnimation()
    await navigateToCompanyLogin({
      companyId,
      store,
      router,
    })
    finishChangeAuthenticatorAnimation()
  },
  resetUplinkConfigurationToDefaultConnector: async () => {
    await store.dispatch('company/resetUplinkConfiguration')
  },
  setCoauthConnectorSettings: assign(() => {
    const [coAuthConnector] = store.getters['company/supportedCoAuthConnectors']

    return {
      showConfirmDistributionPage:
        coAuthConnector.capabilities.showConfirmDistributionPage,
    }
  }),
  enforceCoAuthConnectorBrandingAndAuthentication: () => {
    const [coAuthConnector] = store.getters['company/supportedCoAuthConnectors']

    store.dispatch('company/preCoAuthBackup')
    store.dispatch('authenticator/updateAuthenticatorId', '')
    store.dispatch('company/updateConnector', coAuthConnector)
    store.dispatch('company/updateActiveConnector', coAuthConnector)
    store.dispatch('company/updateSelectedCompany', {
      connector: coAuthConnector,
    })
    updateForegroundLogo(coAuthConnector)
  },
  hideTaskWorkflowBrandingHeader: () => {
    store.dispatch('taskWorkflow/updateShowTaskWorkflowBrandingHeader', false)
  },
  showTaskWorkflowBrandingHeader: () => {
    // Details on why the timeout is here:
    // https://linear.app/atomicbuilt/issue/DD-573/taskbrandingheader-is-not-visible-when-backing-out-of-adp-coauth#comment-354731fb
    setTimeout(() => {
      store.dispatch('taskWorkflow/updateShowTaskWorkflowBrandingHeader', true)
    }, 25)
  },
}

function _launchRecoveryFlow({ flowId }) {
  const recoveryFlow = store.state.company.activeConnector.flows.find(
    (flow) => {
      return flow.id === flowId
    },
  )

  store.dispatch(
    'task/updateRecoveryFlow',
    pick(recoveryFlow, ['id', 'sections', 'type']),
  )
}
