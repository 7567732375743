import { TASK_FAIL_REASON } from '@/util/constants'

const tryAgainOrSkipMessage =
  'Please try again or you can skip this payment and try later.'

export const errorMessage = {
  [TASK_FAIL_REASON.CONNECTION_ERROR]: {
    title: 'We ran into a connection error',
    message:
      'We were unable to finish updating your payment method. Please try again.',
  },
  [TASK_FAIL_REASON.PAYMENT_METHOD_LOCKED]: {
    title: 'Payment method locked',
    message:
      "You've reached the payment method update limit. Please try again in 3 days.",
  },
  [TASK_FAIL_REASON.PAYMENT_METHOD_NOT_SUPPORTED]: {
    title: 'Your payment method is not supported',
    message:
      'Select a different payment method to finish updating your payment method.',
  },
  [TASK_FAIL_REASON.PAYMENT_METHOD_LIMIT_REACHED]: {
    title: 'Too many payment methods',
    message:
      'You’ve reached the maximum number of payment methods for your account. Please remove one and try again.',
  },
  [TASK_FAIL_REASON.SUBSCRIPTION_INACTIVE]: {
    title: 'Your subscription is inactive',
    message: 'Are you sure this is the correct account?',
  },
  [TASK_FAIL_REASON.PAYMENT_METHOD_DECLINED]: {
    title: 'Your payment method was declined',
    message: `There was an issue with your card. Please check that it is active, unfrozen and has sufficient funds and try again.`,
  },
  [TASK_FAIL_REASON.PAYMENT_SWITCH_UNSUCCESSFUL]: {
    title: 'Payment switch unsuccessful',
    message: tryAgainOrSkipMessage,
  },
  [TASK_FAIL_REASON.UNKNOWN_FAILURE]: {
    title: 'We ran into an unexpected error',
    message: tryAgainOrSkipMessage,
  },
}
