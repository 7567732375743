export const taskCompleted = {
  allDoneHere: "You're all set!",
  continueBackOnOtherDevice: 'Continue on your web browser.',
  changeTimeline: 'Updates may take 1-2 pay cycles to take effect.',
  ssChangeTimeline: 'Updates may take 30-60 days to take effect.',
  linkAnotherGigAccount: 'Set up another gig direct deposit',
  linkAnotherAccount: 'Set up another account',
  messageOnlyAuthCompletedTitle: "Great job! Now it's our turn.",
  messageOnlyAuthCompletedDeposit:
    "We're finishing up your direct deposit update, no need for you to hang around.",
  messageOnlyAuthCompletedVerify:
    "We're finishing up linking your payroll, no need for you to hang around.",
  messageOnlyAuthCompletedWithhold:
    "We're finishing up your withholdings update, no need for you to hang around.",
  signInto: 'Sign in to',
  tryAgain: 'Try again',
  messageLong: 'Authentication is taking longer than expected.',
  keepWaiting: 'Keep waiting',
  messageWait:
    "If you'd like, you can leave, and we'll update you on the progress.",
  messageContinueProcess:
    "We'll keep processing your request and update you once it's done.",
  messageAllSet: "You're all set!",
  gigSuccessTitle: ({ customerName, connectorName }) =>
    `Your ${connectorName} direct deposit is now updated to ${customerName}.`,
  loginWithYourCredentials: 'Sign in with your credentials',
  messageNotifiedComplete:
    "You'll be notified when the process is complete, or if we need your help.",
  messageDepositTaskCompleted:
    'Your direct deposit has been updated successfully!',
  messageReadDataTaskCompleted: 'Success!',
  messageReadDataTaskCompletedDescription:
    'Your payroll data has been securely shared.',
  messageAggregateDataTaskCompleted: ({ customerName }) =>
    `Your accounts have been successfully linked to ${customerName}.`,
  messageAuthenticationSuccessful: 'Authentication successful.',
  messageCompleteDescriptionDave: `Nice work logging in. Now it's our turn - we'll get your direct deposit updated in just a few moments. Please note that this may take up to 5 minutes.`,
  tax: {
    completed: {
      title: "You're all set!",
      description: "We've gathered the documents needed for your tax filing.",
      actionItems: {
        connectAnother: 'Connect another account',
      },
    },
  },
  dataVerification: {
    title: 'Review and confirm your employment details for accuracy',
    notFound: 'Not found',
    lastPaystub: 'Last paystub',
    confirm: 'Confirm',
    somethingIsntRight: `Something isn't right`,
  },
}
