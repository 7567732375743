import { store } from '@/store'

export async function detokenizeCreditCard(options) {
  const {
    data: { btToken, btSessionApiKey, pageHandle, debugMode },
  } = options

  if (debugMode === true) {
    console.log('Not detokenizing in debug mode')

    store.dispatch('tempStorage/addCardData', {
      pageHandle,
      cardData: { number: '4242424242424242', cvc: '123', cvv: '123' },
    })
  } else {
    const res = await fetch(`https://api.basistheory.com/tokens/${btToken}`, {
      method: 'get',
      headers: {
        'BT-API-KEY': btSessionApiKey,
      },
      redirect: 'follow',
    })

    const { data } = await res.json()

    const { number, cvc } = data

    store.dispatch('tempStorage/addCardData', {
      pageHandle,
      cardData: { number, cvc, cvv: cvc },
    })
  }
}
