import { adpInterstitial } from './adp-interstitial'
import { authenticationHelp } from './authentication-help'
import { bankInterstitial } from './bank-interstitial'
import { capitalOneCustomError } from './capital-one-error'
import { configurableConnectorSearch } from './configurable-connector-search'
import { customerCustomization } from './customer-customization'
import { depositOptions } from './deposit-options'
import { directDepositForm } from './direct-deposit-form'
import { displayAccounts } from './display-accounts'
import { distribution } from './distribution'
import { distributionConfirmation } from './distribution-confirmation'
import { doordashInterstitial } from './doordash-interstitial'
import { errorMessage } from './error-message'
import { exitModal } from './exit-modal'
import { externalLoginRecovery } from './external-login-recovery'
import { findCredentialsHelpModal } from './find-credentials-help-modal'
import { findEmployerHelpModal } from './find-employer-help-modal'
import { fixedDeposit } from './fixed-deposit'
import { fractionalError } from './fractional-error'
import { legal } from './legal'
import { login } from './login'
import { loginHelp } from './login-help'
import { loginRecovery } from './login-recovery'
import { maintenanceInterstitial } from './maintenance-interstitial'
import { manualDepositInstructions } from './manual-deposit-instructions'
import { manualFallback } from './manual-fallback'
import { mfa } from './mfa'
import { multipleAccounts } from './multiple-accounts'
import { multiplePayrollProviders } from './multiple-payroll-providers'
import { percentDeposit } from './percent-deposit'
import { progress } from './progress'
import { routingBlockedInterstitial } from './routing-blocked-interstitial'
import { searchCompany } from './search-company'
import { searchFranchise } from './search-franchise'
import { searchPayroll } from './search-payroll'
import { security } from './security'
import { shared } from './shared'
import { socialSecurityInterstitial } from './social-security-interstitial'
import { socialSecurityLoginOptions } from './social-security-login-options'
import { socialSecuritySsi } from './social-security-ssi'
import { sparkDriverManual } from './spark-driver-manual'
import { systemAfterHoursInterstitial } from './system-after-hours-interstitial'
import { taskCompleted } from './task-completed'
import { taskHistory } from './task-history'
import { taskProgress } from './task-progress'
import { uplinkInterstitial } from './uplink-interstitial'
import { userDeviceAutomation } from './user-device-automation'
import { walmartManual } from './walmart-manual'
import { welcome } from './welcome'

export const base = {
  adpInterstitial,
  authenticationHelp,
  bankInterstitial,
  capitalOneCustomError,
  configurableConnectorSearch,
  customerCustomization,
  depositOptions,
  directDepositForm,
  displayAccounts,
  distribution,
  distributionConfirmation,
  doordashInterstitial,
  errorMessage,
  exitModal,
  externalLoginRecovery,
  findCredentialsHelpModal,
  findEmployerHelpModal,
  fixedDeposit,
  fractionalError,
  legal,
  login,
  loginHelp,
  loginRecovery,
  maintenanceInterstitial,
  manualDepositInstructions,
  manualFallback,
  mfa,
  multipleAccounts,
  multiplePayrollProviders,
  percentDeposit,
  progress,
  routingBlockedInterstitial,
  searchCompany,
  searchFranchise,
  searchPayroll,
  security,
  shared,
  socialSecurityInterstitial,
  socialSecurityLoginOptions,
  socialSecuritySsi,
  sparkDriverManual,
  systemAfterHoursInterstitial,
  taskCompleted,
  taskHistory,
  taskProgress,
  uplinkInterstitial,
  userDeviceAutomation,
  walmartManual,
  welcome,
}
