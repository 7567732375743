import Ably from '@/plugins/ably'
import { emitSdkEvent } from '@/util/sdk'
import {
  SDK_EVENT_TYPES,
  USER_DEVICE_AUTOMATION_EVENTS,
} from '@/util/constants'
import { injectDomPollTrigger } from '@/util/client-side-automation/script-injections'

export async function initAblyChannelEvents({ store }) {
  return Ably.get().channels.get(`${store.state.user.userData._id}:uplink`)
}

export function initAutomationEvents({ store, channel }) {
  // We do not want to resubscribe! We want to avoid duplicate messages
  if (channel.state === 'attached') {
    return
  }

  // When the virtual page is ready from the backend start polling from the client dom
  channel.subscribe('page-ready', () => {
    _handlePageReady({ store, channel })
  })

  return channel
}

function _handlePageReady({ store, channel }) {
  if (
    store.getters['userDeviceAutomation/parsedUserAutomationDeviceConfig']
      .runDOMDiff
  ) {
    emitSdkEvent(SDK_EVENT_TYPES.AUTOMATION_HANDOFF, {
      type: USER_DEVICE_AUTOMATION_EVENTS.DOM_POLL,
    })

    // Trigger a DOM update after 1s for Android since Android doesn't send an initial
    // DOM on the `dom-poll` event like iOS does
    setTimeout(() => injectDomPollTrigger({ store }), 1000)

    if (store.state.userDeviceAutomation.activeSession.currentURL) {
      Ably.chunkPublish({
        channel,
        eventName: 'client-update-url',
        eventData: {
          url: store.state.userDeviceAutomation.activeSession.currentURL,
        },
      })
    }
  }
}
