import { Quantum } from '@atomicfi/quantum-js'
import { makeRequest } from '@/util/requests'
import { debounce } from 'lodash-es'
import lottieThinking from './lottie-thinking.json'
import Analytics from '@/plugins/analytics'

let isExecutingAI = false

/**
 * Launches a guided webview experience for user interactions
 * @param {Object} params - The parameters object
 * @param {string} params.startUrl - The URL to load in the webview
 * @param {string} params.theActionBeingPerformed - Description of the action being guided
 * @returns {Promise<{page: Object}>} The webview page object
 */
export async function launchGuidedWebview({ url, theActionBeingPerformed }) {
  const lottieScript = await _getLottieScript()
  const page = await _setupGuidedWebview({
    startUrl: url,
    lottieScript,
    theActionBeingPerformed,
  })

  await page.show()
  await page.goto(url)

  return { page }
}

/**
 * Sets up the guided webview with necessary event listeners
 * @param {Object} params - The parameters object
 * @param {string} params.startUrl - The URL to load
 *
 * @param {string} params.lottieScript - The Lottie animation script
 * @param {string} params.theActionBeingPerformed - Action description
 * @returns {Promise<Object>} The configured page object
 */
async function _setupGuidedWebview({
  startUrl,
  lottieScript,
  theActionBeingPerformed,
}) {
  const { page } = await Quantum.launch({
    interceptRequests: false,
  })

  await page.on(
    'started',
    debounce(async () => _handleOnStart({ page }), 700),
  )
  await page.on('finished', _handleOnFinish({ page, lottieScript }))
  await page.on(
    'dispatch',
    _handleOnDispatch({ page, startUrl, theActionBeingPerformed }),
  )

  return page
}

/**
 * Fetches the Lottie animation script from CDN
 * @returns {Promise<string>} The Lottie script content
 */
async function _getLottieScript() {
  const response = await fetch(
    'https://cdnjs.cloudflare.com/ajax/libs/lottie-web/5.12.2/lottie.min.js',
  )
  return await response.text()
}

/**
 * Handles the start event of the webview
 * Resets the execution state when starting a new page load
 * @param {Object} params - The parameters object
 * @param {Object} params.page - The webview page object
 */
async function _handleOnStart({ page }) {
  isExecutingAI = false

  await page.evaluate(() => {
    if (window.hideAILoader) {
      window.hideAILoader()
      window.hideAIGuidedTitle()
    }
  })
}

/**
 * Creates a handler for the finish event
 * @param {Object} params - The parameters object
 * @param {Object} params.page - The webview page object
 * @param {string} params.lottieScript - The Lottie animation script
 * @returns {Function} The finish event handler
 */
function _handleOnFinish({ page, lottieScript }) {
  return async () => {
    await page.evaluate(`async () => {
        if (!window.lottie) {
          ${lottieScript}
        }
        window.MuppetPage.dispatch({ type: 'lottie-ready' })
    }`)
  }
}

/**
 * Creates a handler for dispatch events
 * @param {Object} params - The parameters object
 * @param {Object} params.page - The webview page object
 * @param {string} params.theActionBeingPerformed - Action description
 * @returns {Function} The dispatch event handler
 */
function _handleOnDispatch({ page, startUrl, theActionBeingPerformed }) {
  return async (event) => {
    switch (event.detail.data?.type) {
      case 'lottie-ready':
        return _setupAIElements(page)
      case 'ai-ready':
        return _executeAIInteractions({
          page,
          startUrl,
          theActionBeingPerformed,
        })
      case 'hide-guided-flow':
        return _handleHideGuidedFlow()
      case 'show-guided-flow':
        return _handleShowGuidedFlow({
          page,
          startUrl,
          theActionBeingPerformed,
        })
      case 'user-interaction':
        return _handleUserInteraction({
          page,
          startUrl,
          theActionBeingPerformed,
        })
      case 'finished':
        return _handleGuidedFlowFinished({ page })
    }
  }
}

/**
 * Handles the close guided flow event
 */
function _handleHideGuidedFlow() {
  Analytics.get().track({
    event: 'Hide Guided Flow',
    internal: true,
  })
}

/**
 * Handles the open guided flow event
 * @param {Object} params - The parameters object
 * @param {Object} params.page - The webview page object
 * @param {string} params.startUrl - Starting URL
 * @param {string} params.theActionBeingPerformed - Action description
 */
function _handleShowGuidedFlow({ page, startUrl, theActionBeingPerformed }) {
  Analytics.get().track({
    event: 'Show Guided Flow',
    internal: true,
  })

  return _executeAIInteractions({
    page,
    startUrl,
    theActionBeingPerformed,
  })
}

/**
 * Handles the user interaction event
 * @param {Object} params - The parameters object
 * @param {Object} params.page - The webview page object
 * @param {string} params.startUrl - Starting URL
 * @param {string} params.theActionBeingPerformed - Action description
 */
function _handleUserInteraction({ page, startUrl, theActionBeingPerformed }) {
  Analytics.get().track({
    event: 'Guided Flow User Interaction',
    internal: true,
  })
  return setTimeout(
    () =>
      _executeAIInteractions({
        page,
        startUrl,
        theActionBeingPerformed,
      }),
    1000,
  )
}

/**
 * Handles the guided flow finished event
 * @param {Object} params - The parameters object
 * @param {Object} params.page - The webview page object
 */
async function _handleGuidedFlowFinished({ page }) {
  Analytics.get().track({
    event: 'Clicked Exit Guided Flow',
    internal: true,
  })
  await page.hide()
  return page.close()
}

/**
 * Executes AI-guided interactions based on page content
 * @param {Object} params - The parameters object
 * @param {Object} params.page - The webview page object
 * @param {string} params.theActionBeingPerformed - Action description
 */
async function _executeAIInteractions({
  page,
  startUrl,
  theActionBeingPerformed,
}) {
  // Since multiple places can trigger this, we need to make sure we don't execute AI multiple times concurrently
  if (isExecutingAI) {
    return
  }

  isExecutingAI = true

  await _showAnalyzingNextSteps(page)

  // @TODO: arbitrary timeout for page content to actually load
  await new Promise((resolve) => setTimeout(resolve, 3000))

  const html = await _prepareAndGetHtml(page)
  try {
    const { navState, nextSelector } = await _getNextGuidedSelector({
      action: theActionBeingPerformed,
      startUrl,
      currentUrl: await page.url(),
      html,
    })

    if (navState === 'completed') {
      await _completeAction(page)
    } else if (navState == 'not authenticated') {
      await _showLoginPrompt(page)
    } else {
      await _showNextStep({ page, nextSelector, theActionBeingPerformed })
    }
  } catch (error) {
    await _showErrorProcessingNextStep(page)
  } finally {
    isExecutingAI = false
  }
}

/**
 * Shows the analyzing steps loading state
 * @param {Object} page - The webview page object
 */
async function _showAnalyzingNextSteps(page) {
  await page.evaluate(async () => {
    window.showAILoader()
    await new Promise((resolve) => setTimeout(resolve, 500))
    window.showAIGuidedTitle()
    await window.typeAIGuidedTitleText('Analyzing next steps...')
  })
}

/**
 * Prepares the page HTML by marking hidden elements and returns cleaned HTML
 * @param {Object} page - The webview page object
 * @returns {Promise<string>} Cleaned HTML content
 */
async function _prepareAndGetHtml(page) {
  const html = await page.evaluate(() => {
    const elements = document.getElementsByTagName('*')

    for (let i = 0; i < elements.length; i++) {
      const HIDDEN = 'atomic-hidden'
      const UNCLICKABLE = 'atomic-unclickable'

      const element = elements[i]
      const style = window.getComputedStyle(element)

      // We want to see all inputs and buttons otherwise AI might miss them
      if (
        element.tagName.toLowerCase() === 'input' ||
        element.tagName.toLowerCase() === 'button' ||
        element?.parentElement?.tagName.toLowerCase() === 'button'
      ) {
        continue
      }

      if (
        ((style.width === '0px' ||
          style.height === '0px' ||
          (style.width === '1px' && style.height === '1px')) &&
          style.overflow !== 'visible') ||
        style.opacity === '0' ||
        style.display === 'none' ||
        style.visibility === 'hidden'
      ) {
        element.setAttribute(HIDDEN, '')
      } else if (element.hasAttribute(HIDDEN)) {
        element.removeAttribute(HIDDEN)
      }

      if (style.pointerEvents === 'none') {
        element.setAttribute(UNCLICKABLE, '')
      } else if (element.hasAttribute(UNCLICKABLE)) {
        element.removeAttribute(UNCLICKABLE)
      }
    }

    return document.documentElement.outerHTML
  })

  return _cleanHtml(html)
}

/**
 * Sets up AI-related UI elements in the webview
 * @param {Object} page - The webview page object
 */
async function _setupAIElements(page) {
  await page.evaluate(
    (lottieThinking) => {
      if (!window.addedAILoader) {
        window.addedAILoader = true

        // If the background is transparent, set it to white, this may potentially hurt a website that is enforcing a dark mode style, but yolo
        // Our WKWebView sets the background to the user's system color, so this is a workaround to ensure that the background is white
        const body = document.body
        const computedStyle = window.getComputedStyle(document.body)

        if (
          computedStyle.backgroundColor === 'rgba(0, 0, 0, 0)' ||
          computedStyle.backgroundColor === 'transparent'
        ) {
          body.style.backgroundColor = 'white'
        }

        // Meta viewport tag to prevent zooming/scaling
        const meta = document.createElement('meta')
        meta.setAttribute('name', 'viewport')
        meta.setAttribute(
          'content',
          'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0',
        )
        document.getElementsByTagName('head')[0].appendChild(meta)

        // Semi-transparent white overlay div that covers the whole viewport
        // Used as background for the loading animation
        const overlayDiv = document.createElement('div')
        overlayDiv.style.position = 'fixed'
        overlayDiv.style.top = '0'
        overlayDiv.style.left = '0'
        overlayDiv.style.width = '100%'
        overlayDiv.style.height = '100%'
        overlayDiv.style.backgroundColor = 'rgba(255, 255, 255, 0.9)'
        overlayDiv.style.zIndex = '9999999999'
        overlayDiv.style.display = 'flex'
        overlayDiv.style.alignItems = 'center'
        overlayDiv.style.justifyContent = 'center'
        overlayDiv.style.opacity = '0'
        overlayDiv.style.transition = 'opacity 0.5s ease-in'
        overlayDiv.style.pointerEvents = 'none'

        // Container for the Lottie animation
        const animationContainer = document.createElement('div')
        animationContainer.id = 'animation-container'
        animationContainer.style.width = '300px'
        animationContainer.style.height = '300px'
        animationContainer.style.opacity = '0'
        animationContainer.style.transition = 'opacity 0.5s ease-in 0.5s'
        animationContainer.style.pointerEvents = 'none'

        overlayDiv.appendChild(animationContainer)
        document.body.appendChild(overlayDiv)

        // Trigger reflow to ensure transition works
        overlayDiv.offsetHeight

        // Initialize Lottie animation
        window.lottie.loadAnimation({
          container: document.getElementById('animation-container'),
          renderer: 'svg',
          loop: true,
          autoplay: true,
          animationData: lottieThinking,
        })

        // Function to show the loading overlay and animation
        window.showAILoader = () => {
          overlayDiv.style.opacity = '1'
          animationContainer.style.opacity = '1'
          window.hideAIFabButton()
        }

        // Function to hide the loading overlay and animation
        window.hideAILoader = () => {
          overlayDiv.style.opacity = '0'
          animationContainer.style.opacity = '0'
        }

        // Gradient title bar div that appears at the top of the viewport
        // Used to display messages to the user
        const guidedTitleDiv = document.createElement('div')
        guidedTitleDiv.style.borderRadius = '20px'
        guidedTitleDiv.style.padding = '20px'
        guidedTitleDiv.style.paddingLeft = '30px'
        guidedTitleDiv.style.paddingRight = '30px'
        guidedTitleDiv.style.height = 'auto'
        guidedTitleDiv.style.position = 'fixed'
        guidedTitleDiv.style.top = '20px'
        guidedTitleDiv.style.left = '35px'
        guidedTitleDiv.style.right = '35px'
        guidedTitleDiv.style.alignSelf = 'center'
        guidedTitleDiv.style.display = 'flex'
        guidedTitleDiv.style.flexDirection = 'column'
        guidedTitleDiv.style.alignItems = 'center'
        guidedTitleDiv.style.textAlign = 'center'
        guidedTitleDiv.style.justifyContent = 'center'
        guidedTitleDiv.style.boxShadow = '0 0 20px rgba(0, 0, 0, 0.5)'
        guidedTitleDiv.style.pointerEvents = 'none'
        guidedTitleDiv.style.zIndex = '99999999999'
        guidedTitleDiv.style.color = 'white'
        guidedTitleDiv.style.fontFamily =
          '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif'
        guidedTitleDiv.style.fontSize = '16px'
        guidedTitleDiv.style.fontWeight = 'bold'
        guidedTitleDiv.style.background =
          'linear-gradient(120deg, #B26EAE 0%, #B26EAE 20%, #7B57A9 35%, #3B9E92 60%, #3B9E92 100%)'
        guidedTitleDiv.style.opacity = '0'
        guidedTitleDiv.style.transition = 'opacity 0.3s ease-in-out'
        guidedTitleDiv.setAttribute('guided-title', '')

        // Close button
        const closeButton = document.createElement('button')
        closeButton.style.position = 'absolute'
        closeButton.style.top = '-10px'
        closeButton.style.right = '-10px'
        closeButton.style.width = '31px'
        closeButton.style.height = '31px'
        closeButton.style.borderRadius = '50%'
        closeButton.style.backgroundColor = 'white'
        closeButton.style.border = 'none'
        closeButton.style.boxShadow = '0 2px 4px rgba(0,0,0,0.2)'
        closeButton.style.cursor = 'pointer'
        closeButton.style.display = 'flex'
        closeButton.style.alignItems = 'center'
        closeButton.style.justifyContent = 'center'
        closeButton.style.pointerEvents = 'auto'
        closeButton.innerHTML = `
          <svg width="12" height="12" viewBox="0 0 12 12" style="pointer-events: none">
            <path d="M1 1L11 11M1 11L11 1" stroke="#B26EAE" stroke-width="2" stroke-linecap="round"/>
          </svg>
        `
        closeButton.setAttribute('guided-button', '')
        closeButton.onclick = () => {
          window.MuppetPage.dispatch({ type: 'hide-guided-flow' })
          window.hideAIGuidedTitle()
          window.showAIFabButton()
          window.unhighlightElement()
        }
        guidedTitleDiv.appendChild(closeButton)

        // Text element inside title bar for displaying messages
        const textElement = document.createElement('span')
        guidedTitleDiv.appendChild(textElement)
        guidedTitleDiv.offsetHeight // Trigger reflow

        // Exit button that appears in title bar when task is complete
        const exitButton = document.createElement('button')
        exitButton.textContent = 'Exit'
        exitButton.style.marginTop = '10px'
        exitButton.style.padding = '8px 20px'
        exitButton.style.border = 'none'
        exitButton.style.borderRadius = '20px'
        exitButton.style.backgroundColor = 'white'
        exitButton.style.color = '#B26EAE'
        exitButton.style.cursor = 'pointer'
        exitButton.style.fontWeight = 'bold'
        exitButton.style.pointerEvents = 'auto'
        exitButton.style.display = 'none'
        exitButton.setAttribute('guided-button', '')
        exitButton.onclick = () => {
          window.MuppetPage.dispatch({ type: 'finished' })
        }

        guidedTitleDiv.appendChild(exitButton)

        // Function to show the title bar and optionally show exit button
        window.showAIGuidedTitle = ({ withExitButton = false } = {}) => {
          exitButton.style.display = withExitButton ? 'block' : 'none'
          guidedTitleDiv.style.opacity = '1'
        }

        // Function to hide the title bar
        window.hideAIGuidedTitle = () => {
          guidedTitleDiv.style.opacity = '0'
        }

        // Help FAB button
        const fabButton = document.createElement('button')
        fabButton.style.position = 'fixed'
        fabButton.style.bottom = '20px'
        fabButton.style.left = '20px'
        fabButton.style.width = '60px'
        fabButton.style.height = '60px'
        fabButton.style.borderRadius = '50%'
        fabButton.style.border = 'none'
        fabButton.style.background =
          'linear-gradient(135deg, #B26EAE 0%, #6E3E85 100%)'
        fabButton.style.boxShadow = '0 2px 8px rgba(0,0,0,0.2)'
        fabButton.style.cursor = 'pointer'
        fabButton.style.display = 'flex'
        fabButton.style.alignItems = 'center'
        fabButton.style.justifyContent = 'center'
        fabButton.style.zIndex = '99999999999'
        fabButton.style.color = 'white'
        fabButton.style.fontFamily =
          '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif'
        fabButton.style.fontSize = '25px'
        fabButton.style.fontWeight = 'bold'
        fabButton.style.opacity = '0'
        fabButton.style.pointerEvents = 'none'
        fabButton.style.transition = 'opacity 0.3s ease-in-out'
        fabButton.innerHTML = '?'
        fabButton.setAttribute('guided-button', '')
        fabButton.onclick = () => {
          window.MuppetPage.dispatch({ type: 'show-guided-flow' })
          window.hideAIFabButton()
          window.unhighlightElement()
        }

        document.body.appendChild(fabButton)

        // Function to show the fab button
        window.showAIFabButton = () => {
          fabButton.style.opacity = '1'
          fabButton.style.pointerEvents = 'auto'
        }

        // Function to hide the fab button
        window.hideAIFabButton = () => {
          fabButton.style.opacity = '0'
          fabButton.style.pointerEvents = 'none'
        }

        // Function to animate text being typed into the title bar
        window.typeAIGuidedTitleText = (text) => {
          clearTimeout(window.AIGuidedTimeout)

          return new Promise((resolve) => {
            let charIndex = 0
            textElement.textContent = ''

            const typeWriter = () => {
              if (charIndex < text.length) {
                textElement.textContent += text.charAt(charIndex)
                charIndex++
                window.AIGuidedTimeout = setTimeout(
                  typeWriter,
                  Math.floor(Math.random() * (75 - 15 + 1)) + 15,
                )
              } else {
                resolve()
              }
            }

            typeWriter()
          })
        }

        // Function to highlight a specific element on the page
        window.highlightElement = (selector) => {
          const element = document.querySelector(selector)

          if (!element) {
            return
          }

          element.style.transition = 'all 3s ease;'
          element.style['border-radius'] = '2rem'
          element.style['box-shadow'] = '0 0 0 99999px rgba(0, 0, 0, .85)'
          element.style['z-index'] = '9999999'
          element.style.outline = '#3B9E92 solid 5px'
          element.style.padding = '10px'
          element.style.position = 'relative'
          element.setAttribute('guided-highlight', '')
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          })
        }

        // Function to remove highlighting from previously highlighted element
        window.unhighlightElement = () => {
          const element = document.querySelector('[guided-highlight]')

          if (!element) {
            return
          }

          element.style.transition = ''
          element.style['border-radius'] = ''
          element.style['box-shadow'] = ''
          element.style['z-index'] = ''
          element.style.outline = ''
          element.style.padding = ''
          element.style.position = ''
          element.removeAttribute('guided-highlight')
        }

        // Event listener to hide UI elements when user clicks interactive elements
        document.addEventListener('click', (e) => {
          if (
            !e.target.hasAttribute('guided-button') &&
            (e.target.tagName === 'A' ||
              e.target.tagName === 'BUTTON' ||
              e.target.closest('button'))
          ) {
            window.hideAILoader()
            window.hideAIGuidedTitle()
            window.MuppetPage.dispatch({ type: 'user-interaction' })
          }
        })

        document.body.appendChild(guidedTitleDiv)
      }

      window.MuppetPage.dispatch({ type: 'ai-ready' })
    },
    [lottieThinking],
  )
}

/**
 * Shows the next step in the guided experience
 * @param {Object} params - The parameters object
 * @param {Object} params.page - The webview page object
 * @param {string} params.nextSelector - CSS selector for the next element
 * @param {string} params.theActionBeingPerformed - Action description
 */
async function _showNextStep({ page, nextSelector, theActionBeingPerformed }) {
  await page.evaluate(
    async (nextSelector, theActionBeingPerformed) => {
      window.hideAILoader()
      window.typeAIGuidedTitleText(
        `This looks like the next step to ${theActionBeingPerformed.toLowerCase()}.`,
      )
      window.highlightElement(nextSelector)
    },
    [nextSelector, theActionBeingPerformed],
  )
}

/**
 * Shows an error message when processing fails
 * @param {Object} page - The webview page object
 */
async function _showErrorProcessingNextStep(page) {
  await page.evaluate(async () => {
    window.hideAILoader()
    await window.typeAIGuidedTitleText(
      'Something went wrong. Please try again.',
    )
    setTimeout(() => window.hideAIGuidedTitle(), 3000)
  })
}

/**
 * Shows completion message when all steps are done
 * @param {Object} page - The webview page object
 */
async function _completeAction(page) {
  Analytics.get().track({
    event: 'Guided Flow Completed',
    internal: true,
  })

  await page.evaluate(async () => {
    window.hideAILoader()
    window.showAIGuidedTitle({ withExitButton: true })
    window.typeAIGuidedTitleText('All steps completed!')
  })
}

/**
 * Cleans HTML by removing unnecessary elements and scripts
 * @param {string} html - Raw HTML content
 * @returns {string} Cleaned HTML content
 */
function _cleanHtml(html) {
  return html
    .replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '')
    .replace(/<style\b[^<]*(?:(?!<\/style>)<[^<]*)*<\/style>/gi, '')
    .replace(/<link\b[^<]*(?:(?!<\/link>)<[^<]*)*<\/link>/gi, '')
    .replace(/<svg\b[^<]*(?:(?!<\/svg>)<[^<]*)*<\/svg>/gi, '')
    .replace(/<iframe\b[^<]*(?:(?!<\/iframe>)<[^<]*)*<\/iframe>/gi, '')
    .replace(/<noscript\b[^<]*(?:(?!<\/noscript>)<[^<]*)*<\/noscript>/gi, '')
    .replace(/<noscript\b[^<]*(?:(?!<\/noscript>)<[^<]*)*<\/noscript>/gi, '')
    .replace(/<noscript\b[^<]*(?:(?!<\/noscript>)<[^<]*)*<\/noscript>/gi, '')
}

/**
 * Gets the next guided selector from the AI service
 * @param {Object} params - The parameters object
 * @param {string} params.theActionBeingPerformed - The action being performed
 * @param {string} params.startUrl - The starting url
 * @param {string} params.currentUrl - The current url
 * @param {string} params.html - The current page HTML
 * @returns {Promise<{navState: string, nextSelector: string}>} Navigation state and selector
 */
async function _getNextGuidedSelector({ action, startUrl, currentUrl, html }) {
  const response = await makeRequest({
    method: 'post',
    endpoint: '/ai/predict-guided-step',
    data: {
      action,
      startUrl,
      currentUrl,
      html,
    },
  })
  return {
    navState: response?.data?.navState,
    nextSelector: response?.data?.selector,
  }
}

/**
 * Shows login prompt message
 * @param {Object} page - The webview page object
 */
async function _showLoginPrompt(page) {
  await page.evaluate(async () => {
    window.hideAILoader()
    window.showAIGuidedTitle()
    await window.typeAIGuidedTitleText('Please login to your account')
  })
}
