/** @param { import('@atomicfi/rpc').RPC } rpc */
export function register(rpc) {
  rpc.register('transact.fingerprint.initialize', initialize)
  rpc.register('transact.fingerprint.collect', collect)
  rpc.register('transact.fingerprint.destroy', destroy)
}

const COLLECTOR = `
<script>
window.addEventListener(
  'transact.fingerprint.collect',
  async (e) => {
  const { id, data } = e.detail
  const event = 'transact.fingerprint.collect.' + id
  let detail

  try {
    detail = {
      id,
      data: await collect(data)
    }
  } catch (err) {
    detail = {
      id,
      error: err.toString()
    }
  }

  window.top?.dispatchEvent(
    new CustomEvent(event, {
      detail
    })
  )
})
</script>
`

export async function initialize(html) {
  const content =
    html.indexOf('</html>') >= 0
      ? html.replace('</html>', `${COLLECTOR}</html>`)
      : html + COLLECTOR

  return new Promise((resolve) => {
    const id = 'x' + Number(Math.random()).toString(36)
    const blob = new Blob([content], { type: 'text/html; charset=utf-8' })
    const iframe = window.document.createElement('iframe')

    iframe.id = id
    iframe.style.display = 'none'
    iframe.title = 'Transact'
    iframe.ariaLabel = 'Transact'
    iframe.width = window.screen.availWidth
    iframe.height = window.screen.availHeight

    iframe.addEventListener('load', () => {
      resolve(id)
    })
    iframe.src = URL.createObjectURL(blob)

    window.document.body.appendChild(iframe)
  })
}

export async function collect({ id, data }) {
  const iframe = window.document.getElementById(id)

  if (!iframe)
    return {
      id,
      error: 'Invalid ID',
    }

  return new Promise((resolve) => {
    const event = 'transact.fingerprint.collect.' + id
    const collector = (e) => {
      window.removeEventListener(event, collector)
      resolve(e.detail)
    }

    window.addEventListener(event, collector)

    iframe.contentWindow.dispatchEvent(
      new CustomEvent('transact.fingerprint.collect', {
        detail: {
          id,
          data,
        },
      }),
    )
  })
}

export async function destroy(id) {
  const iframe = window.document.getElementById(id)
  if (!iframe) return

  window.document.body.removeChild(iframe)
}
