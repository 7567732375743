export const distribution = {
  entireAmount: 'Todo el sueldo',
  percentage: 'Porcentaje',
  specificAmount: 'Cantidad Específico',
  unsupported: 'No soportado',
  underMinimumFractionalDistributionFixed:
    'La cantidad seleccionada es insuficiente para proceder.',
  overMaximumFractionalDistributionFixed:
    'Por favor seleccione una cantidad mas baja',
  underMinimumFractionalDistributionPercent:
    'La cantidad seleccionada es insuficiente para proceder.',
  overMaximumFractionalDistributionPercent:
    'Por favor seleccione una cantidad mas baja',
  pageTitleChangeable: ({ name }) =>
    `¿Cuánto de su cheque de pago debe ir a ${name}?`,
  pageTitleNotChangeable: ({ name }) =>
    `Confirme su depósito directo a ${name}`,
  entire: 'Completo',
  percent: 'Porcentaje',
  amount: 'Cantidad',
  entirePaycheck: 'Cheque de pago completo',
  entirePaycheckDescription: ({ name }) =>
    `Si hay asignaciones existentes en su cuenta, ${name} recibirá el resto de su cheque de pago`,
}
