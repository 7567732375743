export const findCredentialsHelpModal = {
  title: 'How to find your credentials',
  description:
    'Your payroll provider is typically where you manage things like your benefits and employment info.',
  step1: {
    title: 'Try account recovery',
    description:
      'Most payroll systems allow you to look up your account or do a password reset.',
  },
  step1Tax: {
    title: 'Try account recovery',
    description:
      'Most tax systems allow you to look up your account or do a password reset.',
  },
  step2: {
    title: 'Check your personal/work email',
    description:
      'Your employer likely sent you an invite email to the payroll system. This email contains details on how to access your account.',
  },
  step3: {
    title: 'Ask your employer for help',
    description:
      'Your employer’s HR or payroll manager will be able to help you get access.',
  },
}
