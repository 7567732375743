import { errorMessage } from './error-message'
import { progress } from './progress'
import { searchCompany } from './search-company'
import { taskCompleted } from './task-completed'
import { taskProgress } from './task-progress'
import { addCard } from './add-card'
import { interstitial } from './interstitial'
import { addCardInterstitial } from './add-card-interstitial'
import { shared } from './shared'
import { home } from './home'
import { welcome } from './welcome'
import { detail } from './detail'

export const payLink = {
  detail,
  errorMessage,
  progress,
  searchCompany,
  taskCompleted,
  taskProgress,
  addCard,
  addCardInterstitial,
  shared,
  home,
  welcome,
  interstitial,
}
