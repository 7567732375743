export const USER_INPUT_REQUEST_TYPES = {
  AUTH: 'auth',
  FRACTIONAL_RESPONSE: 'fractionalResponse',
  KYC: 'kyc',
  LOGIN_RECOVERY: 'loginRecovery',
  MFA: 'mfa',
  MULTI_ACCOUNT_CHANGE: 'multiAccountChange',
  SESSION: 'session',
  SMART_AUTH: 'smart-auth-form',
}
