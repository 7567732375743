<template>
  <div class="dynamic-header-wrap" tabindex="0">
    <div
      class="main-content"
      :class="[
        {
          'logos-left': logosLeft,
          'logos-right': logosRight,
          'logos-center': logosCenter,
          'logos-none': logosNone,
        },
        textAlignment,
      ]"
    >
      <div class="content-wrap">
        <FocusHeading class="title" :class="{ dark: useDarkMode }" :level="2">{{
          title
        }}</FocusHeading>
        <p v-if="subtitle" class="subtitle">{{ subtitle }}</p>
      </div>
      <DynamicHeaderLogos
        class="logos"
        v-if="logos"
        :logos="logos"
        :badge="badge"
      />
    </div>
    <p v-if="description" class="description" :class="[textAlignment]">
      {{ description }}
    </p>
  </div>
</template>

<script>
import DynamicHeaderLogos from '@/components/Header/DynamicHeaderLogos.vue'
import FocusHeading from '@/components/Shared/FocusHeading.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'DynamicHeader',
  props: {
    title: String,
    subtitle: String,
    description: String,
    textAlignment: {
      type: String,
      validator: (value) =>
        ['text-left', 'text-center', 'text-right'].includes(value),
    },
    animation: Object,
    logos: Object,
    badge: {
      type: String,
      validator: (value) =>
        ['success', 'error', 'info', 'arrow'].includes(value),
    },
  },
  components: {
    DynamicHeaderLogos,
    FocusHeading,
  },
  computed: {
    ...mapGetters('theme', ['useDarkMode']),
    logosLeft() {
      return this.logos?.position === 'left'
    },
    logosRight() {
      return this.logos?.position === 'right'
    },
    logosCenter() {
      return this.logos?.position === 'center'
    },
    logosNone() {
      return !this.logos || this.logos.position === 'none'
    },
  },
}
</script>

<style lang="scss" scoped>
.dynamic-header-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
  text-wrap: pretty; /* not yet supported on all browsers */

  .main-content {
    display: flex;
    justify-content: space-between;
    &.logos-left {
      .content-wrap {
        padding-left: calc(var(--paddingBase) * 2);
        order: 2;
      }
      .logos {
        order: 1;
      }
    }
    &.logos-right {
      .content-wrap {
        padding-right: calc(var(--paddingBase) * 2);
      }
    }
    &.logos-center {
      justify-content: center;
    }

    &.logos-none {
      justify-content: center;

      .content-wrap {
        flex-grow: 1;
      }

      .logos {
        display: none;
      }
    }
    &.text-center {
      .title {
        text-align: center;
      }
      .description {
        text-align: center;
      }
    }

    .title {
      color: var(--gray-900);
      &.dark {
        color: var(--darkModeTextHigh);
      }
    }
  }

  .subtitle {
    margin-top: calc(var(--marginBase) * 0.5);
    font-size: 1.2rem;
  }

  .description {
    margin-top: calc(var(--marginBase) * 1);
    font-size: 1.6rem;
    color: var(--gray-600);
    .dark & {
      color: var(--gray-500);
    }
  }
}
</style>
