<template>
  <component :is="is" class="card-wrap" :class="{ dark: useDarkMode }">
    <slot></slot>
  </component>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CardWrap',
  props: {
    is: {
      type: String,
      default: 'div',
      validator: (value) => ['a', 'button', 'div', 'li'].includes(value),
    },
  },
  computed: {
    ...mapGetters('theme', ['useDarkMode']),
  },
}
</script>
<style lang="scss" scoped>
.card-wrap {
  background: var(--gray-100);
  border-radius: var(--smallBorderRadius);
  border: none;
  text-align: left;
  padding: calc(var(--paddingBase) * 2);
  font-size: inherit;
  display: block;

  &.dark {
    background: var(--gray-900);
    color: var(--darkModeTextMedium);
  }

  &:is(a, button) {
    transition: var(--button-hover);
    color: inherit;

    &:hover,
    &:focus,
    &:active {
      cursor: pointer;
      opacity: initial;
      transform: scale(0.98);
      opacity: 0.8;

      &.dark {
        background: var(--gray-800);
      }
      &.is-editing {
        cursor: default;
        background: var(--gray-100);

        &.dark {
          background: var(--gray-900);
        }
      }
    }
  }
}
</style>
